import React, { useState, useEffect, MouseEvent } from 'react';
import { getService } from 'reactInAngular';
import {Menu, MenuItem, ListItemIcon, Button, Typography, Box} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import {IdeaComment, FeedbackStatusTypes} from '../../types';
import HfMultiLanguageText from '../../../hf-multilanguage-text/hfMultilanguageText';
import {darkDropdown, whiteGrey} from '../../../../../utilities/color.utilities';
import {useTheme} from '@mui/material/styles';


interface CommentStatusSelectorProps {
    comment: IdeaComment;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;
    onlyActive?: boolean;
}

const CommentStatusSelector: React.FC<CommentStatusSelectorProps> = ({ comment, onlyActive = false, onCommentUpdated }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [statuses, setStatuses] = useState<FeedbackStatusTypes[]>([]);
    const [currentStatus, setCurrentStatus] = useState<FeedbackStatusTypes | null>(null);

    const $translate = getService('$translate');
    const toaster = getService('toaster');
    const FeedbackStatusService = getService('FeedbackStatusService');
    const ErrorSvrc = getService('ErrorSvrc');
    const theme = useTheme();

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeFeedbackStatus = (newStatusId: string) => {
        FeedbackStatusService.setStatus(comment.id, newStatusId, (error: any) => {
            if (error) {
                ErrorSvrc.showErrorModal(error);
            } else {
                onCommentUpdated && onCommentUpdated(error, comment.id);
                toaster.pop('success', null, $translate.instant('FEEDBACK_STATUS_CHANGED'));
            }
        });
        handleClose();
    };

    const removeStatus = () => {
        FeedbackStatusService.removeStatus(comment.id, (error: any) => {
            if (error) {
                ErrorSvrc.showErrorModal(error);
            } else {
                onCommentUpdated && onCommentUpdated(error, comment.id);
                toaster.pop('success', null, $translate.instant('FEEDBACK_STATUS_CHANGED'));
            }
        });
        handleClose();
    };


    useEffect(() => {
        const listStatuses = () => {
            const archivedStatus = onlyActive ? 'ACTIVE' : 'ANY';
            FeedbackStatusService.list(comment.feedbackType, archivedStatus, (err: any, fetchedStatuses: FeedbackStatusTypes[]) => {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    setStatuses(
                        fetchedStatuses.map((status) => ({
                            ...status,
                            color: status.phase === 'DISCARDED' ? 'red' : status.phase === 'ACCEPTED' ? 'green' : 'yellow',
                        }))
                    );
                }
            });
        };

        listStatuses();
    }, [comment.feedbackType, onlyActive]);

    useEffect(() => {
        if (comment?.statuses) {
            const currentCommentStatus = comment.statuses[comment.statuses.length - 1];
            setCurrentStatus(statuses.find((item) => item.id === currentCommentStatus.statusTypeId) || null);
        }
    }, [statuses, comment.statuses]);

    return (
        <Box>
            <Tooltip
                title={
                    currentStatus ? (
                        <HfMultiLanguageText multilanguage={currentStatus.label} replaceTokens={false} />
                    ) : (
                        $translate.instant('FEEDBACK_NO_STATUS')
                    )
                }
            >
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    startIcon={<ArrowDropDown />}
                    sx={{
                        color: 'gray',
                        justifyContent: 'left',
                        width: '100%',
                        backgroundColor: whiteGrey,
                        border: '0px',
                        fontSize: theme.typography.caption.fontSize,
                        display: 'inline-flex',
                        maxHeight: '27px',
                        textTransform: 'none',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    }}
                >
                    <>
                        <span className="m-r-xs" style={{ color: currentStatus?.color }}>■</span>
                        {currentStatus ? (
                            <HfMultiLanguageText multilanguage={currentStatus.label} replaceTokens={false} />
                        ) : (
                            $translate.instant('FEEDBACK_NO_STATUS')
                        )}
                    </>
                </Button>
            </Tooltip>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    '& .MuiMenu-paper': {
                        backgroundColor: darkDropdown,
                        boxShadow: '0 0 3px rgba(86, 96, 117, 0.7)',
                        border: 'none',
                        borderRadius: '3px',
                        fontSize: theme.typography.caption.fontSize,
                        padding: '0',
                    },
                }}
            >
                {statuses.map((status) => (
                    <MenuItem key={status.id} onClick={() => changeFeedbackStatus(status.id)}>
                        <ListItemIcon>
                            <span style={{ color: status.color }}>■</span>
                        </ListItemIcon>
                        <Typography color={theme.palette.getContrastText(darkDropdown)} variant={'caption'}>
                            <HfMultiLanguageText multilanguage={status.label} replaceTokens={false} />
                        </Typography>
                    </MenuItem>
                ))}

                {(comment?.statuses?.length || 0) > 0 && (
                    <>
                        <hr/>
                        <MenuItem onClick={removeStatus}>
                            <ListItemIcon>
                                <span style={{color: 'gray'}}>■</span>
                            </ListItemIcon>
                            <Typography color={theme.palette.error.main} variant={'caption'}>
                                {$translate.instant('FEEDBACK_REMOVE_STATUS')}
                            </Typography>
                        </MenuItem>
                    </>

                )}

            </Menu>
        </Box>
    );
};


export default CommentStatusSelector;

