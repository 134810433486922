'use strict';

var HeaderCtrl = function () {
    var _this = this;

    _this.showFilters = true;

    _this.is = function (value) {
        if (_this.section === value) {
            return true;
        } else {
            return false;
        }
    };

    this.$onInit = function () {


    };
};

const DashboardHeaderComponent = angular.module('happyForceApp')
    .directive('hfDashboardHeader', function () {
        return {
            scope: {
                hfTitle: '=',
                hfSubtitle: '=',
                hfInfo: '=',
                arrow: '=',
                section: '@'
            },
            template: require('./header.html'),
            controller: HeaderCtrl,
            controllerAs: 'dh',
            transclude: true,
            bindToController: true
        };
    });
export default DashboardHeaderComponent;
