import React, { useState, useEffect } from 'react';
import { getService } from 'reactInAngular';

import { Box, Stack } from '@mui/material';
import CommentMain from '../../components/comment-main/CommentMain';
import CommentReactionsBar from '../../components/comment-reactions-bar/CommentReactionsBar';
import CommentActionsBar from '../../components/comment-actions-bar/CommentsActionBar';
import {IdeaComment} from '../../types';
import CommentContainerSelector from '../../components/comment-container-selector/CommentContainerSelector';
import CommentStatusSelector from '../../components/comment-status-selector/CommentStatusSelector';


interface IdeaFeedbackTypeProps {
    comment: IdeaComment;
    showConversationLink?: boolean;
    showReplyWithAnnouncementLink?: boolean;
    showModerateLink: boolean;
    showSwapPrivacy: boolean;

    onCommentUpdated: (err: any, updatedCommentId: string) => void;

}

const IdeaFeedbackType: React.FC<IdeaFeedbackTypeProps> = ({
    comment,
    showConversationLink = true,
    showReplyWithAnnouncementLink,
    showModerateLink,
    showSwapPrivacy,
    onCommentUpdated,
}) => {
    const [voteImage, setVoteImage] = useState<string | null>(null);

    const $translate = getService('$translate');


    useEffect(() => {

        if (comment && comment.votes) {
            setVoteImage(comment.votes[0].imageUrl);
        }

    }, [comment, showConversationLink, $translate]);

    return (
        <CommentMain
            comment={comment}
            hideType={false}
            feedbackTypeText={$translate.instant('FEEDBACK_TYPE_RESULT_SUGGESTION')}
            onCommentUpdated={onCommentUpdated}
            postHeaderZone={
                <Box display="flex" alignItems="center">
                    {$translate.instant('STANDARD_FEEDBACK_VOTE_LABEL')}
                    {voteImage && <img className="m-l-xs img-circle img-xs" src={voteImage} alt="Vote" />}
                </Box>
            }
            postCommentZone={
                <>
                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <Box width="50%">
                            <CommentContainerSelector
                                comment={comment}
                                onCommentUpdated={onCommentUpdated}
                                noContainerText={$translate.instant('IDEA_HAS_NO_INITIATIVE')}
                                removeContainerText={$translate.instant('IDEA_REMOVE_INITIATIVE')}
                                noContainerOnPeriodText={$translate.instant('IDEA_NO_INITIATIVES_ON_PERIOD')}
                            ></CommentContainerSelector>
                        </Box>
                        <Box width="50%">
                            <CommentStatusSelector comment={comment}
                                onCommentUpdated={onCommentUpdated}
                                onlyActive={true}></CommentStatusSelector>
                        </Box>
                    </Stack>

                </>
            }
            reactionsZone={
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <CommentReactionsBar
                        comment={comment}
                        showRepliesAction={true}
                        onCommentUpdated={onCommentUpdated}
                    />
                    <CommentActionsBar
                        comment={comment}
                        showInappropriate={showModerateLink}
                        showConversation={showConversationLink}
                        showReplyWithAnnouncement={showReplyWithAnnouncementLink}
                        showSwapPrivacy={showSwapPrivacy}
                        onCommentUpdated={onCommentUpdated}
                    />
                </Stack>
            }
        />
    );
};


export default IdeaFeedbackType;
