'use strict';

function YourConversations($scope, Conversation, Comments, ErrorSvrc) {
    var _this = this;

    this.$onInit = function () {

        _this.loading = true;
        _this.totalConversations = 0;
        _this.conversations = [];
        _this.privateComments = [];
        _this.status = 'PENDING';

        _this.loadConversations = function () {
            var params = {
                fromConversation: 0,
                toConversation: 5,
                types: ['COMMENT']
            };

            if (_this.status != null)
                params.status = _this.status;

            Conversation.getAll(params, function (err, conversations) {
                _this.loading = false;
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                    console.log('ERROR getAll', err);
                }

                var results = conversations && conversations.results;
                if (results) {

                    results.forEach(function (conversation, index) {
                        Comments.getOne(conversation.commentId, function (err, data) {
                            conversation.comment = (!err) ? data : {};
                        });
                    });

                    _this.totalConversations = conversations.total;
                    _this.conversations = results;
                }
            });
        };

        _this.loadPendingPrivateComments = function () {
            var params = {
                from: _this.dates.from,
                to: _this.dates.to,
                privated: true,
                hasConversation: false
            };

            Comments.get(params, function (employeeComments) {
                _this.privateComments = employeeComments.comments;
            }, function (err) {

                ErrorSvrc.showErrorModal(err);
            });
        };

        ['dates'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {

                if (n == o) return;

                // Now compute the stats
                _this.loadConversations();
                _this.loadPendingPrivateComments();
            });
        });

        _this.onCommentUpdated = function (err, updatedCommentId) {
            Comments.getOne(updatedCommentId, function (err, updatedComment) {
                // Locate the index of the updated comment

                const index = _this.privateComments.findIndex(comment => comment.id === updatedCommentId);


                // And update it on the list
                _this.privateComments[index] = updatedComment;
            });
        };

        _this.loadConversations();
        _this.loadPendingPrivateComments();
    };
}


const hfYourConversations = angular.module('happyForceApp').directive('hfYourConversations', function () {
    return {
        scope: {
            dates: '=',
            filters: '=',
            groups: '=',
            level: '='
        },
        restrict: 'E',
        template: require('./your-conversations.html'),
        controller: YourConversations,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfYourConversations;
