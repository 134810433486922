import { NestedMenuItem } from 'mui-nested-menu';
import { MenuItem, Checkbox, Radio, ListItemText, Box, Typography } from '@mui/material';
import { Hierarchy } from '../types';
import {useEffect, useState} from 'react';
import {getHierarchyAll} from '../../../services/migrated/hierarchy.service';

interface HierarchyNestedMenuItemProps {
    open?: boolean;
    hierarchies?: Hierarchy[]; // If not defined, it will load all the tree
    filters: string[];
    setHierarchyFilters: (filters: string[]) => void;
    title: string;
    mode: string;
    nonRemovable: boolean;
}

const HierarchyNestedMenuItem = ({ open, hierarchies, filters, setHierarchyFilters, title, mode, nonRemovable, }: HierarchyNestedMenuItemProps) => {

    const [finalHierarchies, setFinalHierarchies] = useState<Hierarchy[] | undefined>();


    // If no hierarchies are defined, load all the tree:

    useEffect(() => {
        if (!hierarchies) {

            getHierarchyAll((error, result) => {
                if (error) {
                    console.error(error);
                } else {
                    setFinalHierarchies(result);
                }

            });
        } else {
            setFinalHierarchies(hierarchies);
        }


    },[hierarchies]);

    //handle with remove/add
    const handleFilter = (item) => {
        if (mode === 'multi') {
            if(filters && filters.includes(item.id)) {
                if(!nonRemovable) {
                    setHierarchyFilters(filters.filter(filterItem => filterItem !== item.id));
                }
            } else {
                setHierarchyFilters([...filters, item.id]);
            }
        } else if (mode === 'single') {
            if(filters && filters.includes(item.id)) {
                if(!nonRemovable) {
                    setHierarchyFilters(filters.filter(filterItem => filterItem !== item.id));
                }
            } else {
                setHierarchyFilters([item.id]);
            }
        } else {
            // TODO: grouped
        }
    }

    const renderHierarchies = (items: Hierarchy[]) => {
        if (!items) {
            return null;
        }

        if (mode === 'multi') {
            return items.map((item, index) => {
                const isChecked = filters && filters.includes(item.id);

                if (item.children && item.children.length > 0) {
                    return (
                        <NestedMenuItem
                            key={index}
                            sx={{ paddingLeft: 1 }}
                            onClick={() => {
                                handleFilter(item)
                            }
                            }
                            renderLabel={() =>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Checkbox
                                        checked={isChecked}
                                        inputProps={{ 'aria-labelledby': index+'' }} />
                                    <ListItemText primary={
                                        <Typography style={{ fontSize: '14px' }}>
                                            {item.name}
                                        </Typography>
                                    } />
                                </Box>
                            }
                            parentMenuOpen={open}
                            button={true}
                        >
                            {renderHierarchies(item.children)}
                        </NestedMenuItem>
                    );
                } else {
                    return (
                        <MenuItem key={index} onClick={() => handleFilter(item)} sx={{ paddingLeft: 1 }}>
                            <Checkbox
                                checked={isChecked}
                                inputProps={{ 'aria-labelledby': index+'' }} />
                            <ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {item.name}
                                </Typography>
                            } />
                        </MenuItem>
                    );
                }
            });
        } else if (mode === 'single') {
            return items.map((item, index) => {
                const isSelected = filters && filters.includes(item.id);

                if (item.children && item.children.length > 0) {
                    return (
                        <NestedMenuItem
                            key={index}
                            sx={{ paddingLeft: 1 }}
                            onClick={() => handleFilter(item)}
                            renderLabel={() =>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Radio
                                        checked={isSelected}
                                    />
                                    <ListItemText primary={
                                        <Typography style={{ fontSize: '14px' }}>
                                            {item.name}
                                        </Typography>
                                    } />
                                </Box>
                            }
                            parentMenuOpen={open}
                            button={true}
                        >
                            {renderHierarchies(item.children)}
                        </NestedMenuItem>
                    );
                } else {
                    return (
                        <MenuItem
                            key={index}
                            onClick={() => handleFilter(item)}
                            sx={{ paddingLeft: 1 }}>
                            <Radio
                                checked={isSelected}
                            />
                            <ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {item.name}
                                </Typography>
                            } />
                        </MenuItem>
                    );
                }
            });
        } else {
            //TODO: GROUPED
        }
    };

    return (
        <NestedMenuItem
            renderLabel={() => <ListItemText primary={
                <Typography style={{ fontSize: '14px', paddingLeft: 10 }}>
                    {title}
                </Typography>
            } />}
            parentMenuOpen={open}
        >
            {renderHierarchies(finalHierarchies)}
        </NestedMenuItem>
    );
};

export default HierarchyNestedMenuItem;
