import async from "async";

import template from './comments.html';

function CommentsCtrl($scope, Comments, CompanySvrc, ErrorSvrc, $log, Analytics) {
    'ngInject';
    var _this = this;

    _this.hasEnoughActiveEmployees = true;
    _this.iconInfo = '';


    this.$onInit = function () {




        function loadData() {
            _this.hasEnoughActiveEmployees = true;


            var streams = [];
            var dateParams;

            streams.push(function (next) {
                Comments.get(dateParams, function (employeeComments) {
                    var comments = {
                        items: [],
                        length: 0
                    };
                    _.forEach(employeeComments.comments, function (comment) {
                        comments.items.push(comment);
                    });

                    next(null, comments);

                }, next);
            });

            //Getting Comment stats
            streams.push(function (next) {
                Comments.stats.get(dateParams, function (err, commentStats) {
                    if (err) {
                        return next(err);
                    }

                    var stats = {};
                    stats.loadingComments = false;
                    stats.commentsInPeriod = commentStats.comments;
                    stats.commentsLikes = commentStats.likes;
                    stats.commentsDislikes = commentStats.dislikes;
                    stats.commentsInappropriates = commentStats.inappropiates;
                    stats.commentsReplies = commentStats.replies;
                    stats.totalSuggestions = commentStats.feedbackTypeCount.SUGGESTION;
                    stats.totalCriticism = commentStats.feedbackTypeCount.CRITICISM;
                    stats.totalRecognition = commentStats.feedbackTypeCount.RECOGNITION;
                    stats.totalInformation = commentStats.feedbackTypeCount.INFORMATION;
                    stats.totalCongratulations = commentStats.feedbackTypeCount.CONGRATULATION;
                    stats.totalFollowup = commentStats.feedbackTypeCount.CLASSIFICATION_FOLLOWUP;
                    stats.totalAnnouncement = commentStats.feedbackTypeCount.ANNOUNCEMENT_COMMENT;
                    stats.totalEnps = commentStats.feedbackTypeCount.ENPS;
                    stats.totalSurvey = commentStats.feedbackTypeCount.SURVEY;
                    stats.totalScore = commentStats.feedbackTypeCount.SCORE;
                    stats.totalCheckins = commentStats.feedbackTypeCount.QUESTION;

                    next(null, stats);
                }, next);
            });

            dateParams = {
                hierarchyId: _this.level && _this.level.id,
                filters: _this.filters,
                groupId: _this.groups,
                feedbackTypes: [
                    'RECOGNITION',
                    'CONGRATULATION',
                    'SUGGESTION',
                    'INFORMATION',
                    'CRITICISM',
                    'SCORE',
                    'ENPS',
                    'CLASSIFICATION_FOLLOWUP',
                    'QUESTION',
                    'SURVEY',
                    'ANNOUNCEMENT_COMMENT',
                ],
                excludePrivate: _this.fullscreen
            };

            $scope.$emit('loading', true);
            async.parallel(streams, function (err, results) {
                $scope.$emit('loading', false);
                if (err) {

                    if (ErrorSvrc.isNotEnoughActiveEmployees(err)) {
                        _this.hasEnoughActiveEmployees = false;
                    } else {
                        ErrorSvrc.showErrorModal(err);
                    }

                    return;
                }

                if (results) {
                    _this.hasEnoughActiveEmployees = true;
                    _this.comments = results[0];

                    if (results[1]) {
                        Object.keys(results[1]).forEach(function (key) {
                            _this[key] = results[1][key];
                        });
                    }
                }
            });
        }

        loadData();

        var lastExecution;
        ['dates', 'level', 'filters', 'groups', 'fullscreen'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {

                if (n == o) return;

                if (lastExecution) {
                    if (moment().diff(lastExecution) < 1000) {
                        return;
                    }
                }

                lastExecution = moment();
                loadData();
            }, true);
        });


    };

    _this.trackEvent = function (action) {
        Analytics.trackEvent('dashboard', action);
    };


    _this.setComment = function (selected) {
        _this.selected_comment = angular.copy(selected);
        _this.temp_comment = selected;//JSON.parse(JSON.stringify(selected));
    };

    _this.onCommentUpdated = function (err, updatedCommentId) {



        // Load and update the changedComment
        Comments.getOne(updatedCommentId, function (err, updatedComment) {
            const index = _this.comments.items.findIndex(comment => comment.id === updatedCommentId);
            _this.comments.items[index] = updatedComment;
        });

    };

    _this.setIconInfo = function (iconInfo) {
        _this.iconInfo = iconInfo;
    };

}


const CommentsBoxComponent = angular.module('happyForceApp')
    .directive('hfCommentsBox', function () {
        return {
            scope: {
                dates: '=',
                level: '=',
                filters: '=',
                groups: '=',
                fullscreen: '=?',
                question: '=',
                hideStats: '='
            },
            template: template,
            controller: CommentsCtrl,
            controllerAs: 'cc',
            bindToController: true
        };
    });

export default CommentsBoxComponent;
