import React, {useEffect, useState} from 'react';
import {Box, Button, LinearProgress, Typography} from '@mui/material';
import {getService} from 'reactInAngular';
import HfDropFileArea from '../../../../../../shared/new-components/hf-drop-file-area/hfDropFileArea';
import {BulkActionPerson, Person} from '../../../../components/user_listing/types';
import {processEmployeeXLSFile} from '../utils';
import {BulkActionType} from '../../../../components/user_listing/components/users_table';
import { saveAs } from 'file-saver';


type BulkActionDownloadAndUploadStepProps = {
    currentAction: BulkActionType | null;
    isVerifying: boolean;
    totalVerifiedSuccess: number;
    totalVerifiedFailure: number;
    totalToVerify: number;
}



const BulkActionDownloadAndUploadStep=({currentAction, totalVerifiedSuccess, totalVerifiedFailure, isVerifying,  totalToVerify} : BulkActionDownloadAndUploadStepProps) => {


    const [verificationProgress, setVerificationProgress] = React.useState(0);
    const [verificationCompleted, setVerificationCompleted] = React.useState(false);
    const [downloadingTemplate, setDownloadingTemplate] = useState(false);

    const $translate = getService('$translate');
    const ErrorSvrc = getService('ErrorSvrc');
    const People = getService('People');


    useEffect(() => {

        if (!currentAction) {
            return;
        }

        if (currentAction === BulkActionType.INVITE) {
            setVerificationProgress(100);
            setVerificationCompleted(true);
        } else {

            const totalVerified = totalVerifiedSuccess + totalVerifiedFailure;

            if (totalToVerify === 0) {
                setVerificationProgress(100);
                setVerificationCompleted(true);
            } else {
                setVerificationProgress((totalVerified / totalToVerify) * 100);
            }

            if (totalToVerify === totalVerified) {
                setVerificationCompleted(true);
            }
        }

    }, [totalVerifiedSuccess, totalVerifiedFailure, totalToVerify, currentAction]);



    const handleDownloadTemplate = () => {


        setDownloadingTemplate(true);

        People.getTemplate(currentAction, function(err, response) {
            setDownloadingTemplate(false);
            if (err) {
                ErrorSvrc.showErrorModal(err);
                return;
            }

            const blob = new Blob([response], { type: response.type });
            saveAs(blob, `template-${currentAction}.xlsx`);

        });
    }



    if (!isVerifying) {
        return (
            <Box sx={(theme) => ({display: 'flex', gap: theme.spacing(1), flexDirection: 'column'})}>
                <Box sx={(theme) => ({display: 'flex', gap: theme.spacing(1), flexDirection: 'column', alignItems: 'baseline', textAlign: 'left'})}>
                    <Typography variant={'h6'}>{$translate.instant('BULK_EMPLOYEE_PROCESSING_DOWNLOAD_TEMPLATE')}</Typography>
                    <Typography variant={'caption'}>{$translate.instant('BULK_EMPLOYEE_PROCESSING_DOWNLOAD_TEMPLATE_DETAIL')}</Typography>
                    <Button disabled={downloadingTemplate} variant={'contained'} color={'primary'} onClick={handleDownloadTemplate}>
                        {$translate.instant('BULK_EMPLOYEE_PROCESSING_DOWNLOAD_TEMPLATE_BUTTON')}
                    </Button>

                    {currentAction === BulkActionType.UPDATE && (
                        <Typography sx={(theme) => ({color: theme.palette.warning.main})} variant={'caption'}>{$translate.instant('BULK_EMPLOYEE_PROCESSING_TEMPLATE_DOWNLOAD_WARNING')}</Typography>
                    )}
                </Box>

                <Box sx={(theme) => ({display: 'flex', gap: theme.spacing(1), flexDirection: 'column', alignItems: 'baseline' , textAlign: 'left'})}>
                    <Typography variant={'h6'}>{$translate.instant('BULK_EMPLOYEE_PROCESSING_TEMPLATE_INSTRUCTIONS')}</Typography>
                    <Typography variant={'caption'}>
                        {currentAction === BulkActionType.INVITE && (
                            <Typography variant={'caption'}>{$translate.instant('BULK_EMPLOYEE_PROCESSING_TEMPLATE_INSTRUCTIONS_INVITE')}</Typography>
                        )}

                        {currentAction === BulkActionType.UPDATE && (
                            $translate.instant('BULK_EMPLOYEE_PROCESSING_TEMPLATE_INSTRUCTIONS_UPDATE')
                        )}
                        {currentAction === BulkActionType.DELETE && (
                            $translate.instant('BULK_EMPLOYEE_PROCESSING_TEMPLATE_INSTRUCTIONS_DELETE')
                        )}
                    </Typography>
                </Box>

                <Box sx={(theme) => ({display: 'flex', gap: theme.spacing(1), flexDirection: 'column', alignItems: 'baseline', textAlign: 'left'})}>
                    <Typography variant={'h6'}>{$translate.instant('BULK_EMPLOYEE_PROCESSING_UPLOAD_FILE')}</Typography>
                    <Typography variant={'caption'}>{$translate.instant('BULK_EMPLOYEE_PROCESSING_UPLOAD_FILE_DETAIL')}</Typography>
                </Box>

            </Box>
        );
    } else {
        return (
            <Box sx={(theme) => ({display: 'flex', gap: theme.spacing(1), flexDirection: 'column'})}>
                <Box sx={(theme) => ({display: 'flex', gap: theme.spacing(1), flexDirection: 'column', alignItems: 'baseline', textAlign: 'left'})}>

                    <Box sx={(theme) => ({
                        display: 'flex',
                        width: '100%',
                        gap: theme.spacing(1),
                        flexDirection: 'column',
                        alignItems: 'baseline',
                        textAlign: 'left'
                    })}>
                        <Typography variant={'caption'}>{$translate.instant('BULK_EMPLOYEE_PROCESSING_FILE_PROCESS_RETRIEVING_ORIGINAL_DATA',{
                            total: totalToVerify,
                            current: totalVerifiedSuccess + totalVerifiedFailure
                        })}</Typography>
                        <LinearProgress sx={{width: '100%', height: '10px'}} variant="determinate" value={verificationProgress} />
                    </Box>

                </Box>
            </Box>
        );
    }




}

export default BulkActionDownloadAndUploadStep;
