const AttachmentService = function(AuthSrvc, Upload, Settings, Restangular) {
    'ngInject';
    var methods = {};

    var Attachment = Restangular.one('company')
        .one('files');

    methods.upload = function (file, callback, progressCallback) {
        var $file = {
            id: '_id',
            name: file.name
        };

        $file.filetype = 'FILE';
        if (file.type.indexOf('/') > -1 && file.type.split('/').length) {
            $file.filetype = file.type.split('/')[1];

            if ($file.filetype.length > 3) {
                $file.filetype = file.name.match(/\.(.+)$/)
                    ? file.name.match(/\.(.+)$/)[1]
                    : 'FILE';
            }
        }

        $file.filesize = file.size / 1024;
        var magnitude = 'Kb';
        if ($file.filesize > 1024) {
            magnitude = 'Mb';
            $file.filesize = file.filesize / 1024;
        }

        $file.filesize = $file.filesize.toFixed(2) + ' ' + magnitude;

        Upload.upload({
            url: Settings.APIUrl + '/company/files',
            headers: {
                SessionId: AuthSrvc.sessionId()
            },
            file: file
        }).success(function (uploadedFile) {
            callback(null, uploadedFile);
        }).error(callback)
            .progress(function (evt) {
                if (progressCallback) {
                    progressCallback(evt.loaded, evt.total);
                }
            });
    };

    methods.delete = function (fileId, callback) {
        Attachment.customDELETE('', { attachmentId: fileId })
            .then(function (data) {
                callback(null, data);
            }, callback);
    };

    return methods;
};

export default AttachmentService;
