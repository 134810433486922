import React, { useState, useEffect, MouseEvent } from 'react';
import { getService } from 'reactInAngular';
import {Menu, MenuItem, Button, Typography, Box} from '@mui/material';

import { ArrowDropDown } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import {FeedbackContainer, IdeaComment} from '../../types';
import HfMultiLanguageText from '../../../hf-multilanguage-text/hfMultilanguageText';
import {darkDropdown, whiteGrey} from '../../../../../utilities/color.utilities';
import {useTheme} from '@mui/material/styles';



interface CommentContainerSelectorProps {
    comment: IdeaComment;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;
    noContainerText?: string;
    removeContainerText?: string;
    noContainerOnPeriodText?: string;
}

const CommentContainerSelector: React.FC<CommentContainerSelectorProps> = ({
    comment,
    onCommentUpdated,
    noContainerText = 'No container selected',
    removeContainerText = 'Remove container',
    noContainerOnPeriodText = 'No containers available for this period',
}) => {
    const [containers, setContainers] = useState<FeedbackContainer[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const FeedbackContainersService = getService('FeedbackContainersService');
    const Comments = getService('Comments');
    const ErrorSvrc = getService('ErrorSvrc');

    const theme = useTheme();

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeFeedbackContainer = (newContainerId: string) => {
        if (newContainerId !== comment.container?.id) {
            Comments.setCommentContainer(comment.id, newContainerId, (err: any, result: { container: { title: string } }) => {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    onCommentUpdated(err, comment.id);
                }
            });
        }
        handleClose();
    };

    const removeContainer = () => {
        Comments.removeCommentContainer(comment.id, (err: any, result: { container: { title: string } }) => {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                onCommentUpdated(err, comment.id);
            }
        });
        handleClose();
    };

    /*
        TODO: This should come from a Hook, so we can reuse between components
     */
    useEffect(() => {
        const params = {
            activeOn: comment.date,
            toContainer: 100,
            draft: false,
        };

        FeedbackContainersService.list(comment.feedbackType, params, (err: any, fetchedContainers: { results: FeedbackContainer[] }) => {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                setContainers(fetchedContainers.results);
            }
        });
    }, [comment.date, comment.feedbackType]);



    return (
        <Box>
            <Tooltip
                title={
                    comment.container ? (
                        <HfMultiLanguageText multilanguage={comment.container.title} replaceTokens={false} />
                    ) : (
                        noContainerText
                    )
                }
            >
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    startIcon={<ArrowDropDown />}
                    sx={{
                        color: 'gray',
                        justifyContent: 'left',
                        width: '100%',
                        backgroundColor: whiteGrey,
                        border: '0px',
                        fontSize: theme.typography.caption.fontSize,
                        display: 'inline-flex',
                        maxHeight: '27px',
                        textTransform: 'none',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    }}
                >
                    {comment.container ? (
                        <HfMultiLanguageText multilanguage={comment.container.title} replaceTokens={false} />
                    ) : (
                        noContainerText
                    )}
                </Button>
            </Tooltip>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    '& .MuiMenu-paper': {
                        backgroundColor: darkDropdown,
                        boxShadow: '0 0 3px rgba(86, 96, 117, 0.7)',
                        border: 'none',
                        borderRadius: '3px',
                        fontSize: theme.typography.caption.fontSize,
                        padding: '0',
                    },
                }}
            >
                {containers.map((container) => (
                    <MenuItem key={container.id} onClick={() => changeFeedbackContainer(container.id)}>

                        <Typography color={theme.palette.getContrastText(darkDropdown)} variant={'caption'}>
                            <HfMultiLanguageText multilanguage={container.title} replaceTokens={false} />
                        </Typography>
                    </MenuItem>
                ))}
                {comment.container && <hr />}
                {comment.container && (
                    <MenuItem onClick={removeContainer}>

                        <Typography color={theme.palette.error.main} variant={'caption'}>
                            {removeContainerText}
                        </Typography>

                    </MenuItem>
                )}
                {(!containers || containers.length === 0) && (
                    <MenuItem>
                        <Typography variant={'caption'}>
                            {noContainerOnPeriodText}
                        </Typography>
                    </MenuItem>
                )}
            </Menu>
        </Box>
    );
};


export default CommentContainerSelector;
