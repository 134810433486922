'use strict';

import template from './hf-image-search.html';

var HFImageSearchCtrl = function ($scope, UnsplashService, ErrorSvrc) {
    'ngInject'
    var _this = this;
    _this.imageSearchName = '';

    _this.onSearchTextChange = function (newSearchText) {
        _this.imageSearchName = newSearchText
    }

    _this.searchImages = function () {
        _this.loading = true;
        UnsplashService.searchImages(_this.imageSearchName, _this.numResults, function (err, results) {
            _this.foundImages = [];
            _this.loading = false;
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.foundImages = results;
                _this.foundImages.unshift({
                    image: _this.defaultImage,
                    author: 'HappyForce'
                });
            };
        });
    };

    _this.selectImage = function (selectImage) {

        _this.foundImages.forEach(function (image) {
            if (image.image != selectImage.image) {
                image.selected = false;
            }
        });

        if (_this.onSelect) {
            if (selectImage.selected) {
                _this.onSelect(null);
                selectImage.selected = false;
            } else {
                _this.onSelect(selectImage.image);
                selectImage.selected = true;
            }
        }
    };





    this.$onInit = function () {
        ['imageSearchName'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n !== o) {
                    _this.searchImages();
                };
            });
        });

        if (!_this.numResults) {
            _this.numResults = 7;
        }
    };
};

const hfImageSearch = angular.module('happyForceApp')
    .directive('hfImageSearch', function () {
        return {
            scope: {
                currentImage: '<',
                defaultImage: '<',
                onSelect: '<',
                small: '<',
                numResults: '<'
            },
            template: template,
            controller: HFImageSearchCtrl,
            controllerAs: '$ctrl',
            bindToController: true,
            transclude: true
        };
    });

export default hfImageSearch;
