import React from 'react';
import { getService } from 'reactInAngular';

import {Box, Stack, Typography} from '@mui/material';
import CommentMain from '../../components/comment-main/CommentMain';
import CommentActionsBar from '../../components/comment-actions-bar/CommentsActionBar';
import HfMultilanguageText from '../../../hf-multilanguage-text/hfMultilanguageText';
import {CheckInComment} from '../../types';
import CircleDot from '../../../hf-styled-components/ColoredCircleDot';
import CommentReactionsBar from '../../components/comment-reactions-bar/CommentReactionsBar';


interface CheckInFeedbackTypeProps {
    comment: CheckInComment;
    showConversationLink?: boolean;
    showModerateLink: boolean;
    showSwapPrivacy: boolean;
    hideHeader?: boolean;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;

}

const CheckInFeedbackType: React.FC<CheckInFeedbackTypeProps> = ({
    comment,
    showConversationLink = true,
    showModerateLink,
    showSwapPrivacy,
    onCommentUpdated,
    hideHeader = false
}) => {

    const $translate = getService('$translate');


    const renderVotes = () => {

        return (
            <>
                {comment.votes.map((vote, index) => {
                    return (
                        <Box key={index} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <CircleDot color={vote.color} size={10} />
                            <Typography variant={'caption'} marginLeft={1}>
                                ({vote.score ? vote.score : vote.id}) -
                            </Typography>

                            <Typography variant={'caption'} marginLeft={1}>
                                <HfMultilanguageText multilanguage={vote.label}/>
                            </Typography>
                        </Box>
                    )})
                }
            </>
        )
    }




    return (
        <CommentMain
            comment={comment}
            hideType={hideHeader}
            feedbackTypeText={$translate.instant('FEEDBACK_TYPE_QUESTION')}
            onCommentUpdated={onCommentUpdated}
            postHeaderZone={
                <Stack direction={'column'} alignItems={'flex-start'} gap={1}>

                    {!hideHeader &&
                        <Typography variant={'body3'} fontWeight={'bold'}>
                            <HfMultilanguageText multilanguage={comment.questionTitle}/>
                        </Typography>
                    }

                    {(comment.votes && comment.votes.length > 0) && (
                        <Stack direction={'row'}>
                            <Typography variant={'caption'} marginRight={1}>
                                {$translate.instant('QUESTION_FEEDBACK_VOTE_LABEL')}
                            </Typography>
                            {renderVotes()}
                        </Stack>
                    )}


                </Stack>
            }
            reactionsZone={
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    {comment.privated && <Box/>}
                    {!comment.privated &&  <CommentReactionsBar
                        comment={comment}
                        showRepliesAction={false}
                        onCommentUpdated={onCommentUpdated}
                    />}

                    <CommentActionsBar
                        comment={comment}
                        showInappropriate={!comment.privated && showModerateLink}
                        showConversation={showConversationLink}
                        showReplyWithAnnouncement={false}
                        showSwapPrivacy={showSwapPrivacy}
                        onCommentUpdated={onCommentUpdated}
                    />
                </Stack>
            }
        />
    );
};


export default CheckInFeedbackType;
