import React, {useEffect, useState} from 'react';
import { getService } from 'reactInAngular';

import {Box, Stack, Typography} from '@mui/material';
import CommentMain from '../../components/comment-main/CommentMain';
import CommentActionsBar from '../../components/comment-actions-bar/CommentsActionBar';
import HfMultilanguageText from '../../../hf-multilanguage-text/hfMultilanguageText';
import {QuestionType, SurveyComment} from '../../types';
import CircleDot from '../../../hf-styled-components/ColoredCircleDot';
import HFTag from '../../../hf-tag/hf-tag';


interface SurveyFeedbackTypeProps {
    comment: SurveyComment;
    showConversationLink?: boolean;
    hideHeader?: boolean;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;

}

const SurveyFeedbackType: React.FC<SurveyFeedbackTypeProps> = ({
    comment,
    showConversationLink = true,
    onCommentUpdated,
    hideHeader = false
}) => {

    const $translate = getService('$translate');
    const Surveys = getService('Surveys');
    const [surveyTitle, setSurveyTitle] = useState('');
    const [roundTitle, setRoundTitle] = useState('');


    useEffect(() => {

        if (comment.surveyId) {
            Surveys.get(comment.surveyId, (err, survey) => {
                if (!err) {
                    setSurveyTitle(survey.title);
                    if (survey.schedulePlan.activationRule.ruleType !== 'CONTINUOUS') {
                        const foundRound = survey.rounds.find(round => round.id === comment.roundId);
                        if (foundRound) {setRoundTitle(foundRound.title);}
                    }
                } else {
                    console.error(err);
                }
            });
        }
    }, [comment]);

    const renderVotes = () => {

        return (
            <>
                {comment.votes.map((vote, index) => {
                    return (
                        <Box key={index} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <CircleDot color={vote.color} size={10} />
                            <Typography variant={'caption'} marginLeft={1}>
                                ({vote.score ? vote.score : vote.id}) -
                            </Typography>

                            <Typography variant={'caption'} marginLeft={1}>
                                <HfMultilanguageText multilanguage={vote.label}/>
                            </Typography>
                        </Box>
                    )})
                }
            </>
        )
    }


    return (
        <CommentMain
            comment={comment}
            hideType={hideHeader}
            feedbackTypeText={$translate.instant('FEEDBACK_TYPE_SURVEY')}
            onCommentUpdated={onCommentUpdated}
            postHeaderZone={
                <Stack direction={'column'} alignItems={'flex-start'} gap={1}>

                    {!hideHeader &&
                        <HFTag status={'primary'} text={
                            <>
                                <HfMultilanguageText multilanguage={surveyTitle}/>
                                {' - '}
                                <HfMultilanguageText multilanguage={roundTitle}/>
                            </>

                        }></HFTag>
                    }

                    <Typography variant={'body3'} fontWeight={'bold'}>
                        <HfMultilanguageText multilanguage={comment.questionTitle}/>

                    </Typography>


                    {(comment.questionType !== QuestionType.OPEN && comment.votes && comment.votes.length > 0) && (
                        <Stack direction={'row'}>
                            <Typography variant={'caption'} marginRight={1}>
                                {$translate.instant('QUESTION_FEEDBACK_VOTE_LABEL')}
                            </Typography>
                            {renderVotes()}
                        </Stack>
                    )}


                </Stack>
            }
            reactionsZone={
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Box/>
                    <CommentActionsBar
                        comment={comment}
                        showInappropriate={false}
                        showConversation={showConversationLink}
                        showReplyWithAnnouncement={false}
                        showSwapPrivacy={false}
                        onCommentUpdated={onCommentUpdated}
                    />
                </Stack>
            }
        />
    );
};


export default SurveyFeedbackType;


