import React, {useState} from 'react';
import {Button, Stack} from '@mui/material';
import UsersBulkStepper from './components/UsersBulkStepper';
import DashboardHeader from '../../../shared/components/_migrated/dashboard-header/hfDashboardHeader';
import {getService} from '../../../../migration_utils/react-in-angular';
import {WhiteBgPaper} from '../../../shared/new-components/hf-styled-components/WhiteBgPaddedPapper';


/*
* ManageUsersView component holds all the components for inviting and managing users
* on the platform
 */
const ManageUsersBulkView = () => {

    const $translate = getService('$translate');



    return (
        <Stack gap={2}>


            <DashboardHeader
                showBackArrow={true}
                hfTitle={$translate.instant('BULK_EMPLOYEE_PROCESSING_TITLE')}
                hfSubtitle={$translate.instant('BULK_EMPLOYEE_PROCESSING_SUBTITLE')}
            />


            <WhiteBgPaper>
                <UsersBulkStepper />
            </WhiteBgPaper>


        </Stack>
    )
}



export default ManageUsersBulkView;
