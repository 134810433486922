import React, { useState, useEffect } from 'react';
import { getService } from 'reactInAngular';

import {Box, Stack} from '@mui/material';
import CommentMain from '../../components/comment-main/CommentMain';
import CommentActionsBar from '../../components/comment-actions-bar/CommentsActionBar';
import {ClassificationFollowupComment} from '../../types';
import HFTag from '../../../hf-tag/hf-tag';
import {useTheme} from '@mui/material/styles';


interface HIClassificationFollowUpFeedbackTypeProps {
    comment: ClassificationFollowupComment;
    showConversationLink?: boolean;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;

}

const HIClassificationFollowUpFeedbackType: React.FC<HIClassificationFollowUpFeedbackTypeProps> = ({
    comment,
    showConversationLink = true,
    onCommentUpdated,
}) => {
    const theme = useTheme();
    const [color, setColor] = useState<string | null>(null);
    const [textColor, setTextColor] = useState<string | null>(null);
    const [label, setLabel] = useState<string | null>(null);

    const $translate = getService('$translate');
    const ClassificationService = getService('ClassificationService');


    useEffect(() => {

        if (comment) {
            setColor(ClassificationService.colors[comment.type]);
            setTextColor(theme.palette.getContrastText(ClassificationService.colors[comment.type]));
            setLabel( $translate.instant('ATTITUDE_' + comment.type.toUpperCase()));
        }


    }, [comment, showConversationLink, $translate]);

    return (
        <CommentMain
            comment={comment}
            hideType={false}
            feedbackTypeText={$translate.instant('HI_FOLLOWUP_QUESTION')}
            onCommentUpdated={onCommentUpdated}
            postHeaderZone={
                <Stack direction={'column'} alignItems={'flex-start'} gap={1}>
                    <HFTag color={color}
                        textColor={textColor}
                        text={label}></HFTag>
                </Stack>
            }
            reactionsZone={
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Box/>
                    <CommentActionsBar
                        comment={comment}
                        showInappropriate={false}
                        showConversation={showConversationLink}
                        showReplyWithAnnouncement={false}
                        showSwapPrivacy={false}
                        onCommentUpdated={onCommentUpdated}
                    />
                </Stack>
            }
        />
    );
};


export default HIClassificationFollowUpFeedbackType;
