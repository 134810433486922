import React, {useEffect, useState} from 'react';
import {getService} from 'reactInAngular';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import {InappropriateReportType, InappropriateTypeComment} from '../../types';
import CloseableDialogTitle from '../../../hf-closeable-dialog-title/CloseableDialogTitle';
import CommentInappropriateReportsModal from './components/CommentInappropriateReportsModal';

interface CommentInappropriateModalProps {
    show: boolean;
    comment: InappropriateTypeComment;
    isReplyOf?: string;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;
    onClose: () => void;
}

const CommentInappropriateModal: React.FC<CommentInappropriateModalProps> = ({
    show,
    comment,
    isReplyOf,
    onCommentUpdated,
    onClose,
}) => {
    const $translate = getService('$translate');
    const Comments = getService('Comments');
    const toaster = getService('toaster');
    const ErrorSvrc = getService('ErrorSvrc');
    const [inappropriate, setInappropriate] = useState<boolean>(!!comment.inappropriate);
    const [referee, setReferee] = useState<boolean>(!!comment.refereed);
    const [reasonType, setReasonType] = useState<InappropriateReportType>(comment.inappropriateReasonType || InappropriateReportType.OFFENSIVE);
    const [reason, setReason] = useState<string>(comment.inappropriateReason || '');
    const [inappropriateOffensive, setInappropriateOffensive] = useState<any[]>([]);
    const [inappropriateDisrespectful, setInappropriateDisrespectful] = useState<any[]>([]);
    const [inappropriateOther, setInappropriateOther] = useState<any[]>([]);
    const [showReports, setShowReports] = useState<boolean>(false);

    useEffect(() => {
        const offensive: any[] = [];
        const disrespectful: any[] = [];
        const other: any[] = [];

        comment.employeeInnappropiateFeedback?.forEach((feedback: any) => {
            if (feedback.type === 'OFFENSIVE') {
                offensive.push(feedback);
            } else if (feedback.type === 'DISRESPECTFUL') {
                disrespectful.push(feedback);
            } else if (feedback.type === 'OTHER') {
                other.push(feedback);
            }
        });

        setInappropriateOffensive(offensive);
        setInappropriateDisrespectful(disrespectful);
        setInappropriateOther(other);
    }, [comment]);

    const handleSave = () => {


        const params = {
            reason: reason,
            reasonType: reasonType,
            inappropriate: inappropriate,
            referee: referee,
        };

        Comments.inappropiate(comment.id, isReplyOf, params, (err: any, updatedComment: InappropriateTypeComment) => {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                const message = updatedComment.inappropriate
                    ? $translate.instant('INAPPROPRIATE_DONE')
                    : $translate.instant('UNMARKED_INAPPROPRIATE');
                toaster.pop('success', null, message);
                onCommentUpdated && onCommentUpdated(null, comment.id);
                onClose();
            }
        });


        //
    };

    return (
        <>
            <Dialog open={show} onClose={() => onClose()} maxWidth="sm" fullWidth>

                <CloseableDialogTitle
                    title={$translate.instant('MODERATE_COMMENT')}
                    onClose={() => {onClose()}}
                />

                <DialogContent>

                    <Stack direction="column"
                        gap={2}
                        justifyContent={'space-between'}>

                        <Paper elevation={1} sx={(theme) => ({
                            padding: theme.spacing(2),
                        })}>
                            <Typography variant={'body3'} dangerouslySetInnerHTML={{ __html: comment.comment }} />
                        </Paper>

                        <Divider />

                        <Typography variant="h6">
                            {$translate.instant('COMMENTS_REPORTED_BY_EMPLOYEES')}
                        </Typography>

                        <Stack direction="row"
                            gap={1}
                            alignItems={'center'}
                            justifyContent={'space-between'}>
                            <Box>
                                <Typography variant={'body3'} fontWeight={inappropriateOffensive.length ? 'bold' : 'inherit'}>
                                    {`${inappropriateOffensive.length} ${$translate.instant('OFFENSIVE_LANGUAGE')}`}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant={'body3'} fontWeight={inappropriateDisrespectful.length ? 'bold' : 'inherit'}>
                                    {`${inappropriateDisrespectful.length} ${$translate.instant('LACK_OF_RESPECT')}`}
                                </Typography>
                            </Box>
                            <Box>
                                {inappropriateOther.length > 0 ? (
                                    <Box component={'a'}
                                        onClick={() => setShowReports(true)}
                                        color="primary"
                                    >
                                        <Typography variant={'body3'} fontWeight={'bold'}>
                                            {`${inappropriateOther.length} ${$translate.instant('OTHERS')}`}
                                        </Typography>

                                    </Box>
                                ) : (
                                    <Typography variant={'body3'} >
                                        {`${inappropriateDisrespectful.length} ${$translate.instant('OTHERS')}`}
                                    </Typography>
                                )}
                            </Box>
                        </Stack>


                        <Stack>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={inappropriate}
                                        onChange={(e) => {
                                            setInappropriate(e.target.checked);
                                            if (!reasonType) {setReasonType(InappropriateReportType.OFFENSIVE);}
                                        }}
                                        disabled={referee}
                                    />
                                }
                                label={$translate.instant('MARK_COMMENT_INAPPROPRIATE')}
                            />
                            <Typography variant="body3" color="textSecondary">
                                {$translate.instant('INAPPROPIATE_WARNING')}
                            </Typography>
                        </Stack>
                        <Stack>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={referee}
                                        onChange={(e) => {
                                            setReferee(e.target.checked);
                                            if (e.target.checked && !inappropriate) {
                                                setInappropriate(true);
                                                if (!reasonType) {setReasonType(InappropriateReportType.OFFENSIVE);}
                                            }
                                        }}
                                    />
                                }
                                label={$translate.instant('BLOCK_EMPLOYEE_BY_COMMENT')}
                            />
                            <Typography variant="body3" color="textSecondary">
                                {$translate.instant('BLOCK_EMPLOYEE_BY_COMMENT_DESCRIPTION')}
                            </Typography>
                        </Stack>
                        {true && (
                            <Box>
                                <Typography variant="subtitle1">
                                    {$translate.instant('REASON')}
                                </Typography>
                                <RadioGroup
                                    value={inappropriate ? reasonType : null}
                                    onChange={(e) => setReasonType(e.target.value)}
                                >
                                    <FormControlLabel
                                        disabled={!inappropriate}
                                        value={InappropriateReportType.OFFENSIVE}
                                        control={<Radio />}
                                        label={$translate.instant('OFFENSIVE_LANGUAGE')}
                                    />
                                    <FormControlLabel
                                        disabled={!inappropriate}
                                        value={InappropriateReportType.DISRESPECTFUL}
                                        control={<Radio />}
                                        label={$translate.instant('LACK_OF_RESPECT')}
                                    />
                                    <FormControlLabel
                                        disabled={!inappropriate}
                                        value={InappropriateReportType.OTHER}
                                        control={<Radio />}
                                        label={$translate.instant('OTHERS')}
                                    />
                                </RadioGroup>
                                {reasonType === 'OTHER' && (
                                    <Box mt={2}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={3}
                                            variant="outlined"
                                            value={reason}
                                            onChange={(e) => setReason(e.target.value)}
                                            placeholder={$translate.instant('OTHERS')}
                                            error={!reason || reason.length < 10}
                                            helperText={
                                                !reason || reason.length < 10
                                                    ? $translate.instant('OTHERS_TO_SHORT')
                                                    : ''
                                            }
                                        />
                                    </Box>
                                )}
                            </Box>
                        )}


                    </Stack>


                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleSave}
                        variant="contained"
                        color="primary"
                        disabled={
                            reasonType === 'OTHER' && (!reason || reason.length < 10)
                        }
                    >
                        {$translate.instant('SAVE')}
                    </Button>
                </DialogActions>
            </Dialog>
            <CommentInappropriateReportsModal show={showReports} onClose={() => setShowReports(false)} reportedMessages={comment.employeeInnappropiateFeedback}></CommentInappropriateReportsModal>
        </>

    );
};

export default CommentInappropriateModal;

