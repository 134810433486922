import React from 'react';
import { angularize } from 'reactInAngular';
import Icon from '@mui/material/Icon';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Tooltip from '@mui/material/Tooltip';
import Style from '../../../../utilities/style.utils';
import angular from 'angular';
interface ArrowIndicatorProps {
    value: number;
    label: string;
}

const ArrowIndicator: React.FC<ArrowIndicatorProps> = ({ value, label }) => {
    if (value === 0) {
        return null;
    }

    return (
        <Tooltip title={label} placement="left">
            <Icon style={{ color: value < 0 ? Style.bad : Style.good }}>
                {value < 0 ? (
                    <ArrowDownwardIcon fontSize="inherit" />
                ) : (
                    <ArrowUpwardIcon fontSize="inherit" />
                )}
            </Icon>
        </Tooltip>
    );
};

// Angular integration with proper typings for bindings
angularize(
    ArrowIndicator,
    'hfArrowIndicator',
    angular.module('happyForceApp'),
    {
        value: '<', // One-way binding
        label: '<', // One-way binding
    }
);

export default ArrowIndicator;
