import React, {useCallback, useEffect, useState} from 'react';
import {Accept, useDropzone} from 'react-dropzone';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import {Box, SxProps, Theme} from '@mui/material';
import {angularize, getService} from 'reactInAngular';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgressWithLabel from '../hf-circular-progress';

interface FileUploadProps {
    onFileUpload: (file: File | File[]) => Promise<void>;
    acceptFileTypes?: Accept;
    instructions?: string;
    subInstructions?: string;
    multiple?: boolean;
    progress?: number;
    sx?: SxProps<Theme>;
}

const UploadPaper = styled(Paper)(({ theme }) => ({
    border: `1px dashed ${theme.palette.primary.main}`,
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    padding: theme.spacing(2),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'relative',
    minWidth: '200px',
    minHeight: '100px',
}));

const HfDropFileArea: React.FC<FileUploadProps> = ({ onFileUpload, acceptFileTypes, instructions, subInstructions, progress, sx, multiple=false }) => {

    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isDragActive, setIsDragActive] = useState(false);
    const [validDrag, setValidDrag] = useState(false);
    const [dragCounter, setDragCounter] = useState(0);

    const $translate = getService('$translate')


    const onDrop = useCallback((acceptedFiles: File[]) => {


        if (acceptedFiles.length > 0) {

            if (multiple) {
                handleFileUpload(acceptedFiles);
            } else {
                const file = acceptedFiles[0];
                handleFileUpload([file]);
            }
        }
        setIsDragActive(false);
        setValidDrag(false);
        setDragCounter(0);
    }, []);

    const handleFileUpload = async (files: File[]) => {

        setUploading(true);
        setError(null);

        try {
            await Promise.all(
                files.map(async (file) => {
                    await onFileUpload(file);
                })
            );
        } catch (err: any) {
            setError(err.message || 'Error uploading file');
        } finally {
            setUploading(false);
        }


    };



    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        accept: acceptFileTypes,
        noClick: true,
        noKeyboard: true
    });


    useEffect(() => {
        const handleDragEnter = (event: DragEvent) => {
            setDragCounter(prev => prev + 1);
            setIsDragActive(true);
            const dt = event.dataTransfer;
            const files = dt?.items;
            if (files && files.length > 0 && acceptFileTypes) {
                // TODO: Check if the file is accepted
                setValidDrag(true);
            }
        };

        const handleDragLeave = () => {
            setDragCounter(prev => prev - 1);
            if (dragCounter === 1) {
                setIsDragActive(false);
                setValidDrag(false);
            }
        };

        const handleDragOver = (event: DragEvent) => {
            event.preventDefault(); // Necesario para permitir el evento drop
        };

        window.addEventListener('dragenter', handleDragEnter);
        window.addEventListener('dragleave', handleDragLeave);
        window.addEventListener('dragover', handleDragOver);

        return () => {
            window.removeEventListener('dragenter', handleDragEnter);
            window.removeEventListener('dragleave', handleDragLeave);
            window.removeEventListener('dragover', handleDragOver);
        };
    }, [acceptFileTypes]);

    useEffect(() => {
        const handleWindowDragLeave = (event: DragEvent) => {
            if (event.clientX === 0 && event.clientY === 0) {
                setIsDragActive(false);
                setValidDrag(false);
                setDragCounter(0);
            }
        };

        window.addEventListener('dragleave', handleWindowDragLeave);

        return () => {
            window.removeEventListener('dragleave', handleWindowDragLeave);
        };
    }, []);


    return (
        <UploadPaper sx={sx} {...getRootProps()} elevation={0} onClick={open} role={'button'}>
            <input {...getInputProps()} />


            <>
                <Typography variant='h3' sx={{ fontSize: '1.5em', marginBottom: theme => theme.spacing(1) }}>
                    {instructions || $translate.instant('FILE_UPLOAD_DROP_INSTRUCTIONS')}
                </Typography>


                <CloudUploadIcon
                    sx={{
                        fontSize: '4em',
                        animation: (isDragActive && validDrag) ? 'pulse 1.5s infinite' : 'none',
                        '@keyframes pulse': {
                            '0%': {
                                transform: 'scale(1)',
                            },
                            '50%': {
                                transform: 'scale(1.5)',
                            },
                            '100%': {
                                transform: 'scale(1)',
                            },
                        },
                    }}
                />


                <Typography variant='caption' sx={{ fontSize: '1em', marginTop: theme => theme.spacing(1) }}>
                    {subInstructions || $translate.instant('FILE_UPLOAD_DROP_SUB_INSTRUCTIONS')}
                </Typography>

            </>

            {progress ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CircularProgressWithLabel value={progress} size={50} />
                </Box>
            ) : <></>}

            {error && (
                <Alert severity="error" style={{ marginTop: '10px' }}>
                    {error}
                </Alert>
            )}
        </UploadPaper>
    );
};







export default HfDropFileArea;
