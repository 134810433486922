import React, { JSX } from "react";
import {Alert, AlertProps} from '@mui/material';
import {useTheme} from '@mui/material/styles';


interface HfBannerProps extends AlertProps {
    children: JSX.Element
    type?: 'primary' | 'success' | 'warning' | 'danger' | 'error' | 'neutral' | 'complementary01' | 'complementary02' | 'complementary03' | 'complementary04'
    backgroundColor?: string,
}


/*
*  We need to use this component while we are migrating to React
* since we can't pass to React components Angular components as children
*
 */
export const HfBannerReact = ({children, backgroundColor, type='neutral', ...alertProps}: HfBannerProps) => {

    const theme =  useTheme();


    const getSeverity = () => {
        switch (type) {
            case 'primary':
                return 'info';
            case 'success':
                return 'success';
            case 'warning':
                return 'warning';
            case 'danger':
                return 'error';
            case 'error':
                return 'error';
            case 'neutral':
                return 'info';
            case 'complementary01':
                return 'info';
            case 'complementary02':
                return 'info';
            case 'complementary03':
                return 'info';
            case 'complementary04':
                return 'info';
            default:
                return 'info';
        }
    }

    if (backgroundColor === undefined) {
        return (
            <Alert
                sx={{
                    width: '100%',
                    flex: 1,
                    justifyContent: 'center',
                }}
                {...alertProps}
                severity={getSeverity()}>
                {children}
            </Alert>
        )
    } else {
        return (
            <Alert
                sx={{
                    width: '100%',
                    flex: 1,
                    justifyContent: 'center',
                    backgroundColor: backgroundColor,
                    borderColor: theme.palette.getContrastText(backgroundColor),
                    color: theme.palette.getContrastText(backgroundColor)
                }}
                {...alertProps}
                severity={getSeverity()}>
                {children}
            </Alert>
        )
    }


};
