'use strict';

import Dates from "../../../../../utilities/date.utilities";

function FollowupBoxCtrl($scope, ErrorSvrc, $translate, ClassificationService, Comments) {
    'ngInject';
    var _this = this;

    _this.currentPage = 1;
    _this.itemsPerPage = 5;

    _this.loadComments = function () {
        _this.loading = true;
        _this.comments = [];
        var to = Dates.lastDayOfMonth(_this.to);
        var from = Dates.firstDayOfMonth(_this.to);
        var params = {
            from: from,
            to: to,
            fromComment: ((_this.currentPage - 1) * 5),
            toComment: ((_this.currentPage) * 5)
        };

        params.filters = _this.filters;
        params.groupId = _this.groups;
        if (_this.level) {
            params.hierarchyId = _this.level.id;
        }

        ClassificationService.feedback.get(params, function (err, followupComments) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.totalComments = followupComments.total;
                _this.comments = followupComments.result;
            }
            _this.loading = false;
        });
    };


    _this.onCommentUpdated = function (err, updatedCommentId) {

        // Load and update the changedComment
        Comments.getOne(updatedCommentId, function (err, updatedComment) {
            const index = _this.comments.findIndex(comment => comment.id === updatedCommentId);
            _this.comments[index] = updatedComment;
        });

    };

    this.$onInit = function () {
        _this.loadComments();

        ['to', 'level', 'filters', 'groups'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n == o || !n)  {
                    return;
                }
                _this.loadComments();
            }, true);
        });
    };


}

const hfEngagementFollowupBox = angular.module('happyForceApp').directive('hfEngagementFollowupBox', function () {
    return {
        scope: {
            to: '=',
            level: '=',
            filters: '=',
            groups: '='
        },
        restrict: 'E',
        template: require('./followup_box.html'),
        controller: FollowupBoxCtrl,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfEngagementFollowupBox;
