import React, { useState, useEffect } from 'react';

import {Box, Grid, Stack, Typography} from '@mui/material';
import {getService} from 'reactInAngular';
import Numbers from '../../../../../../utilities/number.utilities';
import Valuation from '../../../../../../utilities/valuation.utils';
import Style from '../../../../../../utilities/style.utils';
import EngagementCellWrapper from './EngagementCellWrapper';
import Tooltip from '@mui/material/Tooltip';
import EngagementCellDataPopover from './EngagementCellDataPopover';
import {useTheme} from '@mui/material/styles';
import ArrowIndicator from '../../../../../../shared/components/_migrated/arrow-indicator-directive/arrow-indicator';


interface ScoreFactorCellProps {
    cellData?: {
        result: number | null;
        variation?: number;
        quality?: 'NORMAL' | 'GOOD' | string;
        valuation?: any; // Replace `any` with the correct type for valuation
    };
    type: 'SCORE' | 'FACTOR';
    loading: boolean;
    segment: object; // Replace `object` with the exact type for segment
    column: object; // Replace `object` with the exact type for column
    selectedInsight?: object; // Replace `object` with the exact type for selectedInsight
    onCellClick: (segment: object, column: object, selectedInsight?: object) => void; // Update types if needed
}

const ScoreFactorCell: React.FC<ScoreFactorCellProps> = ({
    type,
    cellData,
    loading,
    segment,
    column,
    selectedInsight,
    onCellClick,
}) => {
    const [value, setValue] = useState(null);
    const [hasData, setHasData] = useState(true);
    const [showImpacts, setShowImpacts] = useState(false);
    const [impactHI, setImpactHI] = useState(false);
    const [impactENPS, setImpactENPS] = useState(false);
    const [color, setColor] = useState('');
    const [hiOpacity, setHiOpacity] = useState(1);
    const [enpsOpacity, setEnpsOpacity] = useState(1);
    const [highlightLevel, setHighlightLevel] = useState(0);
    const $translate = getService('$translate');


    const theme = useTheme();


    useEffect(() => {

        if (cellData) {




            if (type === 'SCORE') {
                setShowImpacts(true);
                setValue(Numbers.roundNumber(cellData.result, 1));

                const isHighHIImpact = cellData.impactHI === 'HIGH' || cellData.impactHI === 'TOTAL';
                const isHighEnpsImpact = cellData.impactENPS === 'HIGH' || cellData.impactENPS === 'TOTAL';
                setImpactHI(isHighHIImpact);
                setImpactENPS(isHighEnpsImpact);
                setHiOpacity(isHighHIImpact ? 1 : 0.3);
                setEnpsOpacity(isHighEnpsImpact ? 1 : 0.3);

            } else if (type === 'FACTOR') {
                setValue(Numbers.roundNumber(cellData.result, 1));
            }

            const isRepresentative = cellData.quality === 'NORMAL' || cellData.quality === 'GOOD';
            let calculatedColor = Style.noData;
            if (cellData.result === null || cellData.result === undefined) {
                setHasData(false);
                calculatedColor = Style.noData;
                if (!isRepresentative) {
                    calculatedColor = Style.neutralNotRelevant;
                }
            } else {
                setHasData(true);
                calculatedColor = Valuation.valuationColor(cellData.valuation, false);
                if (!isRepresentative) {
                    calculatedColor = Valuation.valuationColorWithLowQuality(cellData.valuation, false);
                }
            }
            setColor(calculatedColor);
        }
    }, [cellData]);


    useEffect(() => {

        if (selectedInsight) {
            setHighlightLevel(selectedInsight.signalStrength ? selectedInsight.signalStrength : 0);
        } else {
            setHighlightLevel(0);
        }

    }, [selectedInsight]);

    const handleOnCellClick = () => {
        if (onCellClick) {
            onCellClick(segment, column, selectedInsight);
        }
    };

    const infoImpactPopover = (impactType) => {

        if (impactType === 'HI') {
            return impactHI ? $translate.instant('SCORE_IMPACTS_HI') : $translate.instant('SCORE_NO_IMPACTS_HI');
        } else if (impactType === 'ENPS') {
            return impactENPS ? $translate.instant('SCORE_IMPACTS_ENPS') : $translate.instant('SCORE_NO_IMPACTS_ENPS');
        }
    };

    return (

        <EngagementCellWrapper
            color={color}
            loading={loading}
        >
            <Stack direction={'column'}
                gap={1}
                width={'100%'}
                justifyContent={'space-between'}>

                <Grid container={true}>
                    <Grid item={true}
                        xs={4}>
                        <Box display={'hidden'}></Box>
                    </Grid>

                    <Grid item={true} xs={4} textAlign={'center'}>
                        <Typography variant={'body3'}>
                            {hasData ? (
                                <Stack direction={'row'} role="button" onClick={handleOnCellClick}>
                                    {value}
                                    <ArrowIndicator label={''} value={cellData?.variation} />
                                </Stack>
                            ) : (
                                <Box>{$translate.instant('NO_DATA')}</Box>
                            )}
                        </Typography>
                    </Grid>

                    <Grid item={true} xs={4}>
                        <EngagementCellDataPopover
                            hasData={hasData}
                            quality={cellData?.quality}
                            variation={cellData?.variation}
                            value={value}
                            companyBenchmark={cellData?.companyBenchmark}/>
                    </Grid>
                </Grid>

                {showImpacts && (

                    <Stack direction={'row'} justifyContent={'center'} gap={1}>
                        <Tooltip title={
                            <Typography variant="caption"
                                dangerouslySetInnerHTML={{__html: infoImpactPopover('HI')}}></Typography>
                        }
                        placement="bottom">
                            <Box component={'img'}
                                width={theme.typography.body1.fontSize}
                                height={theme.typography.body1.fontSize}
                                src="/images/hi_cell_hm.png"
                                style={{opacity: hiOpacity}}/>
                        </Tooltip>

                        <Tooltip title={
                            <Typography variant="caption"
                                dangerouslySetInnerHTML={{__html: infoImpactPopover('ENPS')}}></Typography>
                        }
                        placement="bottom">
                            <Box component={'img'}
                                width={theme.typography.body1.fontSize}
                                height={theme.typography.body1.fontSize}
                                src="/images/enps_cell_hm.png"
                                style={{opacity: enpsOpacity}}/>
                        </Tooltip>
                    </Stack>
                )}

            </Stack>

        </EngagementCellWrapper>

    );
};

export default ScoreFactorCell;
