


import template from './onboarding.html';

function OnboardingCtrl($scope, $location, AuthSrvc, ErrorSvrc, $state, $stateParams, $translate, $rootScope, CompanySvrc, Analytics) {

    $scope.failedReason = '';
    $scope.errorReason = '';
    $scope.email = '';
    $scope.password = '';
    $scope.hpLoginURL = 'https://directory.stg.cd.id.hp.com/directory/v1/oauth/authorize?response_type=code' +
        '&client_id=' + process.env.HP_CLIENT_ID +
        '&redirect_uri=' + process.env.HP_REDIRECT_URI +
        '&scope=user.profile.read+openid+offline_access+user.profile.write&prompt=consent&nonce=LoginWithHPID';
    $scope.loading = false;

    var stateParams = $state.params;
    var redirect = $state.params && $state.params.redirect;
    var extras = $state.params && $state.params.extras || {};
    var emailPaths = ['/onboarding/password', '/onboarding/method', '/onboarding/employeesent'];

    $scope.auth = AuthSrvc;

    if (AuthSrvc.isLoggedIn()) {
        $state.go(redirect || 'private.dashboard', extras);
    }

    if (emailPaths.indexOf($location.path()) > -1) {
        if ($state.params && $state.params.email) {
            $scope.email = $state.params.email;
        } else {
            $state.go('onboarding');
        }
    } else {
        $scope.email = '';
    }

    // We have to do this cheap thing, since the angular library we are using is not handling properly the logout
    function googleSignOut() {
        var auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut().then(function () {
            console.log('User Google signed out.');
        });
    }

    $scope.isLogged = function () {
        return AuthSrvc.isLoggedIn();
    };

    $scope.goTo = function (path) {
        stateParams.email = $scope.email;
        $state.go('onboarding/' + path, stateParams);
    };

    $scope.restart = function () {
        $state.go('onboarding', stateParams);
    };

    $scope.sendEmployeeCode = function () {
        AuthSrvc.sendEmployeeNewCode(
            $scope.email,
            function () {
                $state.go('onboarding/employeesent', stateParams);
            }, function (err) {

                $scope.executing = false;
                $scope.completed = true;
                $scope.failed = true;
                $scope.showSwalFromError(err);
            });
    };

    $scope.validateEmail = function () {
        // jscs:disable maximumLineLength
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        // jscs:enable maximumLineLength
        if (re.test($scope.email)) {
            AuthSrvc.checkMailAndUserType(
                $scope.email,
                function (userType) {

                    if (userType.valid) {
                        $scope.failed = false;
                        stateParams.email = $scope.email;
                        if (userType.userType === 'COMPANY_USER') {
                            
                            // Handle the case of the user having multiple login methods or only one
                            if (userType.allowedLoginMethods && userType.allowedLoginMethods.length === 1) {
                                // If there is only one login method, we go directly to it
                                if (userType.allowedLoginMethods.indexOf('HP_AUTH') > -1) {
                                    window.location.href = $scope.hpLoginURL;
                                } else if (userType.allowedLoginMethods.indexOf('USER_ACCESS_LINK') > -1) {
                                    $scope.sendLink();
                                } else if (userType.allowedLoginMethods.indexOf('USER_PASSWORD') > -1) {
                                    $state.go('onboarding/password', stateParams);
                                } else {
                                    $state.go('onboarding/method', stateParams);
                                }
                            } else {
                                // Otherwise, we go to the method selection
                                $state.go('onboarding/method', stateParams);
                            }
                        } else if (userType.userType === 'EMPLOYEE') {
                            // Send email recovery automatically
                            $scope.sendEmployeeCode();
                        }

                    } else {
                        $scope.executing = false;
                        $scope.completed = true;
                        $scope.failed = true;
                        $scope.failedReason = $translate.instant('LOGIN_MAIL_NOT_FOUND');
                    }

                },

                function (err) {
                    $scope.executing = false;
                    $scope.completed = true;
                    $scope.failed = true;
                    $scope.showSwalFromError(err);
                }
            );
        } else {
            $scope.failed = true;
            $scope.failedReason = $translate.instant('INVALID_EMAIL');
        }
    };

    $scope.resetErrors = function () {
        $scope.failedReason = null;
        $scope.failed = null;
    };

    $scope.sendLink = function () {
        AuthSrvc.sendAccessLink({
            email: $scope.email
        },
        function (res) {
            $scope.executing = false;
            $scope.completed = true;
            $scope.failed = false;
            $state.go('onboarding/sent');
        },

        function (err) {
            $scope.executing = false;
            $scope.completed = true;
            $scope.failed = true;
            swal($translate.instant(err.errorCode), '', 'error');
        });
    };

    $scope.is = function (value) {
        return ($location.path() === '/' + value);
    };

    $scope.current_year = new Date().getFullYear();

    /**
     * login()
     * @return {[type]}
     */
    $scope.login = function () {
        console.log('entro loging')
        $scope.executing = true;
        AuthSrvc.login({
            email: $scope.email,
            password: $scope.password,
            socialIdToken: $scope.socialIdToken,
            hpToken: $scope.hpToken,
            rememberMe: true
        },
        function (res) {
            $scope.executing = false;
            CompanySvrc.resetUserCompanyCache();
            $state.go(redirect || 'private.dashboard', stateParams);
            Analytics.trackEvent('login', 'email', 'success');
        },

        function (err) {
            $scope.executing = false;
            $scope.completed = true;
            $scope.failed = true;

            //Check if is a 401, which means bad credentials here:
            if (err.status === 401) {
                $scope.failedReason = $translate.instant(err.data && err.data.errorCode || 'LOGIN_ERROR');
            } else if (err.status === 423) {
                $scope.failedReason = $translate.instant('COMPANY_BLOCKED_EXPLANATION');
            } else {
                $scope.failedReason = ErrorSvrc.getErrorMessage(err);
                if ($scope.failedReason === -1) {
                    $scope.failedReason = $translate.instant('CONNECTION_ERROR');
                }
            }

            Analytics.trackEvent('login', 'email', 'fail');
        });
    };

    $scope.loginWithHP = function (code) {
        $scope.loading = true;
        AuthSrvc.getHpToken(code, function (err, response) {
            if (err) {
                console.log(err)
            } else {
                if(response.token){
                    $scope.hpToken = response.token;
                    $scope.login();
                }
            }
            $scope.loading = false;
        });
    };

    $rootScope.$on('event:social-sign-in-success', function (event, userDetails) {

        // First, validate the email:
        $scope.email = userDetails.email;
        AuthSrvc.checkMailAndUserType(
            $scope.email,
            function (userType) {
                if (userType.valid) {
                    $scope.failed = false;
                    stateParams.email = $scope.email;
                    if (userType.userType === 'COMPANY_USER') {
                        // Perform a social auth
                        $scope.socialIdToken = userDetails.idToken;
                        $scope.login();
                    } else if (userType.userType === 'EMPLOYEE') {
                        // Send email recovery automatically
                        $scope.sendEmployeeCode();
                    }

                    Analytics.trackEvent('login', 'google', 'success');

                } else {

                    $scope.executing = false;
                    $scope.completed = true;
                    $scope.failed = true;
                    $scope.failedReason = $translate.instant('LOGIN_MAIL_NOT_FOUND');
                    Analytics.trackEvent('login', 'google', 'failure');
                    googleSignOut();
                }

            },

            function (err) {

                $scope.executing = false;
                $scope.completed = true;
                $scope.failed = true;
                $scope.showSwalFromError(err);
                Analytics.trackEvent('login', 'google', 'failure');
                googleSignOut();

            }
        );
    });

    $scope.showSwalFromError = function (err) {
        if (err.errorCode) {
            swal($translate.instant(err.errorCode), '', 'error');
        } else {
            swal($translate.instant('CONNECTION_ERROR'), '', 'error');
        }
    };

    this.$onInit = function () {
        if($location.search().code){
            $scope.loginWithHP($location.search().code);
        }
    };
}

function OnboardingView($stateProvider) {
    'ngInject';
    $stateProvider
        .state('onboarding', {
            url: '/onboarding?:showConversationFor&showModerateFor',
            controller: OnboardingCtrl,
            controllerAs: 'oc',
            template: template,
            data: { pageTitle: 'WELCOME', bodyClasses: 'login gray-bg' },
            params: {
                redirect: '',
                extras: {}
            }
        })
        .state('onboarding/method', {
            url: '/onboarding/method?:showConversationFor&showModerateFor',
            template: template,
            controllerAs: 'oc',
            controller: OnboardingCtrl,
            data: { pageTitle: 'SELECT_LOGIN_METHOD', bodyClasses: 'login gray-bg' },
            params: {
                redirect: '',
                extras: {},
                email: null
            }
        })
        .state('onboarding/password', {
            url: '/onboarding/password?:showConversationFor&showModerateFor',
            template: template,
            controllerAs: 'oc',
            controller: OnboardingCtrl,
            data: { pageTitle: 'LOGIN_PASSWORD', bodyClasses: 'login gray-bg' },
            params: {
                redirect: '',
                extras: {},
                email: null
            }
        })
        .state('onboarding/sent', {
            url: '/onboarding/sent',
            template: template,
            controllerAs: 'oc',
            controller: OnboardingCtrl,
            data: { pageTitle: 'LOGIN_SENT', bodyClasses: 'login gray-bg' },
            params: {
                redirect: '',
                extras: {},
                email: null
            }
        })
        .state('onboarding/employeesent', {
            url: '/onboarding/employeesent',
            template: template,
            controllerAs: 'oc',
            controller: OnboardingCtrl,
            data: { pageTitle: 'EMPLOYEE_CODE_SENT', bodyClasses: 'login gray-bg' },
            params: {
                redirect: '',
                extras: {},
                email: null
            }
        });
}

export default OnboardingView;
