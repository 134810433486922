import React from 'react';
import { DialogTitle, Stack, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface CloseableDialogTitleProps {
    title: string | React.ReactNode;
    subtitle?: string | React.ReactNode;
    onClose: () => void;
}

const CloseableDialogTitle: React.FC<CloseableDialogTitleProps> = ({ title, subtitle, onClose }) => {


    const renderSubtitle = () => {
        if (subtitle) {
            if (typeof subtitle === 'string') {
                return (
                    <Typography variant="body3" color="text.secondary">
                        {subtitle}
                    </Typography>
                );
            } else {
                return subtitle;
            }
        }
    }

    return (
        <DialogTitle
            sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                borderBottom: `1px solid ${theme.palette.divider}`,
                marginBottom: theme.spacing(1),
            })}
        >
            <Stack  sx={{
                width: '100%',
            }}>
                <Stack
                    direction="row"
                    gap={1}
                    sx={{
                        width: '100%',
                        alignItems: 'baseline',
                        justifyContent: 'space-between',
                    }}
                >

                    {typeof title === 'string' ? (
                        <Typography variant="h4" component="div">
                            {title}
                        </Typography>
                    ) : (
                        title
                    )}



                    <IconButton onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Stack>
                {renderSubtitle()}
            </Stack>




        </DialogTitle>
    );
};

export default CloseableDialogTitle;
