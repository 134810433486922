'use strict';

function AnnouncementCommentsCtrl(announcement, Announcements, $modalInstance, ErrorSvrc, Comments) {
    'ngInject'
    var _this = this;

    _this.announcement = announcement;
    _this.comments = [];
    _this.commentsPerPage = 10;

    _this.currentPage = 1;


    _this.loadFeedback = function (currentPage) {
        _this.comments = [];
        _this.loading = true;

        const from = (currentPage - 1) * _this.commentsPerPage;
        const to = from + _this.commentsPerPage;
        var params = {
            from: from,
            to: to
        };


        Announcements.getFeedback(_this.announcement.id, params, function (err, result) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.total = result.total;
                _this.comments = result.results;
            }
            _this.loading = false;
        });
    };

    _this.onCommentUpdated = function (err, updatedCommentId) {

        // Load and update the changedComment
        Comments.getOne(updatedCommentId, function (err, updatedComment) {
            const index = _this.comments.findIndex(comment => comment.id === updatedCommentId);
            _this.comments[index] = updatedComment;
        });

    };

    _this.close = function () {
        $modalInstance.dismiss();
    };

    _this.loadFeedback(_this.currentPage);
};

// We expose here also the service for show the modal:

const AnnouncementCommentsModal = angular.module('happyForceApp')
    .factory('AnnouncementCommentsModal',
        function ($uibModal, $intercom) {
            var methods = {};
            methods.showComments = function (announcement) {
                $intercom.update({ hide_default_launcher: true });
                $uibModal.open({
                    backdrop: false,
                    animation: true,
                    backdropClass: 'right',
                    template: require('./announcement-feedback.html'),
                    controller: AnnouncementCommentsCtrl,
                    controllerAs: '$ctrl',
                    size: 'right survey',
                    resolve: {
                        announcement: announcement
                    }
                }).result.then(function () {
                    $intercom.update({ hide_default_launcher: false });
                });
            };

            return methods;

        }
    );


export default AnnouncementCommentsModal;
