import ManageUsersView from 'root/app/components/users/ManageUsersView';
import {angularize} from 'reactInAngular';
import angular from 'angular';




// Import the component from the React -> and wrap it with the angularize function
angularize(ManageUsersView, 'hfManageUsers', angular.module('happyForceApp'));

const ManageUsersViewAngular = function ManageUsersRoute($stateProvider) {
    'ngInject';
    $stateProvider.state('manage.users', {
        url: '/users/manage',
        template: '<hf-manage-users></hf-manage-users>',
        data: { pageTitle: 'MANAGE_USERS', roles: ['ADMIN', 'ADVISOR'] }
    });
}
export default ManageUsersViewAngular;
