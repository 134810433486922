import React from 'react';

import { Stack } from '@mui/material';
import CommentMain from '../components/comment-main/CommentMain';
import CommentReactionsBar from '../components/comment-reactions-bar/CommentReactionsBar';
import CommentActionsBar from '../components/comment-actions-bar/CommentsActionBar';
import {CommentReply} from '../types';


interface CommentReplyProps {
    reply: CommentReply;
    replyOf: string;
    showModerateLink?: boolean;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;

}

const CommentReplyType: React.FC<CommentReplyProps> = ({
    reply,
    replyOf,
    showModerateLink,
    onCommentUpdated,
}) => {



    return (
        <CommentMain
            comment={reply}
            replyOf={replyOf}
            hideType={true}
            onCommentUpdated={onCommentUpdated}

            reactionsZone={
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <CommentReactionsBar
                        comment={reply}
                        showRepliesAction={false}
                        onCommentUpdated={onCommentUpdated}
                    />
                    <CommentActionsBar
                        comment={reply}
                        replyOf={replyOf}
                        showInappropriate={showModerateLink}
                        showConversation={false}
                        showReplyWithAnnouncement={false}
                        selfHandleInappropriate={true}
                        showSwapPrivacy={false}
                        onCommentUpdated={onCommentUpdated}
                    />
                </Stack>
            }
        />
    );
};


export default CommentReplyType;
