import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import {Box, Grid} from '@mui/material';
import HappyLoading from '../../../../../../shared/components/_migrated/loader/loading.directive';


interface EngagementCellWrapperProps {
    color: string;
    loading: boolean;
    children: React.ReactNode;
}

const EngagementCellWrapper = ({color, loading, children}: EngagementCellWrapperProps) => {


    return (

        <Box height={'100%'}>
            <Box display={'flex'}
                padding={1}
                bgcolor={color}
                height={'100%'}>
                {!loading && (
                    <> {children}
                    </>

                )}

                {loading && (
                    <Box sx={{width: '100%'}}>
                        <HappyLoading loading={loading} skeleton={2} noMessage={true}/>
                    </Box>
                )}

            </Box>
        </Box>

    )
}

export default EngagementCellWrapper;

