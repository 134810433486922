'use strict';


import template from './conversation-source-preview.html';
import Dates from "../../../../../utilities/date.utilities";


function ConversationSourcePreview($scope, Comments, Conversation, Settings) {
    'ngInject';

    var _this = this;

    function getConversation(conversationId) {
        if (conversationId) {
            _this.loading = true;
            Conversation.get(conversationId, function (err, conversation) {
                _this.conversation = conversation;

                if (_this.conversation.type === 'COMMENT' && _this.conversation.commentId) {
                    // Retrieve the employee from the comment:
                    Comments.getOne(_this.conversation.commentId, function (err, comment) {
                        _this.comment = comment;
                    });
                } else {
                    _this.employeeToken = conversation.encryptedId;
                    _this.to = Dates.lastDayOfMonth(Dates.fromAPIFormat(conversation.createdOn, Settings.apiDateFormat));
                }
            });

        } else {
            _this.canMessage = true;
        }

        return [];
    };

    _this.onCommentUpdated = function (err, updatedCommentId) {
        Comments.getOne(updatedCommentId, function (err, updatedComment) {
            _this.comment = updatedComment;
        });
    };


    this.$onInit = function () {
        ['conversationId'].forEach(function (key) {
            // Listeners
            $scope.$watch(angular.bind(_this, function () {
                return _this[key];
            }), function (n, o) {
                if (n == o) {
                    return;
                }
                getConversation(_this.conversationId);
            }, true);
        });
        getConversation(_this.conversationId);
    };
}


const hfConversationSourcePreview = angular.module('happyForceApp').directive('hfConversationSourcePreview', function () {
    return {
        scope: {
            conversationId: '<'
        },
        restrict: 'E',
        template: template,
        controller: ConversationSourcePreview,
        controllerAs: '$ctrl',
        bindToController: true
    };
});

export default hfConversationSourcePreview;
