import React from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';
import { getService } from 'reactInAngular';
import {CommentBase, RepliesTypeComment} from '../../../types';
import CommentRepliesDrawer from '../../comment-replies-modal/CommentRepliesDrawer';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';

interface CommentShowRepliesActionProps {
    comment: CommentBase & RepliesTypeComment;
    onCommentUpdated?: (err: any, updatedCommentId: string) => void;
}

const CommentShowRepliesAction: React.FC<CommentShowRepliesActionProps> = ({ comment, onCommentUpdated }) => {
    const $translate = getService('$translate');

    const [showRepliesModal, setShowRepliesModal] = React.useState(false);

    const showReplies = () => {
        setShowRepliesModal(true); // Abrimos la modal
    };

    const handleCommentUpdated = (err: any, updatedCommentId: string) => {
        if (onCommentUpdated) {
            onCommentUpdated(err, updatedCommentId);
        }

    };

    const renderContent = () =>
        (
            <Stack direction="row" display="flex" alignItems="center">
                <ForumOutlinedIcon/>
                <Typography variant="caption" marginLeft={0.5}>
                    {comment.replies ? comment.replies.length : 0}
                </Typography>
            </Stack>
        );

    return (
        <>
            <Stack direction="row" display="flex" alignItems="center">
                {!comment.privated ? (
                    <a onClick={showReplies} style={{ display: 'flex' }}>
                        {renderContent()}
                    </a>
                ) : (
                    <Tooltip title={$translate.instant('COMMENT_IS_PRIVATED_REPLY_IN_THREAD_ACTION_HINT')} placement="top">
                        {renderContent()}
                    </Tooltip>
                )}
            </Stack>
            <CommentRepliesDrawer
                showDrawer={showRepliesModal}
                comment={comment}
                onClose={() => setShowRepliesModal(false)}
                onCommentUpdated={handleCommentUpdated}
            />
        </>
    );
};

export default CommentShowRepliesAction;
