import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Grid,
    Typography,
    Stack,
} from '@mui/material';
import { getService } from 'reactInAngular';
import Numbers from '../../../../utilities/number.utilities';
import HFInfoIconWithTooltip from '../../../../shared/new-components/hf-info-icon-with-tooltip';
import {WhiteBgPaper} from '../../../../shared/new-components/hf-styled-components/WhiteBgPaddedPapper';
import Style from '../../../../utilities/style.utils';

interface ManageUsersStatsProps {
    setShowingInvite: () => void;
}


const ManageUsersStats: React.FC<ManageUsersStatsProps> = ({setShowingInvite}) => {
    const [loading, setLoading] = useState(false);
    const [invitedCount, setInvitedCount] = useState(0);
    const [activatedCount, setActivatedCount] = useState(0);
    const [activatedPercent, setActivatedPercent] = useState(0);
    const [activatedPercentValuation, setActivatedPercentValuation] = useState('');
    const [activatedPercentColor, setActivatedPercentColor] = useState('');
    const [sleepingCount, setSleepingCount] = useState(0);
    const [sleepingPercent, setSleepingPercent] = useState(0);
    const [sleepingPercentColor, setSleepingPercentColor] = useState('');
    const [sleepingPercentValuation, setSleepingPercentValuation] = useState('');


    const $translate = getService('$translate');
    const ErrorSvrc = getService('ErrorSvrc');
    const People = getService('People');
    const UserSendReminderModal = getService('UserSendReminderModal');

    const color = (value: number) => {
        if (value === 0) {return Style.noData;}
        if (value <= 25) {return Style.bad;}
        if (value <= 50) {return Style.warning;}
        if (value <= 75) {return Style.excellent;}
        return Style.excellent;
    };


    const  valuation = value => {
        if (value === 0) {
            return $translate.instant('EMPLOYEE_VALUATION_NO_DATA');
        } else if (value <= 25) {
            return $translate.instant('EMPLOYEE_VALUATION_BAD');
        } else if (value <= 50) {
            return $translate.instant('EMPLOYEE_VALUATION_WARNING');
        } else if (value <= 75) {
            return $translate.instant('EMPLOYEE_VALUATION_GOOD');
        } else {
            return $translate.instant('EMPLOYEE_VALUATION_EXCELENT');
        }
    };

    const loadUserStats = () => {
        setLoading(true);
        People.stats.get((err: any, results: any) => {
            setLoading(false);
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                setInvitedCount(results.invited);
                setActivatedCount(results.activated);
                const activatedPercentValue = Numbers.roundNumber(Numbers.calculatePercent(results.activated, results.invited), 1);
                setActivatedPercent(activatedPercentValue);
                setActivatedPercentColor(color(activatedPercentValue));
                setActivatedPercentValuation(valuation(activatedPercentValue));
                setSleepingCount(results.sleeping);
                const sleepingPercentValue = Numbers.roundNumber(Numbers.calculatePercent(results.sleeping, results.activated), 1);
                setSleepingPercent(sleepingPercentValue);
                setSleepingPercentColor(color(100 - sleepingPercentValue));
                setSleepingPercentValuation(valuation(sleepingPercentValue));
            }
        });
    };

    const handleSendReminder = (type: string) => {
        UserSendReminderModal.showSendReminderModal(type, null, null, null, (err: any) => {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            }
        });
    };



    useEffect(() => {
        loadUserStats();
    }, []);

    function renderInvitedUsersBlock() {
        return <Stack direction={'row'}
            spacing={4}
            height={'100%'}
            alignItems={'flex-start'}>
            <Typography variant="bigNumber">{invitedCount}</Typography>

            <Stack direction={'column'}
                spacing={2}
                height={'100%'}
                justifyContent={'space-between'}>

                <Stack direction={'column'} spacing={0.5}>
                    <Typography variant="h2">
                        {$translate.instant('EMPLOYEES_INVITED_COUNT')}
                    </Typography>
                    <Typography variant="body3" color="text.secondary">
                        {$translate.instant('INVITE_MORE_EMPLOYEES_SUGGEST_BULK')}
                    </Typography>
                </Stack>

                <Button variant="contained" color="primary" fullWidth={true} onClick={() => setShowingInvite()}>
                    🎉 {$translate.instant('INVITE_MORE_EMPLOYEES')}
                </Button>
            </Stack>
        </Stack>;
    }

    function renderActivatedUsersBlock() {
        return <Stack direction={'row'}
            height={'100%'}
            spacing={4}
            alignItems={'flex-start'}>


            <Stack direction={'column'} spacing={0} alignItems={'center'}>
                <Typography variant="bigNumber"
                    style={{color: activatedPercentColor}}>{activatedCount}</Typography>
                <Typography variant="h2"
                    style={{color: activatedPercentColor}}>{activatedPercentValuation}</Typography>
            </Stack>


            <Stack direction={'column'}
                spacing={2}
                height={'100%'}
                justifyContent={'space-between'}>

                <Stack direction={'column'} spacing={0.5}>
                    <Typography variant="h2">
                        {$translate.instant('EMPLOYEES_ACTIVATED')}

                        <Box component="span" sx={{display: 'flex', alignItems: 'center'}}>
                            {activatedPercent}%
                            <HFInfoIconWithTooltip
                                tooltipContent={$translate.instant('EMPLOYEES_ACTIVATED_DESCRIPTION')}
                                icon={'INFO'}
                                color={'primary'}
                            />
                        </Box>

                    </Typography>
                    <Typography variant="body3" color="text.secondary">
                        {$translate.instant('EMPLOYEES_ENGAGE_NON_ACTIVATED_DESCRIPTION')}
                    </Typography>


                </Stack>

                <Button variant="contained" color="primary" fullWidth={true}
                    onClick={() => handleSendReminder('NON_ACTIVATED')}>
                    🖖 {$translate.instant('EMPLOYEES_ENGAGE_NON_ACTIVATED')}
                </Button>


            </Stack>
        </Stack>;
    }

    function renderSleeplingUsersBlock() {
        return <Stack direction={'row'}
            height={'100%'}
            spacing={4}
            alignItems={'flex-start'}>

            <Stack direction={'column'} spacing={0} alignItems={'center'}>
                <Typography variant="bigNumber"
                    style={{color: sleepingPercentColor}}>{sleepingCount}</Typography>
                <Typography variant="h2"
                    style={{color: sleepingPercentColor}}>{sleepingPercentValuation}</Typography>
            </Stack>

            <Stack direction={'column'} spacing={2} height={'100%'} justifyContent={'space-between'}>

                <Stack direction={'column'} spacing={0.5}>
                    <Typography variant="h2">
                        {$translate.instant('EMPLOYEES_SLEEPLING_AWAY')}

                        <Box component="span" sx={{display: 'flex', alignItems: 'center'}}>
                            {sleepingPercent}%
                            <HFInfoIconWithTooltip
                                tooltipContent={$translate.instant('EMPLOYEES_SLEEPLING_AWAY_DESCRIPTION')}
                                icon={'INFO'}
                                color={'primary'}
                            />
                        </Box>

                    </Typography>
                    <Typography variant="body3" color="text.secondary">
                        {$translate.instant('EMPLOYEES_ENGAGE_SLEEPING_DESCRIPTION')}
                    </Typography>


                </Stack>

                <Button fullWidth={true}
                    variant="contained"
                    color="primary"
                    onClick={() => handleSendReminder('SLEEPING')}>
                    ⏰ {$translate.instant('EMPLOYEES_ENGAGE_SLEEPING')}
                </Button>


            </Stack>
        </Stack>;
    }

    return (
        <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={12} md={4}>
                <WhiteBgPaper sx={{height:'100%'}}>
                    {renderInvitedUsersBlock()}
                </WhiteBgPaper>
            </Grid>
            <Grid item xs={12} md={4}>
                <WhiteBgPaper sx={{height:'100%'}}>
                    {renderActivatedUsersBlock()}
                </WhiteBgPaper>
            </Grid>
            <Grid item xs={12} md={4}>
                <WhiteBgPaper sx={{height:'100%'}}>
                    {renderSleeplingUsersBlock()}
                </WhiteBgPaper>
            </Grid>
        </Grid>
    );
};

export default ManageUsersStats;
