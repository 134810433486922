import {Paper, styled} from '@mui/material';

const WhiteBgPaper = styled(Paper)(({ theme }) => ({
    border: '1px solid #e2e7e9',
    backgroundColor: '#fff',
    boxShadow: 'none',
    padding: theme.spacing(3),

}));

export { WhiteBgPaper}
