

function ConversationRow($scope, Comments) {
    'ngInject';
    var _this = this;


    _this.onCommentUpdated = function (err, updatedCommentId) {
        Comments.getOne(updatedCommentId, function (err, updatedComment) {
            _this.conversation.comment = updatedComment;
        });
    };

}

const hfConversationRow = angular.module('happyForceApp').directive('hfConversationRow', function () {
    return {
        scope: {
            conversation: '='
        },
        restrict: 'E',
        template: require('./conversation-row.html'),
        controller: ConversationRow,
        controllerAs: '$ctrl',
        bindToController: true
    };
}).controller('ConversationRow', ConversationRow);

export default hfConversationRow;
