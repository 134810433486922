import React, { useState, useEffect } from 'react';
import { getService } from 'reactInAngular';

import {Box, Stack, Typography} from '@mui/material';
import CommentMain from '../../components/comment-main/CommentMain';
import CommentReactionsBar from '../../components/comment-reactions-bar/CommentReactionsBar';
import CommentActionsBar from '../../components/comment-actions-bar/CommentsActionBar';
import {SomethingIsWrongComment} from '../../types';
import CommentTextBox from '../../../hf-styled-components/CommentTextBox';


interface SomethingIsWrongFeedbackTypeProps {
    comment: SomethingIsWrongComment;
    showConversationLink?: boolean;
    showReplyWithAnnouncementLink?: boolean;
    showModerateLink: boolean;
    showSwapPrivacy: boolean;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;

}

const SomethingIsWrongFeedbackType: React.FC<SomethingIsWrongFeedbackTypeProps> = ({
    comment,
    showConversationLink = true,
    showReplyWithAnnouncementLink,
    showModerateLink,
    showSwapPrivacy,
    onCommentUpdated,
}) => {
    const [voteImage, setVoteImage] = useState<string | null>(null);

    const $translate = getService('$translate');


    useEffect(() => {

        if (comment && comment.votes) {
            setVoteImage(comment.votes[0].imageUrl);
        }

    }, [comment, showConversationLink, $translate]);

    return (
        <CommentMain
            comment={comment}
            hideType={false}
            feedbackTypeText={$translate.instant('FEEDBACK_TYPE_RESULT_CRITICISM')}
            onCommentUpdated={onCommentUpdated}
            postCommentZone={
                <>
                    <Typography variant={'caption'}>
                        {$translate.instant('COMMENT_FEEDBACK_TYPE_CRITICISM_MAKES_ME_FEEL')}
                    </Typography>
                    <CommentTextBox text={comment.makesMeFeel}/>

                    <Typography variant={'caption'}>
                        {$translate.instant('COMMENT_FEEDBACK_TYPE_CRITICISM_HOW_TO_SOLVE')}
                    </Typography>
                    <CommentTextBox text={comment.howToSolve}/>
                </>

            }
            postHeaderZone={
                <Box display="flex" alignItems="center">
                    {$translate.instant('STANDARD_FEEDBACK_VOTE_LABEL')}
                    {voteImage && <img className="m-l-xs img-circle img-xs" src={voteImage} alt="Vote" />}
                </Box>
            }
            reactionsZone={
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <CommentReactionsBar
                        comment={comment}
                        showRepliesAction={true}
                        onCommentUpdated={onCommentUpdated}
                    />
                    <CommentActionsBar
                        comment={comment}
                        showInappropriate={showModerateLink}
                        showConversation={showConversationLink}
                        showReplyWithAnnouncement={showReplyWithAnnouncementLink}
                        showSwapPrivacy={showSwapPrivacy}
                        onCommentUpdated={onCommentUpdated}
                    />
                </Stack>
            }
        />
    );
};


export default SomethingIsWrongFeedbackType;
