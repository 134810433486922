import React from 'react';
import {Stack} from '@mui/material';
import {CommentBase, InappropriateTypeComment, StandardComment} from '../../types';
import CommentConversationAction from '../comment-actions/comment-conversation-action/CommentConversationAction';
import CommentReplyWithAnnouncementAction
    from '../comment-actions/comment-reply-with-announcement-action/CommentReplyWithAnnouncementAction';
import CommentInappropriateAction from '../comment-actions/comment-inappropriate-action/CommentInappropriateAction';
import CommentChangeVisibilityAction
    from '../comment-actions/comment-change-visibility-action/CommentChangeVisibilityAction';

interface CommentActionsBarProps {
    comment: CommentBase;
    replyOf?: string | null;
    showInappropriate?: boolean;
    showConversation?: boolean;
    showReplyWithAnnouncement?: boolean;
    showSwapPrivacy?: boolean;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;
}

const CommentActionsBar: React.FC<CommentActionsBarProps> = ({
    comment,
    replyOf,
    showInappropriate = false,
    showConversation = false,
    showReplyWithAnnouncement = false,
    showSwapPrivacy = false,
    onCommentUpdated,
}) => {



    return (
        <Stack display="flex" alignItems="center" gap={1} direction="row">
            {showConversation && (
                <CommentConversationAction
                    comment={comment}
                    onCommentUpdated={onCommentUpdated}
                />
            )}
            {showReplyWithAnnouncement && (
                <CommentReplyWithAnnouncementAction
                    comment={comment}
                    onCommentUpdated={onCommentUpdated}
                />
            )}
            {showInappropriate && (
                <CommentInappropriateAction
                    replyOf={replyOf}
                    comment={comment}
                    onCommentUpdated={onCommentUpdated}
                    showActionLabel={false}
                />
            )}
            {showSwapPrivacy && (
                <CommentChangeVisibilityAction
                    comment={comment}
                    onCommentUpdated={onCommentUpdated}
                />
            )}
        </Stack>
    );
};

export default CommentActionsBar;
