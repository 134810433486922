import React, { useEffect, useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { getService } from 'reactInAngular';

import moment from 'moment/moment';
import {CommentBase, InappropriateTypeComment} from '../../types';
import {HfBannerReact} from '../../../hf-banner/hfBanner';
import CommentInappropriateReportsModal
    from '../comment-inappropriate-modal/components/CommentInappropriateReportsModal';
import CommentInappropriateModal from '../comment-inappropriate-modal/CommentInappropriateModal';



interface CommentInappropriateBoxProps {
    comment: InappropriateTypeComment;
    replyOf?: string | null;
    onCommentUpdated?: (err: any, updatedCommentId: string) => void;
    inlineStyle?: boolean;
}

const CommentInappropriateBox: React.FC<CommentInappropriateBoxProps> = ({
    comment,
    replyOf,
    onCommentUpdated,
    inlineStyle = false,
}) => {
    const [reasonText, setReasonText] = useState('');
    const [refereedAuthor, setRefereedAuthor] = useState('');
    const [showInappropriateModal, setShowInappropriateModal] = useState(false);
    const $translate = getService('$translate');
    const People = getService('People');

    const parseInappropriateFields = () => {
        let text = '';
        if (comment.refereed) {
            switch (comment.inappropriateReasonType) {
                case 'DISRESPECTFUL':
                    text = $translate.instant('LACK_OF_RESPECT');
                    break;
                case 'OFFENSIVE':
                    text = $translate.instant('OFFENSIVE_LANGUAGE');
                    break;
                case 'OTHER':
                    text = $translate.instant('INAPPROPRIATE_OTHER');
                    break;
                case 'THRESHOLD':
                    text = $translate.instant('INAPPROPRIATE_EMPLOYEES');
                    break;
                default:
                    text = comment.inappropriateReason || '';
                    break;
            }
        } else {
            text = $translate.instant('INAPPROPRIATE_RESPECT');
        }
        setReasonText(text);
    };


    useEffect(() => {
        parseInappropriateFields();

        if (comment?.refereed?.userId) {
            People.getProfile(comment.refereed.userId, (err: any, person: { name: string }) => {
                if (err) {
                    setRefereedAuthor($translate.instant('UNKNOWN_USER_NAME'));
                } else {
                    setRefereedAuthor(person.name);
                }
            });
        }
    }, [comment]);


    if (inlineStyle) {
        return (
            <>
                <Box sx={{ textAlign: 'center' }}>
                    <div className="small-style m-b-xs">
                        {comment.refereed
                            ? $translate.instant('COMMENT_AUTHOR_BLOCKED_DESCRIPTION', {
                                date: moment.utc(comment.refereed.date).format('DD MMM YYYY'),
                                blocker: refereedAuthor,
                                reason: reasonText,
                            })
                            : reasonText}
                    </div>
                    <a className="m-t-sm" onClick={() => setShowInappropriateModal(true)}>
                        {$translate.instant('SHOW_COMMENT')}
                    </a>
                </Box>

                <CommentInappropriateModal
                    show={showInappropriateModal}
                    comment={comment}
                    onClose={() => setShowInappropriateModal(false)}
                    onCommentUpdated={onCommentUpdated}/>
            </>

        );
    } else {
        return (
            <>
                <HfBannerReact type={comment.refereed ? 'primary' : 'warning'}>
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <Typography variant="body3">
                            {comment.refereed
                                ? $translate.instant('COMMENT_AUTHOR_BLOCKED_DESCRIPTION', {
                                    date: moment.utc(comment.refereed.date).format('DD MMM YYYY'),
                                    blocker: refereedAuthor,
                                    reason: reasonText,
                                })
                                : reasonText}
                        </Typography>
                        <Button onClick={() => setShowInappropriateModal(true)} size="small">
                            {$translate.instant('SHOW_COMMENT')}
                        </Button>
                    </Box>
                </HfBannerReact>
                <CommentInappropriateModal
                    show={showInappropriateModal}
                    comment={comment}
                    onClose={() => setShowInappropriateModal(false)}
                    onCommentUpdated={onCommentUpdated}/>
            </>

        );
    }
};

export default CommentInappropriateBox;
