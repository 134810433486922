'use strict';

function hfSurveyCommentsControler(survey, question, roundId, filters, $modalInstance, Surveys, SegmentsFilterService, ErrorSvrc, Comments) {
    'ngInject';
    var _this = this;

    _this.survey = survey;
    _this.question = question;
    _this.filters = filters;
    _this.roundId = roundId;

    this.currentCommentPage = 1;
    _this.totalComments = 0;

    _this.loadFeedback = function () {
        _this.feedbackData = [];
        _this.loading = true;

        var params = {};

        params.questionId = _this.question.question.id;
        params.roundId = roundId;

        if (_this.filters.hierarchies && _this.filters.hierarchies.length) {
            params.hierarchyId = _this.filters.hierarchies;
        };

        if (_this.filters.segments && _this.filters.segments.length) {
            params.filters = SegmentsFilterService.compileFilterStringFromTagSegments(_this.filters.segments);
        }

        if (_this.filters.groups && _this.filters.groups.length) {
            params.groupId = _this.filters.groups;
        }

        if (_this.filters.dateFromEnabled && _this.filters.dateFrom) {
            params.from = _this.filters.dateFrom;
        }

        if (_this.filters.dateToEnabled && _this.filters.dateTo) {
            params.to = _this.filters.dateTo;
        }

        if (_this.filters.questionFilters)
            params.questionResults = _this.filters.questionFilters.join(',');

        params.fromComment = (_this.currentCommentPage - 1) * 10;
        params.toComment = 10;

        Surveys.feedback.get(_this.survey.id, params, function (err, comments) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.feedbackData = comments;
                _this.totalComments = comments.total;
            }
        });
    };

    _this.close = function () {
        $modalInstance.dismiss();
    };

    _this.onCommentUpdated = function (err, updatedCommentId) {
        Comments.getOne(updatedCommentId, function (err, updatedComment) {
            // Locate the index of the updated comment

            const index = _this.feedbackData.result.findIndex(comment => comment.id === updatedCommentId);

            // And update it on the list
            _this.feedbackData.result[index] = updatedComment;
        });
    };


    _this.loadFeedback();
};

const hfSurveyCommentsCtrl = angular.module('happyForceApp')
    .controller('hfSurveyCommentsCtrl', hfSurveyCommentsControler);

export default hfSurveyCommentsCtrl;
