import {Box, FormControl, MenuItem, Select, Stack, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import {angularize, getService} from 'reactInAngular';
import angular from 'angular';



interface SurveyDeliveryMethodProps {
    mailSurvey: boolean;
    appSurvey: boolean;
    onChange: (mailSurvey: boolean, appSurvey) => void;
}

const SurveyDeliveryMethod = ({ mailSurvey, appSurvey, onChange }) => {


    const $translate = getService('$translate');
    const [selectedOption, setSelectedOption] = React.useState<string | undefined>();


    const deliveryMethods = [
        {
            id: 'appSurvey',
            label: $translate.instant('SURVEY_ROUND_DELIVERY_VIA_APP'),
            description: $translate.instant('SURVEY_ROUND_DELIVERY_VIA_APP_DESCRIPTION')
        },
        {
            id: 'mailSurvey',
            label: $translate.instant('SURVEY_ROUND_DELIVERY_VIA_MAIL'),
            description: $translate.instant('SURVEY_ROUND_DELIVERY_VIA_MAIL_DESCRIPTION')
        },
        {
            id: 'both',
            label: $translate.instant('SURVEY_ROUND_DELIVERY_VIA_BOTH'),
            description: $translate.instant('SURVEY_ROUND_DELIVERY_VIA_BOTH_DESCRIPTION')
        }
    ];

    useEffect(() => {
        const option = mailSurvey && appSurvey ? 'both' : mailSurvey ? 'mailSurvey' : 'appSurvey';
        setSelectedOption(option);

    }, [mailSurvey, appSurvey]);





    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selected = event.target.value as string;
        setSelectedOption(selected);

        switch (selected) {
            case 'appSurvey':
                onChange(true, false);
                break;
            case 'mailSurvey':
                onChange(false, true);
                break;
            case 'both':
                onChange(true, true);
                break;
        }

    };

    if (!selectedOption) {
        return null;
    }

    return (
        <FormControl fullWidth>
            <Select
                labelId="selector-con-detalle-label"
                value={selectedOption}
                onChange={handleChange}
                MenuProps={{
                    MenuListProps: {
                        style: {
                            maxWidth: '400px', // Ensures dropdown width matches Select
                            wordWrap: 'break-word', // Allows text wrapping
                        },
                    },
                }}
                renderValue={(value) => {
                    const option = deliveryMethods.find(option => option.id === value);
                    return (
                        <Stack spacing={0.5} >
                            <Typography variant="body3" fontWeight="bold">
                                {option.label}
                            </Typography>
                        </Stack>
                    );
                }}
                sx={{
                    '& .MuiSelect-select': {
                        whiteSpace: 'normal', // Ensure the selected value also wraps
                    },
                }}
            >
                {deliveryMethods.map((option) => (
                    <MenuItem key={option.id}
                        value={option.id}
                        style={{ whiteSpace: 'normal' }} // Allows text to wrap
                    >
                        <Stack spacing={0.5} >
                            <Typography variant="body3" fontWeight="bold">
                                {option.label}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                                {option.description}
                            </Typography>
                        </Stack>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

angularize(SurveyDeliveryMethod, 'surveyDeliveryMethod', angular.module('happyForceApp'), {
    onChange: '<',
    mailSurvey: '<',
    appSurvey: '<'
});

export default SurveyDeliveryMethod;

