


import * as React  from 'react';
import {angularize} from 'reactInAngular';

import EngagementKeyFindingsList from './components/insights_list/EngagementKeyFindingsList';
import Grid from '@mui/material/Unstable_Grid2';
import {Box} from '@mui/material';
import EngagementHeatmap from './components/heatmap/EngagementHeatmap';
import {InsightInt} from './components/types';
import {IHeatmapColumn, ISegmentInfo} from '../../shared/new-components/hf-heatmap/heatmap-types';
import ScoreDrawerDetail from './components/details/ScoreDrawerDetail';
import { WhiteBgPaper } from '../../shared/new-components/hf-styled-components/WhiteBgPaddedPapper';
import {PaddedDrawer} from '../../shared/new-components/hf-styled-components/PaddedDrawer';
import angular from 'angular';
import {SegmentFilter} from './components/utils/filteringUtils';
import Chat from './components/chat/EngagementChat';

interface EngagementBetaProps {
    to: Date;
    dateGrouping: string;
}


const EngagementBeta = ({to, dateGrouping}: EngagementBetaProps) => {

    const [selectedInsight, setSelectedInsight] = React.useState<InsightInt | null>(null)

    const [showDrawer, setShowDrawer] = React.useState(false);


    const [selectedSegmentFilter, setSelectedSegmentFilter] = React.useState<SegmentFilter | null>(null);
    const [selectedColumn, setSelectedColumn] = React.useState<IHeatmapColumn | null>(null);


    const onCellClick = (segmentFilter: SegmentFilter, column: IHeatmapColumn, insight: InsightInt) => {
        console.log('onCellClick', segmentFilter, column, insight);
        setSelectedSegmentFilter(segmentFilter);
        setSelectedColumn(column);
        setShowDrawer(true);
    }


    const renderDrawerContent = () => {
        if (selectedColumn && selectedSegmentFilter) {
            if (selectedColumn.type === 'ENPS') {
                return (
                    <Box>
                        enps
                    </Box>
                )
            } else if (selectedColumn.type === 'HI') {
                return (
                    <Box>
                       hi
                    </Box>
                )
            } else if (selectedColumn.type === 'SCORE') {
                return (
                    <ScoreDrawerDetail
                        scoreId={selectedColumn.id}
                        selectedInsight={selectedInsight}
                        dateGrouping={dateGrouping}
                        segmentationFilter={selectedSegmentFilter}
                        to={to}
                    />
                )
            }
        }
    };

    const closeDrawer = (event: any) => {
        setShowDrawer(false);
        setSelectedSegmentFilter(null);
        setSelectedColumn(null);
    }



    return (
        <>
            <Grid container spacing={2}>
                <Grid xs={2}>
                    <EngagementKeyFindingsList
                        to={to}
                        dateGrouping={dateGrouping}
                        onInsightSelected={setSelectedInsight}
                    />
                </Grid>
                <Grid xs={8}>
                    <WhiteBgPaper>
                        <EngagementHeatmap
                            to={to}
                            dateGrouping={dateGrouping}
                            selectedInsight={selectedInsight}
                            onCellClick={onCellClick}
                        ></EngagementHeatmap>
                    </WhiteBgPaper>
                </Grid>
                <Grid xs={2}>
                    <WhiteBgPaper>TODO:
                        Here we will show the ongoing "interventions" that the user can take to improve the engagement.
                    <Chat/>
                    </WhiteBgPaper>
                </Grid>
            </Grid>
            <PaddedDrawer
                anchor={'right'}
                open={showDrawer}
                onClose={closeDrawer}
            >
                {renderDrawerContent()}
            </PaddedDrawer>
        </>

    )
}

angularize(EngagementBeta, 'hfEngagementBeta', angular.module('happyForceApp'), {
    to: '<',
    dateGrouping: '<'
});

export default EngagementBeta;
