import React, { useState, useEffect } from 'react';
import { getService } from 'reactInAngular';

import {Box, Stack, Typography} from '@mui/material';
import CommentMain from '../../components/comment-main/CommentMain';
import CommentActionsBar from '../../components/comment-actions-bar/CommentsActionBar';
import {ScoreComment} from '../../types';
import HFTag from '../../../hf-tag/hf-tag';
import Style from '../../../../../utilities/style.utils';
import HfMultilanguageText from '../../../hf-multilanguage-text/hfMultilanguageText';


interface ScoreFeedbackTypeProps {
    comment: ScoreComment;
    showConversationLink?: boolean;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;

}

const ScoreFeedbackType: React.FC<ScoreFeedbackTypeProps> = ({
    comment,
    showConversationLink = true,
    onCommentUpdated,
}) => {
    const [displayConversationLink, setDisplayConversationLink] = useState<boolean>(showConversationLink);
    const [scoreVote, setScoreVote] = useState<number | null>(null);
    const [scoreVoteColor, setScoreVoteColor] = useState<string | null>(null);

    const $translate = getService('$translate');


    const calculatePunctuationColor = (value, inverted) => {
        if (inverted) {
            if (value < 5) {
                return Style.excellent;
            } else if (value < 6) {
                return Style.warning;
            } else {
                return Style.bad;
            }
        } else {
            if (value > 6) {
                return Style.excellent;
            } else if (value > 5) {
                return Style.warning;
            } else {
                return Style.bad;
            }
        }
    };

    useEffect(() => {
        setDisplayConversationLink(showConversationLink);

        if (comment && comment.votes) {
            setScoreVote(comment.votes[0].score);
            setScoreVoteColor(calculatePunctuationColor(comment.votes[0].score, comment.invertedQuestion));
        }


    }, [comment, showConversationLink, $translate]);

    return (
        <CommentMain
            comment={comment}
            hideType={false}
            feedbackTypeText={$translate.instant('FEEDBACK_TYPE_SCORE')}
            onCommentUpdated={onCommentUpdated}
            postHeaderZone={
                <Stack direction={'column'} alignItems={'flex-start'} gap={1}>
                    <HFTag status={'primary'} text={
                        <>
                            <HfMultilanguageText multilanguage={comment.scoreName}/>
                            {' - '}
                            <HfMultilanguageText multilanguage={comment.factorName}/>
                        </>

                    }></HFTag>
                    <Typography variant={'body3'} fontWeight={'bold'}>
                        <HfMultilanguageText multilanguage={comment.questionTitle}/>
                    </Typography>
                    <Box>
                        <Typography variant={'caption'}>
                            {$translate.instant('PUNTUATION')}
                        </Typography>
                        <Typography variant={'caption'}  color={scoreVoteColor}>
                            {': '}{scoreVote} {' / 10'}
                        </Typography>
                    </Box>

                </Stack>
            }
            reactionsZone={
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Box/>
                    <CommentActionsBar
                        comment={comment}
                        showInappropriate={false}
                        showConversation={displayConversationLink}
                        showReplyWithAnnouncement={false}
                        showSwapPrivacy={false}
                        onCommentUpdated={onCommentUpdated}
                    />
                </Stack>
            }
        />
    );
};


export default ScoreFeedbackType;
