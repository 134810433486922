
/*
    A component to upload files, wrapping the Dropzone component from react-dropzone
 */


import React from 'react';
import HfDropFileArea from '../hf-drop-file-area/hfDropFileArea';
import {getService} from 'reactInAngular';
import {angularize} from '../../../../migration_utils/react-in-angular';
import angular from 'angular';

interface FileUploadProps {
    onFileUploaded: (file: File[]) => void;
}

const HfFileUpload: React.FC<FileUploadProps> = ({onFileUploaded} : FileUploadProps) => {


    const Attachments = getService('Attachments');
    const toaster = getService('toaster');
    const $translate = getService('toaster');

    const onFileUpload = (file: File): Promise<void> => {
        return new Promise((resolve, reject) => {
            Attachments.upload(file, (err, data) => {
                if (err) {
                    const messageError = $translate.instant('UPLOAD_FILE_ERROR_DESC', { name: file.name });

                    toaster.pop(
                        'error',
                        $translate.instant(err?.errorMessage || 'UPLOAD_FILE_ERROR'),
                        messageError
                    );

                    reject(err); // Reject the promise with the error
                } else {
                    if (onFileUploaded) {
                        onFileUploaded(data);
                    }
                    resolve(); // Resolve the promise when the upload is successful
                }
            });
        });
    };


    return (
        <HfDropFileArea onFileUpload={onFileUpload}/>
    );
}

angularize(HfFileUpload, 'hfFileUpload', angular.module('happyForceApp'), {
    onFileUploaded: '<'
});

export default HfFileUpload;
