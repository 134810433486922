import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getService } from 'reactInAngular';
import {
    IconButton,
    Tooltip,
    Box,
    Typography,
    Grid, TableCell, TableRow, Stack,
} from '@mui/material';
import {CommentBase, IdeaComment} from '../../../../../../shared/new-components/hf-comments/types';
import moment from 'moment';
import HfUserSmall from '../../../../../../shared/components/_migrated/user-small/hfUserSmall';
import CommentReactionsBar
    from '../../../../../../shared/new-components/hf-comments/components/comment-reactions-bar/CommentReactionsBar';
import CommentContainerSelector
    from '../../../../../../shared/new-components/hf-comments/components/comment-container-selector/CommentContainerSelector';
import CommentStatusSelector
    from '../../../../../../shared/new-components/hf-comments/components/comment-status-selector/CommentStatusSelector';
import CommentActionsBar
    from '../../../../../../shared/new-components/hf-comments/components/comment-actions-bar/CommentsActionBar';
import CommentTextBox from '../../../../../../shared/new-components/hf-styled-components/CommentTextBox';
import {HfBannerReact} from '../../../../../../shared/new-components/hf-banner/hfBanner';
import StyleUtils from '../../../../../../utilities/style.utils';
import HfCommentImage from '../../../../../../shared/new-components/hf-comments/components/comment-image/CommentImage';
import CommentInappropriateBox
    from '../../../../../../shared/new-components/hf-comments/components/comment-inappropiate-box/CommentInappropriateBox';


interface FeedbackIdeasRowItemProps {
    idea: IdeaComment;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;
}

const FeedbackIdeasRowItem: React.FC<FeedbackIdeasRowItemProps> = ({
    idea,
    onCommentUpdated,
}) => {
    const $translate = getService('$translate');


    const renderCommentContent = () => {

        if (idea.flaggedAnonymous) {
            return (
                <Tooltip title={$translate.instant('ANONYMOUS_COMMENT_DESCRIPTION')}>
                    <HfBannerReact backgroundColor={StyleUtils.chartBg}>
                        <Typography variant="body3">
                            {$translate.instant('ANONYMOUS_COMMENT')}
                        </Typography>

                    </HfBannerReact>
                </Tooltip>
            )
        } else if (idea.inappropriate || idea.refereed) {
            return (
                <CommentInappropriateBox
                    comment={idea}
                    self-handle={true}
                    inline-style={false}
                    inappropriate-callback={onCommentUpdated}
                />
            )

        } else {
            return ( <CommentTextBox text={idea.comment}  />)
        }
    }

    return (
        <TableRow key={idea.id}>
            <TableCell>
                <Typography variant="caption">
                    {moment(idea.date).format('DD MMM YYYY hh:mm')}
                </Typography>
            </TableCell>
            <TableCell sx={{maxWidth:'200px'}}>
                {renderCommentContent()}
            </TableCell>
            <TableCell>
                <HfUserSmall
                    hierarchyName={idea.hierarchyName}
                    hierarchyAnonymized={idea.hierarchyAnonymized}
                    userId={idea.signerId} />

            </TableCell>
            <TableCell>
                <CommentReactionsBar comment={idea}
                    showRepliesAction={true}
                    onCommentUpdated={onCommentUpdated}/>
            </TableCell>
            <TableCell>

                <CommentContainerSelector
                    comment={idea}
                    onCommentUpdated={onCommentUpdated}
                    noContainerText={$translate.instant('IDEA_HAS_NO_INITIATIVE')}
                    removeContainerText={$translate.instant('IDEA_REMOVE_INITIATIVE')}
                    noContainerOnPeriodText={$translate.instant('IDEA_NO_INITIATIVES_ON_PERIOD')}
                />

            </TableCell>
            <TableCell>
                <CommentStatusSelector comment={idea}
                    onCommentUpdated={onCommentUpdated}
                    onlyActive={true}/>
            </TableCell>
            <TableCell>
                <CommentActionsBar
                    comment={idea}
                    showInappropriate={true}
                    showConversation={true}
                    showSwapPrivacy={true}
                    onCommentUpdated={onCommentUpdated}
                    showReplyWithAnnouncement={true}/>
            </TableCell>
        </TableRow>
    );
};


export default FeedbackIdeasRowItem;
