import React, { useState, useEffect } from 'react';
import { angularize, getService } from 'reactInAngular';
import {Tooltip, Avatar, Typography, Stack} from '@mui/material';
import angular from 'angular';
import InfoIcon from '@mui/icons-material/Info';

interface UserSmallProps {
    userId?: string;
    hierarchyName?: string;
    hierarchyAnonymized?: boolean;
    preText?: string;
    postText?: string;
    subImage?: string;
}

// This should be extracter to an API
interface User {
    name: string;
    profileImagePath?: string;
}

const HfUserSmall: React.FC<UserSmallProps> = ({
    userId,
    hierarchyName,
    hierarchyAnonymized = false,
    preText,
    postText
}) => {
    const $translate = getService('$translate');
    const People = getService('People');
    const [shortName, setShortName] = useState<string>('?');
    const [user, setUser] = useState<User>({
        name: $translate.instant('UNKNOWN_USER_NAME'),
    });
    const [isAnonymous, setIsAnonymous] = useState<boolean>(!userId);

    useEffect(() => {
        if (userId) {
            People.getProfile(userId, (err: any, fetchedUser: User) => {
                if (err) {
                    setUser({
                        name: $translate.instant('UNKNOWN_USER_NAME'),
                    });
                    setShortName('?');
                } else {
                    setShortName(fetchedUser.name
                        ? fetchedUser.name.split(' ').map((n) => n[0]).join('')
                        : '?');
                    setUser({ ...fetchedUser });
                }
            });
        } else if (hierarchyName) {
            setUser({
                name: $translate.instant('HIERARCHY_SOMEONE_OF', { hierarchyName }),
                profileImagePath: 'images/annonymous_badge.png',
            });
        } else {
            setUser({
                name: $translate.instant('UNKNOWN_USER_NAME'),
            });
            setShortName('?');
        }
        setIsAnonymous(!userId);
    }, [userId, hierarchyName]);

    return (
        <Stack direction={'row'} alignItems={'center'} gap={1}>
            {preText && (
                <Typography variant={'body3'}>{preText}</Typography>
            )}

            <Avatar
                src={user.profileImagePath || ''}
                alt={shortName}
                sx={{ width: 24, height: 24 }}
            />

            <Typography variant={'body3'}>
                {user.name}
                {hierarchyAnonymized && isAnonymous && (
                    <Tooltip title={$translate.instant('COMMENT_HIERARCHY_NOT_ORIGINAL')} placement="top">
                        <InfoIcon fontSize={'small'} color="primary" />
                    </Tooltip>
                )}
            </Typography>

            {postText && (
                <Typography variant={'body3'}>{postText}</Typography>
            )}
        </Stack>
    );
};

/* Exporting the component with angularize */
angularize(
    HfUserSmall,
    'hfUserSmall',
    angular.module('happyForceApp'),
    {
        userId: '<',
        hierarchyName: '<',
        hierarchyAnonymized: '<',
        preText: '@',
        postText: '@',
        subImage: '@',
    }
);

export default HfUserSmall;
