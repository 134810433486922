'use strict';

function FeedbackIdeasInitiativeEditorCtrl($scope, Filters, HIService, Stats, $stateParams, Settings, ErrorSvrc, UnsplashService, FeedbackContainersService, AuthSrvc, $translate, $state, $window, $transitions) {
    'ngInject';

    var _this = this;

    _this.defaultImage = {
        image: 'https://assets.myhappyforce.com/defaultImages/initiative_default_image.png',
        selected: false,
        name: 'Happyforce'
    };


    _this.dateFormat = Settings.defaultDateFormat;

    // If the user is a manager, he needs to specify to which segments shoudl the initiative be sent
    _this.sendAll = !AuthSrvc.isMANAGER();

    _this.imageSearchName = '';
    _this.savingInitiative = false;
    _this.loading = true;


    const from = new Date();
    let to = new Date();
    to.setMonth(to.getMonth() + 1);

    _this.onSearchTextChange = function (newSearchText) {
        _this.imageSearchName = newSearchText
    }


    if ($stateParams.initiativeId) {
        _this.isUpdate = true;

        FeedbackContainersService.get($stateParams.initiativeId, function (err, result) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                _this.initiative = result;
                _this.originalImage = result.imageUrl;

                if (result.hierarchyIds || result.groupIds || result.characteristics || !AuthSrvc.isMANAGER()) {
                    _this.sendAll = false;
                }

            }
            _this.loading = false;
            setSaveDraft();
        });
    } else {
        _this.isUpdate = false;
        _this.initiative = {
            type: 'IDEA_INITIATIVE',
            title: {
                defaultValue: ''
            },
            description: {
                defaultValue: ''
            },
            imageUrl: _this.defaultImage.image,
            sendNotification: true,
            nonAnonymous: false,
            startDate: from,
            endDate: to,
            owner: AuthSrvc.user().id
        };
        _this.originalImage = 'https://assets.myhappyforce.com/defaultImages/initiative_default_image.png';
        _this.loading = false;
        setSaveDraft();
    }

    // This function will add a new function to the scope allowing to save the initative as a draft
    function setSaveDraft() {
        if (!_this.isUpdate || _this.initiative.draft || _this.initiative.status === 'SCHEDULED') {
            // If the initiative is a draft or is not yet launched, allow to change the status
            _this.saveDraft = function () {
                _this.initiative.draft = true;
                _this.save();
            };
        }
    }

    this.$onInit = function () {

        $transitions.onStart({ exiting: 'feedback.initiativeEditor' }, function (trans) {
            if (!_this.exitConfirmed && !_this.isSaveAction) {

                swal({
                    type: 'warning',
                    title: $translate.instant('INITIATIVE_CHANGES_WILL_BE_LOST'),
                    text: $translate.instant('INITIATIVE_CHANGES_WILL_BE_LOST_DESCRIPTION'),
                    allowEscapeKey: true,
                    buttons: {
                        cancel: { visible: true, text: $translate.instant('INITIATIVE_CHANGES_EXIT_CONFIRM'),
                            value: true, className: 'swal-button--delete' },
                        confirm: { text: $translate.instant('CONTINUE'), value: false }
                    },
                    confirmButtonColor: '#DD6B55',
                    closeOnConfirm: true
                }).then(function (result) {
                    if (result) {
                        _this.exitConfirmed = true;
                        $window.history.back();
                    };
                });
                return false;
            } else {
                delete _this.exitConfirmed;
                return true;
            };


        });
    };





    _this.arrow = {
        action: function () {
            $window.history.back();
        }
    };

    _this.validateDates = function () {

        if (_this.initiative.from && _this.initiative.to) {
            return _this.initiative.from < _this.initiative.to;
        } else {
            return true;
        }
    };

    _this.searchImages = function () {
        UnsplashService.searchImages(_this.imageSearchName, 15, function (err, results) {
            _this.foundImages = [];
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {

                _this.foundImages = results;
                _this.foundImages.unshift(_this.defaultImage);
            };
        });
    };

    _this.selectImage = function (selectImage) {

        if (selectImage.selected) {
            _this.initiative.imageUrl = _this.originalImage;
            selectImage.selected = false;
        } else {
            _this.foundImages.forEach(function (image) {
                image.selected = false;
            });
            selectImage.selected = true;
            _this.initiative.imageUrl = selectImage.image;
        }
    };

    _this.save = function () {
        _this.savingInitiative = true;
        _this.isSaveAction = true;

        function processSaveResult(err) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                var swalMessage;
                if (!_this.initiative.draft) {
                    if (_this.isUpdate) {
                        swalMessage = $translate.instant('INITIATIVE_EDITOR_INITIATIVE_UPDATED');
                    } else {
                        swalMessage = $translate.instant('INITIATIVE_EDITOR_INITIATIVE_CREATED');
                    }
                } else {
                    if (_this.isUpdate) {
                        swalMessage = $translate.instant('INITIATIVE_EDITOR_DRAFT_INITIATIVE_UPDATED');
                    } else {
                        swalMessage = $translate.instant('INITIATIVE_EDITOR_DRAFT_INITIATIVE_CREATED');
                    }
                }

                var swalParams = {
                    icon: 'success',
                    closeOnEsc: false,
                    closeModal: true,
                    text: swalMessage
                };

                swal(swalParams)
                    .then(function (value) {
                        $window.history.back();
                    });
            }
            _this.savingInitiative = false;
        }

        if (_this.isUpdate) {
            FeedbackContainersService.update(_this.initiative.id, _this.initiative, function (err, result) {
                processSaveResult(err);
            });
        } else {
            FeedbackContainersService.save(_this.initiative, function (err, result) {
                processSaveResult(err);
            });
        }

    };

    _this.launchInitiative = function () {
        _this.initiative.draft = false;
        _this.save();
    };




    $scope.$watch(angular.bind(_this, function () {
        return _this.imageSearchName;
    }), function (newVal, oldValue) {
        if (newVal === oldValue) return;
        _this.searchImages();
    });

}

const hfFeedbackIdeasInitiativeEditor = angular.module('happyForceApp')
    .directive('hfFeedbackIdeasInitiativeEditor', function () {
        return {
            template: require('./feedback-ideas-initiative-editor.html'),
            controller: FeedbackIdeasInitiativeEditorCtrl,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });

export default hfFeedbackIdeasInitiativeEditor;
