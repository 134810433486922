import React from 'react';
import { styled } from '@mui/system';

interface CircleDotProps {
    color?: string;
    size?: number;
}

const CircleDot = styled('span')<CircleDotProps>(({ color = 'gray', size=10 }) => ({
    display: 'inline-block',
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: '50%',
    backgroundColor: color,
}));

export default CircleDot;
