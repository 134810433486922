'use strict';

function AnnouncementReplyToCommentModalCtrl(Comments, announcement, comment, $modalInstance, ErrorSvrc) {
    'ngInject'
    var _this = this;
    _this.announcement = announcement;
    _this.comment = comment;

    _this.save = function (announcementId) {
        $modalInstance.close(announcementId);
    };

    _this.exit = function () {
        $modalInstance.dismiss();
    };

    _this.onCommentUpdated = function (err, updatedCommentId) {
        Comments.getOne(updatedCommentId, function (err, updatedComment) {
            _this.comment = updatedComment;
        });
    };
};


const AnnouncementReplyToCommentModal = angular.module('happyForceApp')
    .factory('AnnouncementReplyToCommentModal',
        function ($uibModal, Comments, Announcements, ErrorSvrc) {
            var methods = {};


            function showModalInternal(announcement, comment, callback) {
                setTimeout(function () {
                    $(window).trigger('resize');
                }, 500);

                $uibModal.open({
                    backdrop: false,
                    animation: true,
                    size: 'lg',
                    template: require('./announcement-reply-to-feedback-modal.html'),
                    controller: AnnouncementReplyToCommentModalCtrl,
                    controllerAs: '$ctrl',
                    resolve: {
                        announcement: announcement,
                        comment: comment
                    }
                }).result.then(function (announcementId) {
                    if (callback) {
                        callback(announcementId);
                        console.log('Modal closed with ok');
                    }
                }, function (params) {
                    // If there is a callback, reload allays the comment, so we have a fresh version of it
                    if (callback) {
                        console.log('Modal closed with dismiss');
                    }
                });
            }

            methods.showModal = function (commentId, callback) {

                // Retrieve the comment
                Comments.getOne(commentId, function (err, comment) {
                    if (err) {
                        ErrorSvrc.showErrorModal(err);
                    } else {
                        // If the comment has an announcementId, we retrieve the announcement, and we show the modal
                        if (comment.announcementId) {
                            Announcements.getOne(comment.announcementId, function (err, announcement) {
                                if (err) {
                                    ErrorSvrc.showErrorModal(err);
                                } else {
                                    showModalInternal(announcement, comment, callback);
                                }
                            });
                        } else {
                            // If the comment has no announcementId, we create a new announcement, and we show the modal
                            const announcement = {
                                title: {
                                    defaultValue: ''
                                },
                                message: {
                                    defaultValue: ''
                                },
                                resume: {
                                    defaultValue: ''
                                },
                                sectionId: null,
                                cover: '',
                                repliesToComments: [commentId],
                                commentsEnabled: true,
                                reactionsEnabled: true,
                                notify: true,
                                attachments: [],
                                coverImageURL: 'https://assets.myhappyforce.com/defaultImages/announcement_contents/announcements_cover_image1.png'
                            };
                            showModalInternal(announcement, comment, callback);
                        }
                    }
                });

            };

            return methods;
        }
    );

export default AnnouncementReplyToCommentModal;
