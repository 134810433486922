import React, { useState, useEffect } from 'react';
import { getService } from 'reactInAngular';

import {Box, Stack, Typography} from '@mui/material';
import CommentMain from '../../components/comment-main/CommentMain';
import CommentActionsBar from '../../components/comment-actions-bar/CommentsActionBar';
import {ENPSComment, ScoreComment, StandardComment} from '../../types';
import HFTag from '../../../hf-tag/hf-tag';


interface ENPSFeedbackTypeProps {
    comment: ENPSComment;
    showConversationLink?: boolean;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;

}

const ENPSFeedbackType: React.FC<ENPSFeedbackTypeProps> = ({
    comment,
    showConversationLink = true,
    onCommentUpdated,
}) => {
    const [enpsScore, setENPSScore] = useState<number | null>(null);
    const [enpsTagLabel, setENPSTagLabel] = useState<string | null>(null);
    const [enpsTagType, setENPSTagType] = useState<string | null>(null);

    const $translate = getService('$translate');




    useEffect(() => {

        if (comment && comment.votes) {


            const value = comment.votes[0].score;
            setENPSScore(value);


            if (value >= 9) {
                setENPSTagType('success');
                setENPSTagLabel('ENPS_PROMOTER');
            } else if (value >= 7) {
                setENPSTagType('warning');
                setENPSTagLabel('ENPS_PASSIVE');
            } else {
                setENPSTagType('danger');
                setENPSTagLabel('ENPS_DETRACTOR');
            }

        }


    }, [comment, showConversationLink, $translate]);

    return (
        <CommentMain
            comment={comment}
            hideType={false}
            feedbackTypeText={$translate.instant('FEEDBACK_TYPE_ENPS')}
            onCommentUpdated={onCommentUpdated}
            postHeaderZone={
                <Stack direction={'column'} alignItems={'flex-start'} gap={1}>
                    <HFTag status={enpsTagType} text={$translate.instant(enpsTagLabel)}></HFTag>

                    <Box>
                        <Typography variant={'caption'}>
                            {$translate.instant('PUNTUATION')}
                        </Typography>
                        <Typography variant={'caption'} >
                            {': '}{enpsScore} {' / 10'}
                        </Typography>
                    </Box>

                </Stack>
            }
            reactionsZone={
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Box/>
                    <CommentActionsBar
                        comment={comment}
                        showInappropriate={false}
                        showConversation={showConversationLink}
                        showReplyWithAnnouncement={false}
                        showSwapPrivacy={false}
                        onCommentUpdated={onCommentUpdated}
                    />
                </Stack>
            }
        />
    );
};


export default ENPSFeedbackType;
