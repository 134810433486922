
import * as React from 'react';


interface Props {
    attachments: any; // <-- TODO: Define somewhere the type of this object
}


const HfCommentImage = (({attachments}:Props) => {





    if (!attachments || !attachments.length) {
        return null;
    }

    // Current implementation only supports one image per comment
    const imageURL = attachments[0]?.fileURL;


    return (
        <div className={'m-t-xs m-b-xs'}>
            <img style={{width: '50%', maxWidth: '150px'}}
                src={imageURL}/>
        </div>
    );

});


export default HfCommentImage;
