import {Person} from '../../../types';
import {Box, Chip, Typography} from '@mui/material';
import HFTag from '../../../../../../../shared/new-components/hf-tag/hf-tag';
import React, {useEffect, useState} from 'react';
import {getService} from '../../../../../../../../migration_utils/react-in-angular';
import CheckIcon from '@mui/icons-material/Check';

interface UserGroupsProps {
    person: Person;
    onRowChange: (updatedPerson: Person) => void;
}

const UserGroups = ({person, onRowChange}: UserGroupsProps) => {


    const Groups = getService('Groups');
    const ErrorSvrc = getService('ErrorSvrc');

    const [groups, setGroups] = useState([]);



    useEffect(() => {
        Groups.getAll({ active: true }, (err, groups) => {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                setGroups(groups);
            }
        });
    }, []);

    return (
        <Box sx={{display: 'flex', flexDirection: 'column'}}>

            <Box sx={({display: 'flex', flexWrap: 'wrap'})}>
                {groups?.map(group => {
                    const personInGroup = person.groups?.includes(group.id);

                    return (
                        <Box
                            key={group.id}
                            role={'button'}
                            sx={theme => ({
                                width: 'fit-content',
                                marginTop: theme.spacing(1),
                                marginRight: theme.spacing(0.5)})}
                        >

                            <Chip label={group.name}
                                onClick={() => {
                                    if (person.groups?.includes(group.id)) {
                                        onRowChange({...person, groups: person?.groups.filter(groupId => groupId !== group.id)});
                                    } else {
                                        const newGroups = person.groups ? [...person.groups] : [];
                                        newGroups.push(group.id);
                                        onRowChange({...person, groups: newGroups});
                                    }

                                }}
                                icon={personInGroup ? <CheckIcon/>: null}
                                size={'small'}
                                color={personInGroup ? 'primary' : 'default'}
                                variant={personInGroup ? 'filled' : 'outlined'}/>
                        </Box>
                    );
                })}
            </Box>
        </Box>

    )

}

export default UserGroups;
