import React, { useState, useEffect } from 'react';
import { angularize, getService } from 'reactInAngular';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TableFooter, TablePagination, Skeleton, Stack, Box,
} from '@mui/material';
import angular from 'angular';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';

import FeedbackIdeasRowItem from './components/FeedbackIdeaRow';
import {IdeaComment} from '../../../../../shared/new-components/hf-comments/types';
import HFSearchInput from '../../../../../shared/components/_migrated/search-input/hfSearchInput';
import EmptyState from '../../../../../shared/components/_migrated/empty_state/empty_state';

interface FeedbackIdeasListingProps {
    filters?: Record<string, any>;
    initiativeId?: string;
}


const FeedbackIdeasListing: React.FC<FeedbackIdeasListingProps> = ({ filters, initiativeId }) => {
    const SegmentsFilterService = getService('SegmentsFilterService');
    const ErrorSvrc = getService('ErrorSvrc');
    const FeedbackStatusService = getService('FeedbackStatusService');
    const FeedbackIdeasService = getService('FeedbackIdeasService');
    const Comments = getService('Comments');
    const $translate = getService('$translate');

    const [currentIdeasPage, setCurrentIdeasPage] = useState<number>(0);
    const [totalIdeas, setTotalIdeas] = useState<number>(0);
    const [orderBy, setOrderBy] = useState<string>('RECENT');
    const [ideas, setIdeas] = useState<IdeaComment[]>([]);
    const [searchText, setSearchText] = useState<string | null>(null);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [hasStatuses, setHasStatuses] = useState<boolean>(true);
    const [ideasPerPage, setIdeasPerPage] = useState<number>(10);


    useEffect(() => {
        FeedbackStatusService.checkTypeHasStatuses('SUGGESTION', (err: any, hasStatuses: boolean) => {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                setHasStatuses(hasStatuses);
            }
        });
    }, []);


    useEffect(() => {
        loadIdeasFeedback();
    }, [filters, searchText, initiativeId, currentIdeasPage, ideasPerPage, orderBy]);

    const prepareParams = () => {
        const params: Record<string, any> = {};

        if (filters?.from) {params.from = filters.from;}
        if (filters?.to) {params.to = filters.to;}
        if (filters?.groups?.length) {params.groups = filters.groups;}
        if (filters?.hierarchyIds?.length) {params.hierarchyId = filters.hierarchyIds.join(',');}
        if (filters?.segments?.length) {
            params.filters = SegmentsFilterService.compileFilterStringFromTagSegments(filters.segments);
        }
        if (orderBy) {params.sortOrder = orderBy;}
        if (searchText) {params.commentText = searchText;}
        if (initiativeId) {params.containerId = initiativeId;}

        params.fromComment = (currentIdeasPage) * ideasPerPage;
        params.toComment = ideasPerPage;

        return params;
    };

    const loadIdeasFeedback = () => {
        const params = prepareParams();
        setLoadingData(true);

        FeedbackIdeasService.list(params, (err: any, results: { comments: IdeaComment[]; total: number }) => {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                setIdeas(results.comments || []);
                setTotalIdeas(results.total || 0);
            }
            setLoadingData(false);
        });
    };

    const handleOrderByChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setOrderBy(event.target.value as string);
    };

    const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentIdeasPage(page);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setIdeasPerPage(parseInt(event.target.value, 10));
        setCurrentIdeasPage(0);
    };

    const handleCommentUpdated = (err, commentId) => {

        // Load a single idea and update the comments on the list lookig for the commentId

        Comments.getOne(commentId, (err, comment) => {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                setIdeas(ideas.map((idea) => {
                    if (idea.id === commentId) {
                        return comment;
                    } else {
                        return idea;
                    }
                }));
            }
        });

    }

    const renderIdeasList = () => {



        if (loadingData) {

            // Create 5 rows with 7 cells. One cell will be a loading spinner
            return Array.from({length: 5}, (_, i) => (
                <TableRow key={i}>
                    {/* Create 7 cells */
                        Array.from({length: 7}, (_, i) => (
                            <TableCell key={i}>
                                <Skeleton animation="wave" variant="text" />
                            </TableCell>
                        ))
                    }
                </TableRow>
            ));

        } else if (!ideas || ideas.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={7}>
                        <Box sx={{padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                            <EmptyState
                                image="images/not_results.svg"
                                small={true}
                                message={$translate.instant('IDEAS_NO_RESULTS_TITLE')} submessage={undefined}                          />

                        </Box>
                    </TableCell>


                </TableRow>

            );
        } else {
            return (
                <>
                    {
                        ideas.map((idea) => (
                            <FeedbackIdeasRowItem key={idea.id} idea={idea} onCommentUpdated={handleCommentUpdated} />
                        ))
                    }
                </>

            )
        }




    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>

                <Stack direction={'row'} gap={1}>
                    <HFSearchInput
                        searchPlaceholder={$translate.instant('SEARCH')}
                        searchText={searchText}
                        onSearchTextChange={(value) => setSearchText(value)}/>


                    <Select
                        size="small"
                        labelId="sort-by-label"
                        value={orderBy}
                        onChange={handleOrderByChange}
                    >
                        <MenuItem value="RECENT">{$translate.instant('RECENT')}</MenuItem>
                        <MenuItem value="AGREE">{$translate.instant('AGREE')}</MenuItem>
                        <MenuItem value="DISAGREE">{$translate.instant('DISAGREE')}</MenuItem>
                        <MenuItem value="POPULARITY">{$translate.instant('POPULARITY')}</MenuItem>
                        <MenuItem value="LAST_UPDATED">{$translate.instant('LAST_UPDATED')}</MenuItem>
                        <MenuItem value="INAPPROPRIATE">{$translate.instant('REPORTED_COMMENT')}</MenuItem>
                    </Select>
                </Stack>

            </Grid>

            <Grid item xs={12}>
                <Typography variant="caption" fontWeight={'bold'} className="total-ideas">
                    {`${totalIdeas} ${$translate.instant('TOTAL')}`}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <TableContainer>
                    <Table sx={{width:'100%'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant={'body3'}
                                        fontWeight={'bold'}>{$translate.instant('DATE')}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={'body3'}
                                        fontWeight={'bold'}>{$translate.instant('COL_COMMENT')}
                                    </Typography>
                                </TableCell>

                                <TableCell>
                                    <Typography variant={'body3'}
                                        fontWeight={'bold'}>{$translate.instant('FEEDBACK_AUTHOR')}
                                    </Typography>
                                </TableCell>

                                <TableCell>
                                    <Typography variant={'body3'}
                                        fontWeight={'bold'}>{$translate.instant('FEEDBACK_RECEIVED_REACTIONS')}
                                    </Typography>
                                </TableCell>

                                <TableCell>
                                    <Typography variant={'body3'}
                                        fontWeight={'bold'}>{$translate.instant('INITIATIVE')}
                                    </Typography>
                                </TableCell>


                                <TableCell>
                                    <Typography variant={'body3'}
                                        fontWeight={'bold'}>{$translate.instant('STATUS')}
                                    </Typography>
                                </TableCell>


                                <TableCell>
                                    <Typography variant={'body3'}
                                        fontWeight={'bold'}>{$translate.instant('EMPLOYEE_ACTIONS')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderIdeasList()}
                        </TableBody>
                        <TableFooter>

                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    count={totalIdeas}
                                    rowsPerPage={10}
                                    page={currentIdeasPage}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>

                        </TableFooter>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

angularize(FeedbackIdeasListing, 'hfFeedbackIdeasListing', angular.module('happyForceApp'), {
    filters: '<',
    initiativeId: '<',
});

export default FeedbackIdeasListing;
