import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import React from 'react';
import {getQualityDescription, getVariationDescription} from './cell_utils';
import {getService} from 'reactInAngular';
import {useTheme} from '@mui/material/styles';
import {Divider} from '@mui/material';


interface EngagmentCellDataPopoverProps {
    hasData: boolean;
    quality: string;
    variation: number;
    companyBenchmark: number;
    value: number;
}

const EngagementCellDataPopover = ({hasData, quality, variation, value, companyBenchmark}: EngagmentCellDataPopoverProps) => {

    const theme = useTheme();

    const $translate = getService('$translate');


    const getBenchmarkDescription = (result, companyBenchmark, $translate) => {
        if (result && companyBenchmark) {
            const benchmark = result - companyBenchmark;
            const benchmarkAbs = benchmark.toFixed(2);

            if (benchmark > 0) {
                return $translate.instant('SCORE_BENCHMARK_COM_UP', { benchmark: benchmarkAbs });
            } else if (benchmark < 0) {
                return $translate.instant('SCORE_BENCHMARK_COM_DOWN', { benchmark: benchmarkAbs });

            } else {
                return $translate.instant('SCORE_BENCHMARK_COM_EQUAL', { benchmark: benchmarkAbs });
            }
        }
    };

    const getVariationDescription = (variation, $translate) => {
        if (variation) {
            const variationAbs = variation.toFixed(2);
            if (variation > 0) {
                return $translate.instant('SCORE_VARIATION_UP', { variation: variationAbs });
            } else if (variation < 0) {
                return $translate.instant('SCORE_VARIATION_DOWN', { variation: variationAbs });
            }   else {
                return $translate.instant('SCORE_VARIATION_EQUAL', { variation: variationAbs });
            }
        }

    };


    const getQualityDescription = (quality, $translate) => {
        switch (quality) {
            case 'LOW':
                return $translate.instant('SCORE_LOW_QUALITY_DESCRIPTION');
            case 'NO_DATA':
                return $translate.instant('SCORE_NODATA_QUALITY_DESCRIPTION');
            default:
                return $translate.instant('SCORE_NORMAL_QUALITY_DESCRIPTION');
        }
    };


    return (
        <Tooltip title={
            <>
                {hasData && (
                    <>
                        <Typography variant={'caption'}  dangerouslySetInnerHTML={{__html:getQualityDescription(quality, $translate)}}></Typography>

                        {variation !== undefined && (
                            <>
                                <br/>
                                <Typography variant={'caption'}  dangerouslySetInnerHTML={{__html:getVariationDescription(variation, $translate)}}></Typography>
                            </>
                        )}


                        {companyBenchmark !== undefined && (
                            <>
                                <br/>
                                <Typography variant={'caption'}  dangerouslySetInnerHTML={{__html:getBenchmarkDescription(value, companyBenchmark, $translate)}}></Typography>
                            </>
                        )}



                    </>
                )}

                {!hasData && (
                    <Typography variant={'caption'} >{$translate.instant('SCORE_NODATA_LONG_DESCRIPTION')}</Typography>
                )}
            </>
        } placement="bottom">
            <InfoIcon

                width={theme.typography.body1.fontSize}
                height={theme.typography.body1.fontSize}

                sx={{
                    float: 'right',
                    right: '-5px',
                    position: 'relative',
                    top: '-5px',
                }} htmlColor={'white'} fontSize={'small'}  />

        </Tooltip>
    )
}

export default EngagementCellDataPopover;
