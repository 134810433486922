import React, { useEffect, useState } from 'react';
import { angularize, getService } from 'reactInAngular';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Box,
    Switch, FormControlLabel, Stack,
} from '@mui/material';
import {CommentBase} from '../../types';
import CloseableDialogTitle from '../../../hf-closeable-dialog-title/CloseableDialogTitle';
import angular from 'angular';
import moment from 'moment/moment';
import usePersonName from '../../../../hooks/usePersonName';

interface CommentSwapVisibilityModalProps {
    show: boolean;
    comment: CommentBase;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;
    onClose: () => void;
}

const CommentSwapVisibilityModal: React.FC<CommentSwapVisibilityModalProps> = ({
    show,
    comment,
    onCommentUpdated,
    onClose,
}) => {
    const Comments = getService('Comments');
    const ErrorSvrc = getService('ErrorSvrc');
    const $translate = getService('$translate');

    const [currentPrivated, setCurrentPrivated] = useState<boolean>(false);
    const [originalPrivated, setOriginalPrivated] = useState<boolean>(false);

    const userName = usePersonName(comment?.privateStatusChangedById);

    useEffect(() => {
        if (comment) {
            setCurrentPrivated(comment.privated);
            setOriginalPrivated(comment.privated);
        }
    }, [comment]);

    const handleSave = () => {
        Comments.showSwapVisibilityModal(comment.id, currentPrivated, (err: any) => {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                onCommentUpdated(null, comment.id);
                onClose();
            }
        });
    };


    return (
        <Dialog open={show} onClose={() => onClose()} maxWidth="sm" fullWidth>
            <CloseableDialogTitle onClose={() => onClose()}
                title={$translate.instant('COMMENTS_SWAP_VISIBILITY_MODAL_TITLE')}
                subtitle={$translate.instant('COMMENTS_SWAP_VISIBILITY_ACTION_DESCRIPTION')}
            />

            <DialogContent>

                <Stack gap={2}>

                    <FormControlLabel
                        control={
                            <Switch
                                checked={currentPrivated}
                                onChange={(e) => setCurrentPrivated(e.target.checked)}
                            />
                        }
                        label={$translate.instant('COMMENTS_SWAP_VISIBILITY_ACTION_SWITCH')}
                    />

                    {comment.privateStatusChangedById && (
                        <Typography variant="body3" color="warning.main">
                            {$translate.instant('COMMENTS_SWAP_VISIBILITY_LAST_CHANGE_HINT', {
                                name: userName.personName,
                                date: moment(new Date(comment.privateStatusChangedOn)).format('DD MMM YYYY hh:mm'),
                            })}
                        </Typography>
                    )}
                    {!originalPrivated && currentPrivated && (
                        <Typography variant="body3" color="info.main">
                            {$translate.instant('COMMENTS_SWAP_VISIBILITY_PREVIOUSLY_PUBLIC_HINT')}
                        </Typography>
                    )}

                </Stack>

            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    color="primary"
                    disabled={currentPrivated === comment.privated}
                >
                    {$translate.instant('SAVE')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

angularize(CommentSwapVisibilityModal, 'commentSwapVisibilityModal', angular.module('happyForceApp'), {
    commentId: '<',
    onClose: '&',
});

export default CommentSwapVisibilityModal;
