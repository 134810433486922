interface CommentBase {
    id: string;
    feedbackTypeImage: string;
    feedbackType: FeedbackType;
    privated: boolean;
    date: string;
    comment: string;
    hierarchyId: string;
    hierarchyName: string;
    hierarchyAnonymized: boolean;
    flaggedAnonymous: boolean;
    votes: CommentVote[];
    attachments: any[];
    allowStartConversation: boolean;
    conversationOwnerId?: string;
    signerId?: string;
    conversationId?: string;
    hasBeenSeen: boolean;
    allowPrivatedSwap: boolean;
}

interface InappropriateReport {
    date: string;
    reason: string;
    type: InappropriateReportType;
}

enum InappropriateReportType {
    THRESHOLD = 'THRESHOLD',
    OFFENSIVE = 'OFFENSIVE',
    DISRESPECTFUL = 'DISRESPECTFUL',
    OTHER = 'OTHER',
}

enum InappropriateSourceType {
    USER = 'USER',
    EMPLOYEE = 'EMPLOYEE',
}

enum FeedbackType {
    RECOGNITION = 'RECOGNITION',
    CONGRATULATION = 'CONGRATULATION',
    SUGGESTION = 'SUGGESTION',
    INFORMATION = 'INFORMATION',
    CRITICISM = 'CRITICISM',
    OTHER = 'OTHER',
    SCORE = 'SCORE',
    ENPS = 'ENPS',
    ANNOUNCEMENT_COMMENT = 'ANNOUNCEMENT_COMMENT',
    CLASSIFICATION_FOLLOWUP = 'CLASSIFICATION_FOLLOWUP',
    QUESTION = 'QUESTION',
    SURVEY = 'SURVEY',
}

interface CommentRefereed {
    userId: string;
    date: string;
}

interface RepliesTypeComment extends CommentBase {
    replies: CommentReply[];
}

interface ReactionsTypeComment extends CommentBase {
    likes: number;
    dislikes: number;
}

interface InappropriateTypeComment extends CommentBase {
    inappropriate: boolean;
    employeeInnappropiateFeedback: InappropriateReport[];
    inappropriates: number;

    inappropriateReason: string;


    inappropriateReasonType: InappropriateReportType | null;

    inappropriateSourceType: InappropriateSourceType ;

    refereed: CommentRefereed | null;
}

interface StandardComment  extends RepliesTypeComment, CommentBase, ReactionsTypeComment, InappropriateTypeComment  {
    announcementId: string;
    feedbackType: FeedbackType.CONGRATULATION | FeedbackType.SUGGESTION | FeedbackType.INFORMATION | FeedbackType.OTHER;
}


interface CommentReply extends CommentBase, ReactionsTypeComment, InappropriateTypeComment {

}

interface SomethingIsWrongComment extends RepliesTypeComment, CommentBase, ReactionsTypeComment {
    feedbackType: FeedbackType.CRITICISM;
    makesMeFeel: string;
    howToSolve: string;
}

interface CommentContainer {
    id: string;
    title: string;
}

interface IdeaComment extends RepliesTypeComment, CommentBase, ReactionsTypeComment  {
    feedbackType: FeedbackType.SUGGESTION;
    container: CommentContainer;
    howToSolve: string;
    statuses: FeedbackStatus[];
}

interface RecognitionComment  extends RepliesTypeComment, CommentBase, ReactionsTypeComment  {
    feedbackType: FeedbackType.RECOGNITION;
    value: RecognitionValue;
    target: FeedbackTarget;
}


interface ScoreComment extends CommentBase {
    feedbackType: FeedbackType.SCORE;
    scoreId: string;
    factorId: string;
    questionId: string;
    scoreName: string;
    factorName: string;
    questionTitle: string;
    invertedQuestion: boolean;
}


interface CheckInComment extends CommentBase, ReactionsTypeComment {
    feedbackType: FeedbackType.QUESTION;
    questionTitle: string;
}


interface ENPSComment extends CommentBase {
    feedbackType: FeedbackType.ENPS;
}


interface SurveyComment extends CommentBase {
    feedbackType: FeedbackType.SURVEY;
    questionId: string;
    questionTitle: string;
    questionType: QuestionType;
    surveyId: string;
    roundId: string;
}

interface AnnouncementComment extends CommentBase, ReactionsTypeComment {
    announcementId: FeedbackType.ENPS;
}

interface ClassificationFollowupComment extends CommentBase {
    type: ClassificationFollowupType;
}

enum QuestionType {
    GENERAL = 'GENERAL',
    TOPIC = 'TOPIC',
    OPEN = 'OPEN',
    RANGE = 'RANGE',
}

enum ClassificationFollowupType {
    DISENGAGED = 'DISENGAGED',
    SLEEPING = 'SLEEPING',
    DISENCHANTED = 'DISENCHANTED',
    SAD = 'SAD',
    NEUTRAL = 'NEUTRAL',
    HAPPY = 'HAPPY',
    ENCHANTED = 'ENCHANTED',
}


interface CommentVote {
    id: string;
    score: number;
    label: string;
    color: string;
    imageUrl: string;
}

interface FeedbackContainer {
    id: string;
    title: string;
}

interface FeedbackStatusTypes {
    id: string;
    label: string;
    phase: FeedbackStatusPhaseTypes;
    color?: string;
}

enum FeedbackStatusPhaseTypes {
    NO_PHASE = 'NO_PHASE',
    DISCARDED = 'DISCARDED',
    ACCEPTED = 'ACCEPTED',
    ONGOING = 'ONGOING',
}

interface FeedbackStatus {
    statusTypeId: string;
    date: string;
    userId: string;
    isCurrent: boolean;
}

interface RecognitionValue {
    id: string;
    name: string;
    iconId: number;
    iconImagePath: string;
}

interface FeedbackTarget {
    id: string;
    name: string;
    type: FeedbackTargetType;
}

enum FeedbackTargetType {
    EMPLOYEE = 'EMPLOYEE',
    MANAGER = 'MANAGER',
    HIERARCHY = 'HIERARCHY',
    FREE = 'FREE',
}


export {
    CommentBase,
    StandardComment,
    SomethingIsWrongComment,
    IdeaComment,
    FeedbackContainer,
    FeedbackStatusTypes,
    RecognitionComment,
    ScoreComment,
    ENPSComment,
    ClassificationFollowupComment,
    CheckInComment,
    SurveyComment,
    FeedbackType,
    AnnouncementComment,
    QuestionType,
    CommentReply,
    RepliesTypeComment,
    ReactionsTypeComment,
    InappropriateReport,
    InappropriateReportType,
    InappropriateTypeComment,
};
