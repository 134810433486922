import React from 'react';
import { angularize, getService } from 'reactInAngular';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Paper,
    Stack,
} from '@mui/material';
import CloseableDialogTitle from '../../../../hf-closeable-dialog-title/CloseableDialogTitle';
import moment from 'moment/moment';

interface CommentInappropriateReportsModalProps {
    show: boolean;
    reportedMessages: Array<{ date: string; reason: string | null }>;
    onClose: () => void;
}

const CommentInappropriateReportsModal: React.FC<CommentInappropriateReportsModalProps> = ({
    show,
    reportedMessages,
    onClose,
}) => {
    const $translate = getService('$translate');

    return (
        <Dialog open={show} onClose={onClose} maxWidth="sm" fullWidth>
            <CloseableDialogTitle
                title={$translate.instant('COMMENTS_REPORTED_BY_EMPLOYEES')}
                onClose={onClose}
            />
            <DialogContent>
                {reportedMessages.map((reportedComment, index) => (
                    <Paper
                        key={index}
                        sx={{
                            padding: 2,
                        }}
                    >
                        <Stack direction="column" gap={1}>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                            >
                                {moment(reportedComment.date).format('DD MMM YYYY hh:mm')}
                            </Typography>
                            {reportedComment.reason ? (
                                <Typography variant="body3" style={{ marginTop: 8 }}>
                                    {reportedComment.reason}
                                </Typography>
                            ) : (
                                <Typography variant="body3" style={{ marginTop: 8 }}>
                                    {$translate.instant('OLD_COMMENT_REPORT')}
                                </Typography>
                            )}
                        </Stack>

                    </Paper>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" variant="contained">
                    {$translate.instant('CLOSE')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default CommentInappropriateReportsModal;
