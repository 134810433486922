


import {angularize} from 'reactInAngular';
import angular from 'angular';
import ManageUsersBulkView from 'root/app/components/users/bulk/ManageUsersBulkView';





// Import the component from the React -> and wrap it with the angularize function

angularize(ManageUsersBulkView, 'hfUsersBulkView', angular.module('happyForceApp'), {});


const ManageUsersBulkViewAngular =  function ($stateProvider) {
    'ngInject';

    $stateProvider.state('manage.users_bulk', {
        url: '/users/bulk',
        template: '<hf-users-bulk-view></hf-users-bulk-view>',
        data: { pageTitle: 'Bulk Manage Users', roles: ['ADMIN', 'ADVISOR'] }
    });
}

export default ManageUsersBulkViewAngular;


