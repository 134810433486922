

var DATETIME_FORMAT = 'YYYY-MM-DD';

/**
 * Range dates formatter
 *     <hf-dates from="" to="" />
 *
 * @param {Object} scope    From and to dates
 * @param {Object} Settings Config Settings
 */
const DateRangeController = function (scope, format, inputFormat) {
    var from = moment(scope.from, inputFormat);
    var to = moment(scope.to, inputFormat);

    scope.printedFrom = from.format(format);
    scope.printedTo = to.format(format);
    scope.datetime = from.format(DATETIME_FORMAT) + '/' + to.format(DATETIME_FORMAT);
};

/**
 * Single date formatter
 * @param {Object} scope    Date to format
 * @param {Object} Settings Config Settings
 */
const DateController = function (scope, format, inputFormat) {
    var date = scope.utc ? moment.utc(scope.date, inputFormat) : moment(scope.date, inputFormat);

    scope.printedFrom = date.format(format);
    scope.datetime = date.format(DATETIME_FORMAT);
};

/**
 * Time formatter
 * @param {Object} scope    Time to format
 * @param {Object} Settings Config Settings
 */
const TimeController = function (scope, dateFormat, timeFormat, inputFormat) {
    var date = scope.utc ? moment.utc(scope.time, inputFormat) : moment(scope.time, inputFormat);

    scope.printedFrom = date.local().format(scope.output || dateFormat + ' ' + timeFormat);
    scope.datetime = date.local().format(DATETIME_FORMAT);
};

/**
 * Dates Controller
 * @param {Object} Settings Global Config Settings
 */
const DatesController = function (Settings, $translate, $scope) {

    'ngInject';
    var _this = this;

    this.$onInit = function () {

        var inputFormat = _this.format || null;

        var ObserverController = function (scope) {
            moment.locale($translate.use() || 'en');
            var dateFormat = $translate.instant('DATE_FORMAT');
            if (dateFormat === 'DATE_FORMAT')
            {dateFormat = Settings.defaultDateFormat;}

            var timeFormat = $translate.instant('TIME_FORMAT');
            if (timeFormat === 'TIME_FORMAT')
            {timeFormat = Settings.defaultTimeFormat;}

            if (scope.from && scope.to) {
                DateRangeController(scope, dateFormat, inputFormat);
            } else if (scope.date) {
                DateController(scope, dateFormat, inputFormat);
            } else if (scope.time) {
                TimeController(scope, dateFormat, timeFormat, inputFormat);
            }
        };

        $scope.$watch(angular.bind(this, function () {
            return this.from;
        }), function (value) {

            ObserverController(_this);
        });

        $scope.$watch(angular.bind(this, function () {
            return this.date;
        }), function (value) {

            ObserverController(_this);
        });

        $scope.$watch(angular.bind(this, function () {
            return this.time;
        }), function (value) {

            ObserverController(_this);
        });
    };
};


const hfDate = angular.module('happyForceApp')
    .directive('hfDate', function () {
        return {
            scope: {
                date: '=',
                format: '@?'
            },
            template: require('./date.html'),
            controller: DatesController,
            controllerAs: 'dt',
            bindToController: true
        };
    });

const hfDates = angular.module('happyForceApp').directive('hfDates', function () {
    return {
        scope: {
            from: '=',
            to: '=',
            format: '@?',
            utc: '@?'
        },
        template: require('./range.html'),
        controller: DatesController,
        controllerAs: 'dt',
        bindToController: true
    };
});
const hfTime = angular.module('happyForceApp').directive('hfTime', function () {
    return {
        scope: {
            time: '=',
            format: '@?',
            output: '@?',
            utc: '@?'
        },
        template: require('./time.html'),
        controller: DatesController,
        controllerAs: 'dt',
        bindToController: true
    };
});

export {hfDate, hfTime, hfDates};


