'use strict';

function AnnouncementsCommentReplyTypeEditorController(Settings) {
    'ngInject';
    var _this = this;

    _this.sectionId = Settings.announcementSectionsConfig.commentReplySectionId;


    _this.onReplyWithAnnouncementEdited = function (announcementId) {
        if (_this.onAnnouncementEdited)
            _this.onAnnouncementEdited(announcementId);
    };

    _this.onReplyWithAnouncementExitEdition = function () {
        if (_this.onExitEdition)
            _this.onExitEdition();
    };
};

const hfAnnouncementCommentReplyTypeEditor = angular.module('happyForceApp')
    .directive('hfAnnouncementCommentReplyTypeEditor', function () {
        return {
            scope: {
                announcement: '<',
                onAnnouncementEdited: '<',
                onExitEdition: '<'
            },
            replace: true,
            template: require('./announcement-comment-reply-type-editor.component.html'),
            controller: AnnouncementsCommentReplyTypeEditorController,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });


export default hfAnnouncementCommentReplyTypeEditor;
