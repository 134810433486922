import React, {useState} from 'react';
import {Stack, Tooltip, Typography} from '@mui/material';
import { getService } from 'reactInAngular';
import CommentSwapVisibilityModal from '../../comment-swap-visibility-modal/CommentSwapVisibilityModal';
import {CommentBase} from '../../../types';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';


interface CommentChangeVisibilityActionProps {
    comment: CommentBase;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;
}

const CommentChangeVisibilityAction: React.FC<CommentChangeVisibilityActionProps> = ({
    comment,
    onCommentUpdated,
}) => {
    const $translate = getService('$translate');
    const [showModal, setShowModal] = useState(false);


    const renderContent = () => (
        <Stack direction="row" display="flex" alignItems="center">

            {comment.privated ? (<VisibilityOffOutlinedIcon/>) : ( <VisibilityOutlinedIcon />)}

        </Stack>
    );

    return (
        <>
            {comment.allowPrivatedSwap ? (
                <a onClick={() => {setShowModal(true)}} style={{ display: 'flex' }}>
                    {renderContent()}
                </a>
            ) : (
                <Tooltip title={$translate.instant('COMMENT_ACTION_SWAP_VISIBILITY_MANAGE')}>
                    {renderContent()}
                </Tooltip>
            )}
            <CommentSwapVisibilityModal
                show={showModal}
                comment={comment}
                onCommentUpdated={onCommentUpdated}
                onClose={() => setShowModal(false)}/>
        </>
    );
};


export default CommentChangeVisibilityAction;
