import React, { useState, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import { angularize } from 'reactInAngular';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Clear, Search } from '@mui/icons-material';
import angular from 'angular';

interface HFSearchInputProps {
    searchText: string;
    onSearchTextChange: (searchText: string) => void;
    hideButtons?: boolean;
    realTime?: boolean;
    searchPlaceholder?: string;
}

const HFSearchInput: React.FC<HFSearchInputProps> = ({
    searchText,
    realTime = true,
    hideButtons = false,
    onSearchTextChange,
    searchPlaceholder = 'SEARCH',
}) => {
    const [searchString, setSearchString] = useState<string>(searchText || '');
    const [timeoutId, setTimeoutId] = useState<any | null>(null);

    const handleClearInput = () => {
        onSearchTextChange('');
    };


    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchString(value);
        if (realTime) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            const id = setTimeout(() => {
                onSearchTextChange(value);
                setTimeoutId(null);
            }, 500);
            setTimeoutId(id);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onSearchTextChange(searchString)
        }
    };

    return (
        <TextField
            value={searchString}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder={searchPlaceholder}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {!hideButtons && (
                            <>
                                <IconButton onClick={handleClearInput} size="small">
                                    <Clear />
                                </IconButton>
                                {!realTime && (
                                    <IconButton
                                        onClick={() => onSearchTextChange(searchString)}
                                        size="small"
                                    >
                                        <Search />
                                    </IconButton>
                                )}
                            </>
                        )}
                    </InputAdornment>
                ),
            }}
        />
    );
};


angularize(HFSearchInput, 'hfSearchInput', angular.module('happyForceApp'), {
    searchText: '=',
    realTime: '=?',
    hideButtons: '@',
    onSearchTextChange: '<',
    searchPlaceholder: '@',
});

export default HFSearchInput;
