import React from 'react';
import {Stack, Tooltip, Typography} from '@mui/material';
import { angularize, getService } from 'reactInAngular';
import CommentInappropriateModal from '../../comment-inappropriate-modal/CommentInappropriateModal';
import {CommentBase, InappropriateTypeComment, StandardComment} from '../../../types';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';


interface CommentInappropriateActionProps {
    comment: InappropriateTypeComment;
    replyOf?: string | null;
    onCommentUpdated?: (err: any, updatedCommentId: string) => void;
    showActionLabel?: boolean;
}

const CommentInappropriateAction: React.FC<CommentInappropriateActionProps> = ({
    comment,
    replyOf,
    onCommentUpdated,
    showActionLabel = false,
}) => {
    const $translate = getService('$translate');
    const [showInappropriateModal, setShowInappropriateModal] = React.useState(false);


    const renderContent = () => (
        <Stack direction="row" display="flex" alignItems="center">
            <BlockOutlinedIcon color={(comment.inappropriate || comment.refereed ? 'error' : 'primary')} />
            <Typography variant="caption" marginLeft={0.5}>
                {comment.inappropriates || 0}
                {showActionLabel && $translate.instant('COMMENT_ACTION_INAPPROPRIATE_MANAGE')}
            </Typography>
        </Stack>
    );

    return (
        <span>
            {!comment.privated ? (
                <a
                    onClick={() => setShowInappropriateModal(true)}
                    style={{ display: 'flex' }}
                    color={comment.inappropriate || (comment.inappropriates ?? 0) > 0 ? 'error' : 'default'}
                >
                    {renderContent()}
                </a>
            ) : (
                <Tooltip
                    title={$translate.instant('COMMENT_IS_PRIVATED_MODERATE_ACTION_HINT')}
                    placement="top"
                >
                    {renderContent()}
                </Tooltip>
            )}
            <CommentInappropriateModal show={showInappropriateModal}
                comment={comment}
                isReplyOf={replyOf}
                onCommentUpdated={onCommentUpdated}
                onClose={() => setShowInappropriateModal(false)}/>
        </span>
    );
};

export default CommentInappropriateAction;
