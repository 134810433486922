


import {doPostAsync} from './axiosWrapper';

const baseUrl = '/company/content';

interface GenerateContentRequestDTO {
    instruction: string;
    baseContent: string;
    selectedContent?: string;
    outputFormat?: string;
}

const generateContent = async (request: GenerateContentRequestDTO): Promise<string> => {
    const url = `${baseUrl}/generate`;

    return doPostAsync(url, request);
};

export {generateContent, GenerateContentRequestDTO};
