import React, { useState, useEffect } from 'react';
import { getService } from 'reactInAngular';

import {Box, Stack, Typography} from '@mui/material';
import CommentMain from '../../components/comment-main/CommentMain';
import CommentReactionsBar from '../../components/comment-reactions-bar/CommentReactionsBar';
import CommentActionsBar from '../../components/comment-actions-bar/CommentsActionBar';
import {RecognitionComment} from '../../types';
import HfMultilanguageText from '../../../hf-multilanguage-text/hfMultilanguageText';
import {ImageIcon} from '../../../hf-styled-components/ImageIcon';


interface RecognitionFeedbackTypeProps {
    comment: RecognitionComment;
    showConversationLink?: boolean;
    showReplyWithAnnouncementLink?: boolean;
    showModerateLink: boolean;
    showSwapPrivacy: boolean;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;

}

const RecognitionFeedbackType: React.FC<RecognitionFeedbackTypeProps> = ({
    comment,
    showConversationLink = true,
    showReplyWithAnnouncementLink,
    showModerateLink,
    showSwapPrivacy,
    onCommentUpdated,
}) => {
    const [voteImage, setVoteImage] = useState<string | null>(null);

    const $translate = getService('$translate');


    useEffect(() => {

        if (comment && comment.votes) {
            setVoteImage(comment.votes[0].imageUrl);
        }

    }, [comment, showConversationLink, $translate]);

    return (
        <CommentMain
            comment={comment}
            hideType={false}
            feedbackTypeText={$translate.instant('FEEDBACK_TYPE_RESULT_RECOGNITION')}
            onCommentUpdated={onCommentUpdated}
            preCommentZone={
                <Stack gap={2} marginTop={1}>
                    <Typography variant={'caption'}
                        dangerouslySetInnerHTML={{__html: $translate.instant('COMMENT_FEEDBACK_TYPE_RECOGNITION_TARGET', {name: comment.target.name})}}/>

                    <Box display="flex" alignItems="center" justifyContent={'center'}>
                        <ImageIcon

                            size={'medium'}
                            src={comment.value.iconImagePath}
                            alt={comment.value.name}/>
                        <Typography variant={'body3'} fontWeight={'bold'}>
                            <HfMultilanguageText multilanguage={comment.value.name}/>
                        </Typography>

                    </Box>


                    <Typography variant={'caption'}>
                        {$translate.instant('COMMENT_FEEDBACK_TYPE_RECOGNITION_BECAUSE_OF')}
                    </Typography>
                </Stack>

            }
            postHeaderZone={
                <Box display="flex" alignItems="center">
                    {$translate.instant('STANDARD_FEEDBACK_VOTE_LABEL')}
                    {voteImage && <img className="m-l-xs img-circle img-xs" src={voteImage} alt="Vote" />}
                </Box>
            }
            reactionsZone={
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <CommentReactionsBar
                        comment={comment}
                        showRepliesAction={true}
                        onCommentUpdated={onCommentUpdated}
                    />
                    <CommentActionsBar
                        comment={comment}
                        showInappropriate={showModerateLink}
                        showConversation={showConversationLink}
                        showReplyWithAnnouncement={showReplyWithAnnouncementLink}
                        showSwapPrivacy={showSwapPrivacy}
                        onCommentUpdated={onCommentUpdated}
                    />
                </Stack>
            }
        />
    );
};


export default RecognitionFeedbackType;
