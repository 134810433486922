
import React, {useEffect, useState} from 'react';
import {getService} from 'reactInAngular';
import {Box, CircularProgress, Skeleton} from '@mui/material';
import PropTypes from 'prop-types';
import EngagementENPSBox from '../../../../components/engagement/components/enps_box/enps_box';

const HappyLoading = ({ loading, message, noMessage, skeleton, children }) => {

    const $translate = getService('$translate');

    const [finalMessage, setMessage] = useState(); // [1]
    const [isSkeleton, setIsSkeleton] = useState(false);


    useEffect(() => {
        if (message) {
            setMessage($translate.instant(message));
        } else {
            setMessage($translate.instant('LOADING_DATA_SENTENCE_1'));
        }

        if (skeleton && skeleton > 0) {
            setIsSkeleton(true);
        }

    }, [message, noMessage, skeleton]);


    if (loading) {
        return (
            <Box sx={{ display: 'flex', flexDirection:'column', alignItems:'center', width:'100%' }}>
                {isSkeleton && (
                    <Box sx={{width:'100%'}}>
                        {[...Array(skeleton)].map((e, i) => (
                            <Skeleton key={i} sx={{ animationDelay: `${i}s` }} />
                        ))}
                    </Box>

                )}
                {!isSkeleton && (  <CircularProgress />)}

                {!noMessage && (
                    <span className="text-center text-primary p-t">
                        {/* Assuming Font Awesome 4 is being used */}
                        <div>{finalMessage}</div>
                    </span>
                )}

            </Box>
        )

    } else {
        return children;
    }


};

/* Expose component to AngularJS
angularize(HappyLoading, 'happyLoading', angular.module('happyForceApp'), {
    loading: '<',
    message: '@',
    children: '<'
});*/



/* Expose component to AngularJS */
//TODO: We need to keep this directive for the old components, but we should use the HappyLoader component for
// REACT components. This is due since we can't use AngularJS components under (children) React components.

angular.module('happyForceApp')
    .directive('happyLoading', function () {
        return {
            restrict: 'A',
            replace: true,
            transclude: true,
            scope: {
                loading: '=happyLoading'
            },
            template: '<div class="hf-loading">\n' +
                '\t<div class="background" ng-show="loading">\n' +
                '\t\t<div class="text-center text-primary spinner">\n' +
                '\t\t\t<!--i class="fa fa-spinner fa-spin fa-5x col-sm-12"></i-->\n' +
                '            <i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i>\n' +
                '\t\t\t<div class="col-sm-12">{{$lc.message}}</div>\n' +
                '\t\t</div>\n' +
                '\t</div>\n' +
                '\t<div ng-show="!loading"  ng-transclude></div>\n' +
                '</div>',
            link: function (scope, element, attrs) {
                scope.message = attrs.message;
            }
        };
    });

HappyLoading.propTypes = {
    loading: PropTypes.bool.isRequired,
    message: PropTypes.string,
    noMessage: PropTypes.bool,
    skeleton: PropTypes.number,
    children: PropTypes.any,
};


export default HappyLoading;
