'use strict';

function AnnouncementsTemplateTypeEditorController($scope, $rootScope, Announcements, hfImageSearchModal, $translate, AuthSrvc, Settings, ErrorSvrc, toaster, Attachments) {
    'ngInject';

    var _this = this;

    _this.defaultCoverImage = 'https://assets.myhappyforce.com/defaultImages/announcement_contents/announcements_cover_image1.png';
    _this.editHTML = false;
    _this.savingInprogress = false;
    _this.sendAll = true;

    _this.deleteAttachment = function (id) {
        var attachments = _this.announcement.attachments;
        if (!_this.announcement.id) {
            // If we are creating a new announcement, we can directly remove the attachment from the list
            Attachments.delete(id, function (err, data) {
                if (err) {
                    return console.error('ERROR', err);
                }
                _this.announcement.attachments = attachments.filter(
                    function (item) {
                        return item.id !== id;
                    }
                );
            });
        } else {
            // If we are editing an existing announcement, we keep the attachment in the list, the backend will clean it up
            _this.announcement.attachments = attachments.filter(
                function (item) {
                    return item.id !== id;
                }
            );
        }
    };

    _this.onUploadFile = function (files) {

        if (!_this.announcement.attachments) {
            _this.announcement.attachments = [];
        }

        return _this.announcement.attachments.push(
            ...files
        );
    };

    _this.onContentUpdated = function (content) {
        _this.announcement.message = content;
    };

    _this.onMediaUpdated = function (media) {
        if (!_this.announcement.media) {
            _this.announcement.media = [];
        }

        return _this.announcement.media.push(
            media
        );
    };

    _this.validAnnouncementContent = function () {
        return _this.announcement.message && _this.announcement.message.replace(/(<([^>]+)>)/gi, '').length > 0;
    };

    _this.validateDates = function () {
        if (_this.announcement.startDate && _this.announcement.endDate) {
            return _this.announcement.startDate <= _this.announcement.endDate;
        }
        return true;
    };

    _this.onSelectImage = function (image) {
        if (image)
            _this.announcement.coverImageURL = image;
        else
            _this.announcement.coverImageURL = _this.defaultCoverImage;
    };

    _this.save = function (isCreate, isDraft) {
        _this.announcement.draft = isDraft;
        _this.savingInprogress = true;

        // This is a callback to allow the parent component to handle the save. Used for templates
        if (_this.onSaveCallback) {
            _this.onSaveCallback(_this.announcement, function (err, announcement) {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                    _this.savingInprogress = false;
                    return;
                } else {
                    toaster.pop('success', null, ($translate.instant('ANNOUNCEMENT_CREATED')));
                    $rootScope.$broadcast('announcementCreated', { id: announcement.id, sectionId: announcement.sectionId });
                    if (_this.onAnnouncementEdited)
                        _this.onAnnouncementEdited(announcement.id);
                }
                _this.savingInprogress = false;
            });
        }
    };

    _this.publishAnnouncement = function () {
        if (!_this.announcement.id) {
            _this.save(true, false);
        } else {
            _this.save(false, false);
        }


    };

    _this.saveDraft = function () {
        if (_this.announcement.id) {
            _this.save(false, true);
        } else {
            _this.save(true, true);
        }
    };

    _this.showCoverImageSelector = function () {
        hfImageSearchModal
            .showImageSelector(_this.announcement.coverImageURL, _this.defaultCoverImage, _this.onSelectImage);
    };


    _this.cancel = function () {
        if (_this.onExitEdition)
            _this.onExitEdition();
    };
};

const hfAnnouncementTemplateTypeEditor = angular.module('happyForceApp')
    .directive('hfAnnouncementTemplateTypeEditor', function () {
        return {
            scope: {
                announcement: '<',
                onSaveCallback: '<'
            },
            replace: true,
            template: require('./announcement-template-type-editor.html'),
            controller: AnnouncementsTemplateTypeEditorController,
            controllerAs: '$ctrl',
            bindToController: true
        };
    });


export default hfAnnouncementTemplateTypeEditor;
