import React, { useState, useEffect } from 'react';
import { getService } from 'reactInAngular';

import { Stack } from '@mui/material';
import CommentMain from '../../components/comment-main/CommentMain';
import CommentReactionsBar from '../../components/comment-reactions-bar/CommentReactionsBar';
import CommentActionsBar from '../../components/comment-actions-bar/CommentsActionBar';
import {AnnouncementComment} from '../../types';


interface AnnouncementFeedbackTypeProps {
    comment: AnnouncementComment;
    showConversationLink?: boolean;
    showModerateLink: boolean;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;

}

const AnnouncementFeedbackType: React.FC<AnnouncementFeedbackTypeProps> = ({
    comment,
    showConversationLink = true,
    showModerateLink,
    onCommentUpdated,
}) => {

    const $translate = getService('$translate');

    return (
        <CommentMain
            comment={comment}
            hideType={false}
            feedbackTypeText={ $translate.instant('FEEDBACK_TYPE_ANNOUNCEMENT_COMMENT')}
            onCommentUpdated={onCommentUpdated}

            reactionsZone={
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <CommentReactionsBar
                        key={comment.id}
                        comment={comment}
                        showRepliesAction={true}
                        onCommentUpdated={onCommentUpdated}
                    />
                    <CommentActionsBar
                        comment={comment}
                        showInappropriate={showModerateLink}
                        showConversation={showConversationLink}
                        showReplyWithAnnouncement={false}
                        showSwapPrivacy={false}
                        onCommentUpdated={onCommentUpdated}
                    />
                </Stack>
            }
        />
    );
};


export default AnnouncementFeedbackType;
