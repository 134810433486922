import React from 'react';
import { Stack, Typography } from '@mui/material';
import { ThumbDownOutlined, ThumbUpOutlined } from '@mui/icons-material';
import {ReactionsTypeComment, RepliesTypeComment} from '../../types';
import CommentShowRepliesAction from '../comment-actions/comment-show-replies-action/CommentShowReplieaAction';


interface CommentReactionsBarProps {
    comment: ReactionsTypeComment | RepliesTypeComment;
    showRepliesAction: boolean;
    onCommentUpdated: (err: any, updatedCommentId: string) => void;
}

const CommentReactionsBar: React.FC<CommentReactionsBarProps> = ({ comment, showRepliesAction, onCommentUpdated }) => {

    const isRepliesTypeComment = (obj: any): obj is RepliesTypeComment => {
        return obj && Array.isArray(obj.replies);
    };

    const isReactionsTypeComment = (obj: any): obj is ReactionsTypeComment => {

        return obj && (obj.likes !== null || obj.dislikes !== null);
    }

    return (
        <Stack key={comment.id} display="flex" alignItems="center" gap={1} direction="row">


            {isReactionsTypeComment(comment) && (
                <>
                    <Stack direction="row" display="flex" alignItems="center">
                        <ThumbUpOutlined/>
                        <Typography variant="caption" marginLeft={0.5}>
                            {comment.likes}
                        </Typography>
                    </Stack>
                    <Stack direction="row" display="flex" alignItems="center">
                        <ThumbDownOutlined/>
                        <Typography variant="caption" marginLeft={0.5}>
                            {comment.dislikes}
                        </Typography>
                    </Stack>
                </>

            )}



            {isRepliesTypeComment(comment) && showRepliesAction && (
                <CommentShowRepliesAction
                    comment={comment}
                    onCommentUpdated={onCommentUpdated}
                />
            )}
        </Stack>
    );
};

export default CommentReactionsBar;
