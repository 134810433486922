import {
    Box, Chip, CircularProgress,
    ClickAwayListener,
    FormControl, FormHelperText, IconButton,
    Popper, Stack, TextField,
    Typography
} from '@mui/material';
import React, {KeyboardEvent, useMemo, useRef, useState} from 'react';
import Paper from '@mui/material/Paper';
import {getService} from '../../../../../migration_utils/react-in-angular';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import {generateContent} from '../../../services/migrated/content.service';


type AnchorPosition = {
    top: number;
    left: number;
} | null;


interface HfContentAIDialogProps {
    position?: AnchorPosition;
    selectedContent?: string;
    outputFormat?: string;
    allContent: string;
    onClose: () => void;
    onAIContentGenerated: (content: string) => void;
}

const HfContentAIDialog = ({position, onAIContentGenerated, onClose, selectedContent, allContent, outputFormat}: HfContentAIDialogProps) => {

    const popperRef = useRef<HTMLDivElement>(null); // Reference to the Popper container
    const [ignoreNextClick, setIgnoreNextClick] = useState(true); // Flag to ignore the next click
    const $translate = getService('$translate');

    const toaster = getService('toaster');

    const [aiPrompt, setAiPrompt] = useState('');
    const [loading, setLoading] = useState(false);

    const aiQuickActions = useMemo(() =>  [
        {
            id: 'make_it_longer',
            title: $translate.instant('AI_DIALOG_MAKE_IT_LONGER'),
            prompt: 'Make the content longer keeping tone and style',
        },
        {
            id: 'make_it_shorter',
            title: $translate.instant('AI_DIALOG_MAKE_IT_SHORTER'),
            prompt: 'Make this text shorter keeping tone and style',
        },
        {
            id: 'simplify_language',
            title: $translate.instant('AI_DIALOG_SIMPLIFY_LANGUAGE'),
            prompt: 'Simplify this text keeping tone and style',
        },
        {
            id: 'fix_spelling_grammar',
            title: $translate.instant('AI_DIALOG_FIX_SPELLING_GRAMMAR'),
            prompt: 'Fix spelling and grammar',
        },
        {
            id: 'improve_writting',
            title: $translate.instant('AI_DIALOG_IMPROVE_WRITTING'),
            prompt: 'Improve this text keeping tone and style',
        }
    ], []);


    const executeAIAction = (prompt: string) => {

        setLoading(true);
        generateContent({
            instruction: prompt,
            baseContent: allContent,
            selectedContent: selectedContent,
            outputFormat: outputFormat,
        }).then((result) => {
            setIgnoreNextClick(true);
            setAiPrompt('');
            onAIContentGenerated(result);
        }).catch((error) => {
            console.error(error);
            toaster.pop('error', null, $translate.instant('AI_DIALOG_API_ERROR'));
        }).finally(() => {
            setLoading(false);
        });
    };



    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (!loading && event.key === 'Enter') {
            executeAIAction(aiPrompt);
        }
    };

    /*
    * Handle the click away event. This will close the dialog if the user clicks outside of the dialog.
    * Due to the way the Popper works, we need to ignore the first click event after the dialog is displayed.
     */
    const handleClickAway = (event: MouseEvent | TouchEvent) => {

        if (!loading) {
            if (ignoreNextClick) {
                setIgnoreNextClick(false);
            } else {
                setAiPrompt('');
                setIgnoreNextClick(true);
                onClose();
            }
        }


    }

    const renderInputAdornment = () => {
        if (loading) {
            return (<CircularProgress size={20} />);
        } else {
            return (
                <IconButton
                    disabled={loading}
                    onClick={() => executeAIAction(aiPrompt)}
                    edge="end"
                >
                    <SendIcon/>
                </IconButton>
            );
        }

    }



    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Popper
                ref={popperRef} // Attach the ref to the Popper
                open={!!position}
                style={{
                    position: 'absolute',
                    top: position?.top || 0,
                    left: position?.left || 0,
                    zIndex: 1300, // Ensure it's above other elements
                }}
            >
                <Paper elevation={2} sx={{padding:1}}>

                    <Stack  sx={{
                        width: '100%',
                    }}>
                        <Stack
                            direction="row"
                            gap={1}
                            sx={{
                                width: '100%',
                                alignItems: 'baseline',
                                justifyContent: 'space-between',
                            }}
                        >

                            <Typography variant="h6" component="div">
                                {$translate.instant('AI_DIALOG_CONTENT_TITLE')}
                            </Typography>

                            <IconButton onClick={onClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                    </Stack>

                    <Stack gap={2}>
                        <FormControl variant="standard" fullWidth >
                            <TextField
                                id="aiPrompt"
                                disabled={loading}
                                aria-describedby="aiPrompt-text"
                                value={aiPrompt}
                                onChange={(event) => setAiPrompt(event.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder={$translate.instant('AI_DIALOG_CONTENT_PLACEHOLDER')}
                                variant="outlined"
                                size="small"
                                fullWidth
                                InputProps={{
                                    endAdornment: renderInputAdornment()
                                }}
                            />
                            <FormHelperText id="aiPrompt-text">
                                {selectedContent ? $translate.instant('AI_DIALOG_CONTENT_SELECTION_DESCRIPTION') : $translate.instant('AI_DIALOG_CONTENT_GENERATION_DESCRIPTION')}
                            </FormHelperText>
                        </FormControl>



                        {selectedContent && (
                            <Stack gap={1}>
                                <Typography variant={'body3'} fontWeight={'bold'}>

                                    {$translate.instant('AI_DIALOG_SUGGESTIONS')}
                                </Typography>

                                <Box>
                                    {
                                        aiQuickActions.map((action) => (
                                            <Chip
                                                disabled={loading}
                                                variant={'outlined'}
                                                color={'primary'}
                                                sx={{margin: 0.5}}
                                                key={action.id}
                                                label={<Typography variant={'caption'}>{action.title}</Typography>}
                                                onClick={() => executeAIAction(action.prompt)}
                                            />
                                        ))
                                    }
                                </Box>

                            </Stack>
                        )}



                    </Stack>


                </Paper>
            </Popper>
        </ClickAwayListener>

    );
}

export {AnchorPosition, HfContentAIDialog};
