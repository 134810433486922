import HfDropFileArea from '../../../../../../shared/new-components/hf-drop-file-area/hfDropFileArea';
import Box from '@mui/material/Box';
import * as React from 'react';


type BulkActionDownloadAndUploadPanelProps = {
    handleUploadFile: (file: File) => Promise<void>;
    progress: number;
}

const BulkActionDownloadAndUploadPanel = ({handleUploadFile, progress}: BulkActionDownloadAndUploadPanelProps) => {

    return (
        <>
            <Box sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
            }}>
                <HfDropFileArea
                    sx={{width: '100%', height: '100%'}}
                    textSx={{fontSize: '1.2 em'}}
                    onFileUpload={handleUploadFile}
                    progress={progress}
                    acceptFileTypes={{'application/*': ['.xls', '.xlsx']}}/>
            </Box>
        </>
    );

}

export default BulkActionDownloadAndUploadPanel;
