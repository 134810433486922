import React, {useState} from 'react';
import {Box, Link, styled, Typography} from '@mui/material';


interface CommentTextBoxProps {
    text: string;
    maxLength?: number;
}


const StyledTextBox = styled(Box)(({ theme }) => ({
    ...theme.typography['body3'],
    padding: theme.spacing(1),
    borderRadius: '5px',
    backgroundColor: '#FBFBFB',

}));


const CommentTextBox = ({text, maxLength=200}: CommentTextBoxProps) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded);
    };


    const displayText = isExpanded
        ? text
        : text.length > maxLength
            ? text.substring(0, maxLength) + '...'
            : text;

    return (
        <StyledTextBox>
            <span dangerouslySetInnerHTML={{ __html: displayText }}></span>
            {text.length > maxLength && (
                <Box mt={1}>
                    <Link
                        component="button"
                        onClick={handleToggleExpand}
                        sx={{ textDecoration: 'none', cursor: 'pointer' }}
                    >
                        <Typography variant="caption" color="primary">
                            {isExpanded ? 'Show less' : 'Show more'}
                        </Typography>

                    </Link>
                </Box>
            )}

        </StyledTextBox>
    );
}

export default CommentTextBox;
