import React from 'react';

import { angularize } from 'reactInAngular';

const HFPulseMark = () => {
    return <span className="pulse-mark-badge pulsate"></span>;
};

angularize(HFPulseMark, 'hfPulseMark', angular.module('happyForceApp'));

export default HFPulseMark;
