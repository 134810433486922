import {Stack} from '@mui/material';
import {
    FilterSelected,
    GroupFiltersConfig,
    HierarchyFiltersConfig, OptionFilter, OptionFiltersConfig, SegmentFiltersConfig
} from '../../../../shared/new-components/hf-filter-menu/types';
import HFSearchInput from '../../../../shared/components/_migrated/search-input/hfSearchInput';
import React, {useMemo} from 'react';
import HfFilterMenu from '../../../../shared/new-components/hf-filter-menu/hfFilterMenu';
import {getService} from 'reactInAngular';


interface UserSearchBarProps {
    onSearchFieldsUpdated: (filters: FilterSelected) => void;
    onSearchTextUpdated: (search: string) => void;
    searchText: string;
    filters: FilterSelected;
}

const UserSearchBar:  React.FC<UserSearchBarProps> = ({onSearchFieldsUpdated, onSearchTextUpdated, searchText, filters}) => {


    const $translate = getService('$translate');
    const AuthSrvc = getService('AuthSrvc');

    const hierarchyFiltersConfig: HierarchyFiltersConfig = useMemo(() => {
        return {
            id: 'HIERARCHY',
            type: 'hierarchyIds',
            mode: 'multi',
            title: $translate.instant('AREA'),
            nonRemovable: false,
        }
    }, []);


    const groupFiltersConfig: GroupFiltersConfig = useMemo(() => {
        return {
            id: 'GROUP',
            type: 'groups',
            mode: 'multi',
            title: $translate.instant('GROUPS'),
            nonRemovable: false,
            groups: null,
        }
    }, []);


    const segmentFiltersConfig: SegmentFiltersConfig = useMemo(() => {
        return {
            id: 'SEGMENT',
            type: 'segments',
            mode: 'multi',
            title: $translate.instant('SEGMENTED'),
            nonRemovable: false,
            segments: null
        }

    }, []);

    const deletedStatusFilterOptions: OptionFilter = {
        title: $translate.instant('EMPLOYEE_STATUS'),
        type: 'deletedStatus',
        mode: 'single',
        nonRemovable: true,
        options: [
            {
                title: $translate.instant('INVITED'),
                id: false
            },
            {
                title: $translate.instant('DELETED'),
                id: true
            }
        ]
    }


    const activationStatusFilterOptions: OptionFilter = {
        title: $translate.instant('EMPLOYEE_ACTIVATION_STATUS'),
        type: 'activatedStatus',
        mode: 'multi',
        nonRemovable: false,
        options: [
            {
                title: $translate.instant('EMPLOYEE_ACTIVATED'),
                id: true
            },
            {
                title: $translate.instant('EMPLOYEE_NOT_ACTIVATED'),
                id: false
            }
        ]
    };


    const roleFilterOptions: OptionFilter = {
        title: $translate.instant('USER_TYPE'),
        type: 'employeeType',
        nonRemovable: false,
        mode: 'multi',
        options: [
            {
                title: $translate.instant('EMPLOYEE'),
                id: 'EMPLOYEE'
            },
            {
                title: $translate.instant('ADMIN'),
                id: 'ADMIN'
            },
            {
                title: $translate.instant('MANAGER'),
                id: 'MANAGER'
            }
        ]
    };

    if (AuthSrvc.isADVISOR) {
        roleFilterOptions.options.push({
            title: $translate.instant('ADVISOR'),
            id: 'ADVISOR'
        });
    }

    const  mailStatusOptions: OptionFilter = {
        title: $translate.instant('EMPLOYEE_MAIL_STATUS'),
        type: 'mailStatus',
        nonRemovable: true,
        mode: 'single',
        options: [
            {
                title: $translate.instant('EMPLOYEE_MAIL_STATUS_ANY'),
                id: 'ANY'
            },
            {
                title: $translate.instant('EMPLOYEE_MAIL_STATUS_CORRECT'),
                id: 'CORRECT'
            },
            {
                title: $translate.instant('EMPLOYEE_MAIL_STATUS_FAILING'),
                id: 'FAILING'
            },
            {
                title: $translate.instant('EMPLOYEE_MAIL_STATUS_UNSUB'),
                id: 'UNSUBSCRIBED'
            },
            {
                title: $translate.instant('EMPLOYEE_MAIL_STATUS_UNKNOWN'),
                id: 'UNKNOWN'
            }
        ]
    };

    const profileStatusFilterOptions: OptionFilter = {
        title: $translate.instant('EMPLOYEE_CONFIG_STATUS'),
        type: 'profileStatus',
        nonRemovable: false,
        mode: 'multi',
        options: [
            {
                title: $translate.instant('EMPLOYEE_WITHOUT_AREA'),
                id: 'NO_AREA'
            },
            {
                title: $translate.instant('EMPLOYEE_WITHOUT_FULL_PROFILE'),
                id: 'INCOMPLETE_PROFILE'
            }
        ]
    };

    if (AuthSrvc.isADVISOR) {
        profileStatusFilterOptions.options.push({
            title: $translate.instant('BLOCKED_EMPLOYEES'),
            id: 'BLOCKED'
        });
    }

    const optionFiltersConfig: OptionFiltersConfig = {
        filters: [deletedStatusFilterOptions,
            activationStatusFilterOptions,
            roleFilterOptions,
            mailStatusOptions,
            profileStatusFilterOptions]
    };


    return (
        <Stack gap={2}>

            <HFSearchInput
                realTime={true}
                searchPlaceholder={$translate.instant('EMPLOYEE_SEARCH_INPUT_TITLE')}
                searchText={searchText}
                onSearchTextChange={onSearchTextUpdated}/>

            <HfFilterMenu
                segmentFiltersConfig={segmentFiltersConfig}
                hierarchyFiltersConfig={hierarchyFiltersConfig}
                groupFiltersConfig={groupFiltersConfig}
                optionFiltersConfig={optionFiltersConfig}
                selectedFilters={filters}
                updateFilters={onSearchFieldsUpdated}
            />

        </Stack>
    );


}

export default UserSearchBar;
