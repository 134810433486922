import {Key, useEffect, useState} from 'react';
import { getService } from 'reactInAngular';
import { NestedMenuItem } from 'mui-nested-menu';
import { MenuItem, Checkbox, Radio, Box, ListItemText, Typography } from '@mui/material';
import {Segment, SegmentSelected, SegmentValue} from '../types';

interface SegmentNestedMenuItemProps {
    open?: boolean;
    segments?: Segment[];
    filters: SegmentSelected[];
    setSegmentFilters: (filters: SegmentSelected[]) => void;
    title: string;
    mode: string;
    nonRemovable: boolean;
}

const SegmentNestedMenuItem = ({ open, segments, filters, setSegmentFilters, title, mode, nonRemovable }: SegmentNestedMenuItemProps) => {
    const Languages = getService('Languages');


    const [finalSegments, setFinalSegments] = useState<Segment[] | undefined>();

    // If no groups are defined, load all the tree:


    const Characteristics = getService('Characteristics');

    useEffect(() => {
        if (!segments) {


            Characteristics.getAll(function (err, segments) {
                if (err) {
                    console.log('Error', err);
                    return;
                }
                setFinalSegments(segments);
            });


        } else {
            setFinalSegments(segments);
        }


    },[segments]);

    const handleFilter = (characteristic: Segment, item: SegmentValue) => {
        // Find if the characteristic.id is already in the filters
        const existingFilterIndex = filters.findIndex(filter => filter.id === characteristic.id);

        // Copy the previous filters to modify
        const newFilters: SegmentSelected[] = [...filters];

        if (mode === 'multi') {
            if (existingFilterIndex !== -1) {
                // If the filter already exists for this characteristic.id
                const existingFilter = newFilters[existingFilterIndex];

                if (existingFilter.values.includes(item.id)) {
                    // If the item.id is already in the values, remove it
                    if (!nonRemovable) {
                        existingFilter.values = existingFilter.values.filter(valueId => valueId !== item.id);

                        // If there are no more values, remove the entire filter
                        if (existingFilter.values.length === 0) {
                            newFilters.splice(existingFilterIndex, 1);
                        }
                    }
                } else {
                    // If the item.id is not present, add it
                    existingFilter.values.push(item.id);
                }
            } else {
                // If there is no filter for this characteristic, add it
                newFilters.push({
                    id: characteristic.id,
                    values: [item.id]
                });
            }
        } else if (mode === 'single') {
            if (existingFilterIndex !== -1) {
                const existingFilter = newFilters[existingFilterIndex];

                if (existingFilter.values[0] === item.id) {
                    // If the item.id is already selected, remove it
                    if (!nonRemovable) {
                        newFilters.splice(existingFilterIndex, 1);
                    }
                } else {
                    // If it is a different value, replace it with the new item.id
                    existingFilter.values = [item.id];
                }
            } else {
                // If there is no filter for this characteristic, add it
                newFilters.push({
                    id: characteristic.id,
                    values: [item.id]
                });
            }
        }

        setSegmentFilters(newFilters);
    };

    const renderCharacteristics = (characteristic) => {
        if (mode === 'multi') {
            if (characteristic.questionType === 'LIST' && characteristic.values) {
                return characteristic.values.map((item: SegmentValue, index: Key) => {
                    const isChecked = filters.findIndex(e => e.id === characteristic.id) > -1 && filters.find(e => e.id === characteristic.id).values.includes(item.id);
                    return (
                        <MenuItem sx={{ paddingLeft: 1 }} key={index} onClick={() => handleFilter(characteristic, item)}>
                            <Checkbox
                                checked={isChecked} />
                            <ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {Languages.getTranslationFromUserLanguage(item.value)}
                                </Typography>
                            } />
                        </MenuItem>
                    );
                });
            }
            if (characteristic.questionType === 'DATE' && characteristic.ranges) {
                return characteristic.ranges.map((item: SegmentValue, index: Key) => {
                    const isChecked = filters.findIndex(e => e.id === characteristic.id) > -1 && filters.find(e => e.id === characteristic.id).values.includes(item.id);
                    return (
                        <MenuItem sx={{ paddingLeft: 1 }} key={index} onClick={() => handleFilter(characteristic, item)}>
                            <Checkbox
                                checked={isChecked} />
                            <ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {Languages.getTranslationFromUserLanguage(item.name)}
                                </Typography>
                            } />
                        </MenuItem>
                    );
                });
            }
        } else if (mode === 'single') {
            if (characteristic.questionType === 'LIST' && characteristic.values) {
                return characteristic.values.map((item: SegmentValue, index: Key) => {
                    const isSelected = filters.findIndex(e => e.id === characteristic.id) > -1 && filters.find(e => e.id === characteristic.id).values.includes(item.id);
                    <MenuItem
                        sx={{ paddingLeft: 1 }}
                        key={index}
                        onClick={() => handleFilter(characteristic, item)}>
                        <Radio
                            checked={isSelected}
                        />
                        <ListItemText primary={
                            <Typography style={{ fontSize: '14px' }}>
                                {Languages.getTranslationFromUserLanguage(item.value)}
                            </Typography>
                        } />
                    </MenuItem>
                });
            }
            if (characteristic.questionType === 'DATE' && characteristic.ranges) {
                return characteristic.ranges.map((item: SegmentValue, index: Key) => {
                    const isSelected = filters.findIndex(e => e.id === characteristic.id) > -1 && filters.find(e => e.id === characteristic.id).values.includes(item.id);
                    <MenuItem
                        sx={{ paddingLeft: 1 }}
                        key={index}
                        onClick={() => handleFilter(characteristic, item)}>
                        <Radio
                            checked={isSelected}
                        />
                        <ListItemText primary={
                            <Typography style={{ fontSize: '14px' }}>
                                {Languages.getTranslationFromUserLanguage(item.name)}
                            </Typography>
                        } />
                    </MenuItem>
                });
            }
        } else {
            //TODO: grouped
        }
    }

    const renderCharacteristicsDescriptions = (items: Segment[]) => {
        if (!items) {
            return null;
        }

        return items.map((item, index) => {
            return (
                <NestedMenuItem
                    key={index}
                    sx={{ paddingLeft: 2 }}
                    renderLabel={() =>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <ListItemText primary={
                                <Typography style={{ fontSize: '14px' }}>
                                    {Languages.getTranslationFromUserLanguage(item.description)}
                                </Typography>
                            } />
                        </Box>
                    }
                    parentMenuOpen={open}
                    button={true}
                >
                    {renderCharacteristics(item)}
                </NestedMenuItem>
            );
        });
    };

    return (
        <NestedMenuItem
            renderLabel={() => <ListItemText primary={
                <Typography style={{ fontSize: '14px', paddingLeft: 10 }}>
                    {title}
                </Typography>
            } />}
            parentMenuOpen={open}
        >
            {renderCharacteristicsDescriptions(finalSegments)}
        </NestedMenuItem>
    );
};

export default SegmentNestedMenuItem;
